/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchUserRolesType } from 'client/core/redux/action-types/fetch-user-roles';

/**
 * Export `fetchUserRoles`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchUserRolesType
  })
);

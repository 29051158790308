
/**
 * Module dependencies.
 */

import { Button, Icon, color, states, units } from 'pmint-design-system';
import { Collapse } from 'react-collapse';
import { ifProp, theme } from 'styled-tools';
import { useHistory } from 'react-router-dom';
import FieldValue from 'client/components/core/field-value';
import React, { useCallback, useState } from 'react';
import arrowIcon from 'client/assets/svg/arrow-back.svg';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  content: {
    currentBalance: string;
    destinationAddress: string;
  };
  partnerKey: string;
  partnerName: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('gray100')};
  margin-left: ${units(1)};
  min-height: ${units(5)};
  padding: ${units(1)};
`;

/**
 * `Item` styled component.
 */

const Item = styled.div`
  ${theme('typography.styles.label')};

  align-items: center;
  border-bottom: 1px solid ${color('gray300')};
  color: ${color('textColorLight')};
  cursor: pointer;
  display: grid;
  font-size: 18px;
  font-weight: 400;
  grid-template-columns: repeat(12, 1fr);
  min-height: 73px;
  padding: 0 26px;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color;

  ${states.interaction`
    background-color: ${color('gray300')};
  `}
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  background-color: ${color('gray300')};
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 ${color.transparentize('gray300', 0.05)}, 0 3px 8px 0 ${color.transparentize('gray300', 0.1)}, 0 0 1px 0 ${color.transparentize('gray300', 0.5)};
  grid-column: -1;
  height: ${units(3)};
  transform: rotate(-90deg);
  width: ${units(3)};

  ${ifProp('isCollapseOpen', css`
    transform: rotate(90deg);
  `)}
`;

/**
 * `ViewPartnerDetailsButton` styled component.
 */

const ViewPartnerDetailsButton = styled(Button)`
  grid-column: -3;
`;

/**
 * `FieldsWrapper` styled component.
 */

const FieldsWrapper = styled.div`
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(2, 1fr);
`;

/**
 * `CollapseItem` component.
 */

function CollapseItem(props: Props): JSX.Element {
  const {
    content: {
      currentBalance,
      destinationAddress
    },
    partnerKey,
    partnerName
  } = props;

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const history = useHistory();
  const { translate } = useTranslate();
  const toggleCollapse = useCallback(() => setIsCollapseOpen(value => !value), []);
  const handleViewPartnerDetailsClick = useCallback((event: any) => {
    event.stopPropagation();

    history.push({
      pathname: routes.dashboard.partnerDetails,
      state: { partner: partnerKey }
    });
  }, [history, partnerKey]);

  return (
    <>
      <Item onClick={toggleCollapse}>
        {partnerName}

        <ViewPartnerDetailsButton
          data-cy={`partners-${partnerName}-view`}
          onClick={handleViewPartnerDetailsClick}
        >
          {translate('common.actions.view')}
        </ViewPartnerDetailsButton>

        <StyledIcon
          data-cy={`partners-${partnerName}-collapse`}
          icon={arrowIcon}
          isCollapseOpen={isCollapseOpen}
        />
      </Item>

      <Collapse isOpened={isCollapseOpen}>
        <Wrapper>
          <FieldsWrapper>
            <FieldValue
              data-cy={`partners-${partnerName}-currentBalance`}
              label={translate('partners.labels.currentBalance')}
            >
              {currentBalance}
            </FieldValue>

            <FieldValue
              data-cy={`partners-${partnerName}-destinationAddress`}
              label={translate('partners.labels.destinationAddress')}
            >
              {destinationAddress}
            </FieldValue>
          </FieldsWrapper>
        </Wrapper>
      </Collapse>
    </>
  );
}

/**
 * Export `CollapseItem` component.
 */

export default CollapseItem;

/**
 * Module dependencies.
 */

import { Card, Loader, Type, color, media, states, units } from 'pmint-design-system';
import { getPatchIdentityState } from 'client/core/redux/selectors/patch-identity';
import { getUacIdentity } from 'client/core/redux/selectors/uacs';
import { ifProp, theme } from 'styled-tools';
import { patchIdentity, resetPatchIdentity } from 'client/core/redux/actions/patch-identity';
import { useDispatch, useSelector } from 'react-redux';
import CloseCaseModal from './close-case-modal';
import ModalPortal from 'client/components/core/modal/modal-portal';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  uacId: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row-gap: ${units(2)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(12, 1fr);
  `}
`;

/**
 * `StyledCard` styled component.
 */

const StyledCard = styled(Card)`
  border: 1px solid ${color('textColorLight')};
  border-radius: ${units(1)};
  display: grid;
  grid-row-gap: ${units(2)};
  padding: ${units(2)};

  ${media.min('xs')`
    grid-column: 1 / 8;
    grid-template-columns: repeat(12, 1fr);
  `}
`;

/**
 * `IdentityName` styled component.
 */

const IdentityName = styled(Type.Label)`
  font-weight: 400;
  grid-column: 1 / -1;
`;

/**
 * `IdentitySsnWraper` styled component.
 */

const IdentitySsnWraper = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1 / -1;
`;

/**
 * `SarsFieldWrapper` styled component.
 */

const SarsFieldWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1 / 9;
`;

/**
 * `TextContent` styled component.
 */

const TextContent = styled(Type.Paragraph)`
  font-weight: 400;
`;

/**
 * `IdentityBlockWrapper` styled component.
 */

const IdentityBlockWrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row-gap: ${units(2)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(12, 1fr);
  `}
`;

/**
 * `BlockButton` styled component.
 */

const BlockButton = styled.button<{
  isIdentityBlocked: boolean | undefined;
}>`
  background-color: ${color('errorDark')};
  border: 1px solid ${color('errorDark')};
  border-radius: ${units(1)};
  box-shadow: none;
  color: ${color('white')};
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  grid-column: 9 / -1;
  line-height: 24px;
  max-width: ${units(40)};
  min-height: ${units(6)};
  outline: 0;
  padding: ${units(1.5)} ${units(3)};
  text-align: center;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color, color;
  width: 100%;

  ${ifProp(
    'isIdentityBlocked',
    css`
      background-color: ${color('bender1')};
      border: 1px solid ${color('bender1')};
    `
  )}

  ${media.min('md')`
    grid-column: 1 / -1;
  `}

  ${media.min('lg')`
    grid-column: 9 / -1;
  `}

  ${states.interaction`
    background-color: ${ifProp('isIdentityBlocked', color('bender2'), '#d93d3d')};
    border-color:  ${ifProp('isIdentityBlocked', color('bender2'), '#d93d3d')};
  `}
`;

/**
 * `CloseUacButton` styled component.
 */

const CloseUacButton = styled.button`
  align-self: end;
  background-color: ${color('bender7')};
  border: 1px solid ${color('bender7')};
  border-radius: ${units(1)};
  box-shadow: none;
  color: ${color('white')};
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  outline: 0;
  padding: ${units(1.5)} ${units(3)};
  text-align: center;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color, color;
  width: 100%;

  ${media.min('xs')`
    grid-column: 10 / -1;
    max-width: ${units(25)};
  `}

  ${states.interaction`
    background-color: ${color('bender6')};
    border-color: ${color('bender6')};
  `}
`;

/**
 * `Loading` styled component.
 */

const Loading = styled(Loader)`
  color: ${color('white')};
  display: inline-flex;
  size: ${units(3)};
  stroke: ${units(0.5)};
`;

/**
 * `UacManagementDetailsIdentityInformation` component.
 */

function UacManagementDetailsIdentityInformation({ uacId }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { blocked, id, idNumber, name, numberOfSars } = useSelector(getUacIdentity);
  const { errors, isLoading } = useSelector(getPatchIdentityState);
  const [isCloseCaseModalVisible, setIsCloseCaseModalVisible] = useState(false);
  const handleCloseCase = useCallback(() => {
    setIsCloseCaseModalVisible(true);
  }, []);

  const handleRequestClose = useCallback(() => {
    setIsCloseCaseModalVisible(false);
  }, []);

  const { translate } = useTranslate();
  const handleBlockClick = useCallback(() => {
    dispatch(patchIdentity(id, { blocked: !blocked }));
  }, [blocked, dispatch, id]);

  useEffect(() => {
    return () => dispatch(resetPatchIdentity());
  }, [dispatch]);

  useNetworkErrorMessage('uacManagementDetails.errors.network', errors);

  return (
    <Wrapper>
      <StyledCard>
        <IdentityName data-cy={'uacManagementDetails-identityInformation-name'}>{name}</IdentityName>

        <IdentitySsnWraper>
          <Type.Label color={color('textColor')}
            fontWeight={450}
            marginRight={units(1)}
          >
            {translate('uacManagementDetails.identityInformation.ssn')}
          </Type.Label>

          <TextContent data-cy={'uacManagementDetails-identityInformation-ssn'}>{idNumber}</TextContent>
        </IdentitySsnWraper>

        <IdentityBlockWrapper>
          <SarsFieldWrapper>
            <Type.Label color={color('textColor')}
              fontWeight={450}
              marginRight={units(1)}
            >
              {translate('uacManagementDetails.identityInformation.sarsField')}
            </Type.Label>

            <TextContent>{numberOfSars ?? 0}</TextContent>
          </SarsFieldWrapper>

          <BlockButton
            data-cy={'uacManagementDetails-blockButton'}
            isIdentityBlocked={blocked}
            onClick={handleBlockClick}
          >
            {isLoading ? (
              <Loading />
            ) : translate(`uacManagementDetails.identityInformation.${blocked ? 'unblock' : 'block'}`)
            }
          </BlockButton>
        </IdentityBlockWrapper>
      </StyledCard>

      <CloseUacButton data-cy={'uacManagementDetails-closeUACButton'}
        onClick={handleCloseCase}
      >
        {translate('uacManagementDetails.identityInformation.closeCase')}
      </CloseUacButton>

      <ModalPortal isOpen={isCloseCaseModalVisible}>
        <CloseCaseModal
          isVisible={isCloseCaseModalVisible}
          name={name}
          onRequestClose={handleRequestClose}
          uacId={uacId}
        />
      </ModalPortal>
    </Wrapper>
  );
}

/**
 * Export `UacManagementDetailsIdentityInformation` component.
 */

export default UacManagementDetailsIdentityInformation;

/**
 * Module dependencies.
 */

import { cookiesStorage } from 'client/core/utils/cookies';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { fetchUserType } from 'client/core/redux/action-types/fetch-user';
import { storageKeys } from 'core/utils/storage-keys';

/**
 * Export `fetchUser`.
 */

export const fetchUser = () => {
  const userId = cookiesStorage.get(storageKeys.userId);

  return createRequestAction({
    endpoint: {
      name: 'fetchUser',
      params: { userId }
    },
    handleResponse: (response: any) => response.data,
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchUserType
  });
};

/**
 * Export `resetFetchUser`.
 */

export const resetFetchUser = createResetRequestAction({
  type: fetchUserType
});

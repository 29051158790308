/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchIdentityDocumentsType } from 'client/core/redux/action-types/fetch-identity-documents';
import { normalizeIdentityDocuments } from 'client/core/utils/normalizer';

/**
 * Export `fetchIdentityDocuments`.
 */

export const fetchIdentityDocuments = (identityId: string) => {
  return createRequestAction({
    endpoint: {
      name: 'identityDocuments',
      params: { id: identityId }
    },
    handleResponse: ({ data }: any) => normalizeIdentityDocuments(data),
    type: fetchIdentityDocumentsType
  });
};

/**
 * Export `resetFetchIdentityDocuments`.
 */

export const resetFetchIdentityDocuments = createResetRequestAction({
  type: fetchIdentityDocumentsType
});


/**
 * Module dependencies.
 */

import type { FetchTransactionsType } from 'client/core/types/transactions';
import { color, media, units } from 'pmint-design-system';
import { fetchTransactions, resetFetchTransactions } from 'client/core/redux/actions/fetch-transactions';
import { getAllTransactions } from 'client/core/redux/selectors/fetch-transactions';
import { theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import ListEmptyState from 'client/components/core/lists/empty-state';
import RawHtml from 'react-components/raw-html';
import React, { useCallback, useEffect } from 'react';
import TransactionsListContent from './list-content';
import TransactionsListHeader from './list-header';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 2 / -2;
  margin-bottom: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(4)};
  `}
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  margin: ${units(5)} 0 ${units(9)};
  text-align: center;
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  ${theme('typography.styles.label')}

  color: ${color('textColor')};

  > a {
    color: ${color('textColor')};
    text-decoration: none;

    &:hover, &:focus {
      color: ${color('textColorLight')};
    }
  }
`;

/**
 * `TransactionsList` component.
 */

function TransactionsList(): JSX.Element {
  const dispatch = useDispatch();
  const transactions = useSelector(getAllTransactions);
  const { translate } = useTranslate();
  const handleFetchTransactions = useCallback(({ number }: FetchTransactionsType = {}) => {
    dispatch(fetchTransactions({
      number
    }));
  }, [dispatch]);

  useEffect(() => {
    handleFetchTransactions();

    return () => {
      dispatch(resetFetchTransactions());
    };
  }, [dispatch, handleFetchTransactions]);

  return (
    <Wrapper>
      <DescriptionWrapper data-cy={'transactionsList-description'}>
        <StyledRawHtml>
          {translate('transactions.description')}
        </StyledRawHtml>
      </DescriptionWrapper>

      {transactions.length ? (
        <>
          <TransactionsListHeader />

          <TransactionsListContent
            handleFetchTransactions={handleFetchTransactions}
            transactions={transactions}
          />
        </>
      ) : (
        <ListEmptyState
          dataCy={'transactions-emptyState'}
          description={translate('transactions.listEmptyState')}
        />
      )}
    </Wrapper>
  );
}

/**
 * Export `TransactionsList` component.
 */

export default TransactionsList;

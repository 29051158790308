/**
 * Module dependencies.
 */

import cookies from 'browser-cookies';

/**
 * Export `cookiesStorage`.
 */

export const cookiesStorage = {
  get: (storageKey: string) => cookies.get(storageKey),
  remove: (storageKey: string) => cookies.erase(storageKey),
  set: (storageKey: string, storageValue: string, options?: cookies.CookieOptions) => {
    return cookies.set(storageKey, storageValue, options);
  }
};

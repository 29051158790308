/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { patchIdentityType } from 'client/core/redux/action-types/patch-identity';

/**
 * Export `patchIdentity`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'PATCH',
    type: patchIdentityType
  })
);

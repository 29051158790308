/**
 * Module dependencies.
 */

import { Alert, Box } from 'pmint-design-system';
import { units } from 'react-components/styles';
import React, { useCallback, useEffect } from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
  id: number;
  onDismiss: (id: number) => void;
  options: Record<string, any>;
};

/**
 * `SnackbarContent` container.
 */

function SnackbarContent(props: Props): JSX.Element {
  const { children, id, onDismiss, options } = props;
  const { translate } = useTranslate();
  const handleDismiss = useCallback(() => {
    onDismiss(id);
  }, [id, onDismiss]);

  useEffect(() => {
    setTimeout(() => {
      onDismiss(id);
    }, 3000);
  }, [id, onDismiss]);

  return (
    <Alert
      closeButtonAriaLabel={'common.actions.close'}
      data-cy={'alertSnackbar'}
      onDismiss={handleDismiss}
      size={'small'}
      type={options.type}
    >
      <Box as={'b'}
        marginRight={units(0.5)}
        textTransform={'uppercase'}
      >
        {translate(`common.alerts.${options.type}`)}
      </Box>

      {children}
    </Alert>
  );
}

/**
 * Export `SnackbarContent` container.
 */

export default SnackbarContent;

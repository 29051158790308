/**
 * Module dependencies.
 */

import { AnyAction } from 'redux';
import type { Document } from 'client/core/types/documents';
import { fetchIdentityDocumentsTypes } from 'client/core/redux/action-types/fetch-identity-documents';
import { setActiveDocumentType } from 'client/core/redux/action-types/documents';

/**
 * Documents.
 */

function documents(state: Array<Document> = [], action: AnyAction): Array<Document> {
  const { payload, type } = action ?? {};

  switch (type) {
    case fetchIdentityDocumentsTypes.successType:
      return [...payload];

    case setActiveDocumentType:
      return state.map((document: Document) => {
        if (document.id === payload.documentId) {
          return {
            ...document,
            isActive: true
          };
        }

        return {
          ...document,
          isActive: false
        };
      });

    default:
      return state;
  }
}

/**
 * Export `documents`.
 */

export default documents;

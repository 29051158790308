/**
 * Module dependencies.
 */

import { get, has, unescape } from 'lodash';

/**
 * Export from global variable `_config` which contains
 * the config injected by the koa server.
 */

const config = JSON.parse(unescape((window as any)._config)); // eslint-disable-line no-underscore-dangle

/**
 * Delete global reference.
 */

delete (window as any)._config; // eslint-disable-line no-underscore-dangle

/**
 * Export `config`.
 */

export default {
  ...config,
  get: (key: string) => get(config, key),
  has: (key: string) => has(config, key)
};

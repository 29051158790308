/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchIdentityOperationsType } from 'client/core/redux/action-types/fetch-identity-operations';

/**
 * Export `fetchIdentityOperations`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchIdentityOperationsType
  })
);

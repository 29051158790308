/**
 * Module dependencies.
 */

import { searchIdentitiesType } from 'client/core/redux/action-types/identities';

/**
 * Export `searchIdentities`.
 */

export const searchIdentities = (searchWord?: string) => {
  return {
    payload: { searchWord },
    type: searchIdentitiesType
  };
};

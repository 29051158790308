/**
 * Module dependencies.
 */

import { colors } from './colors';
import { createPmintTheme } from 'pmint-design-system';
import { layout } from './layout';
import { zIndex } from './z-index';

/**
 * Export `theme`.
 */

export const theme = createPmintTheme({
  colors,
  layout,
  zIndex
});

/**
 * Module dependencies.
 */

import { Type, media, units } from 'pmint-design-system';
import FieldValue from 'client/components/core/field-value';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  email: string;
  phoneNumber: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `ContactInformation` component.
 */

function ContactInformation({ email, phoneNumber }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Title>{translate('identitiesListDetails.contactInformation.title')}</Title>

      <SideBySide>
        <FieldValue
          data-cy={'identitiesListDetails-contactInformation-email'}
          label={translate('identitiesListDetails.contactInformation.labels.email')}
        >
          {email}
        </FieldValue>

        <FieldValue
          data-cy={'identitiesListDetails-contactInformation-phoneNumber'}
          label={translate('identitiesListDetails.contactInformation.labels.phoneNumber')}
        >
          {phoneNumber}
        </FieldValue>
      </SideBySide>
    </Wrapper>
  );
}

/**
 * Export `ContactInformation` component.
 */

export default ContactInformation;

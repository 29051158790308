/**
 * Module dependencies.
 */

import { Button, color, units } from 'pmint-design-system';
import { useFormActions } from '@seegno/react-forms';
import React, { useCallback } from 'react';
import backIcon from 'client/assets/svg/back-32px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  dataCy?: string;
  onRequestClose: () => void;
};

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  align-items: center;
  color: ${color('textColorLight')};
  min-height: ${units(8)};
`;

/**
 * `ModalCancelButton` component.
 */

function ModalCancelButton({ dataCy, onRequestClose }: Props): JSX.Element {
  const { reset } = useFormActions();
  const { translate } = useTranslate();
  const handleCancel = useCallback(() => {
    onRequestClose();
    reset();
  }, [onRequestClose, reset]);

  return (
    <StyledButton
      colorTheme={'secondary'}
      data-cy={dataCy}
      fullWidth
      icon={backIcon}
      onClick={handleCancel}
      variant={'outlined'}
    >
      {translate('common.actions.cancel')}
    </StyledButton>
  );
}

/**
 * Export `ModalCancelButton` component.
 */

export default ModalCancelButton;

/**
 * Module dependencies.
 */

import { Icon, Link, color, media, units } from 'pmint-design-system';
import React, { ReactNode } from 'react';
import logoIcon from 'client/assets/svg/logo.svg';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('gray200')};
  display: flex;
  grid-area: sidebar;
  overflow-x: hidden;
  padding: ${units(3.25)} 0 ${units(4.25)};

  ${media.min('md')`
    padding: ${units(2)} 0 ${units(2.5)};
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.header`
  display: grid;
  flex: 1;
  grid-row-gap: ${units(8.125)};
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 50px auto;

  ${media.min('md')`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 50px auto 30px;
  `}
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  grid-column: 2 / -2;
  margin: 0;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin: 0 ${units(3)};
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-self: center;
  grid-column: 2 / -2;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin: 0 ${units(3.25)};
  `}
`;

/**
 * `Sidebar` component.
 */

function Sidebar(props: Props): JSX.Element {
  const { children } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <ContentWrapper>
        <StyledLink to={routes.home}>
          <Icon
            aria-label={translate('sidebar.logoLabel')}
            color={color('bender6')}
            icon={logoIcon}
            size={units(15.75)}
          />
        </StyledLink>

        <Content>{children}</Content>
      </ContentWrapper>
    </Wrapper>
  );
}

/**
 * Export `Sidebar` component.
 */

export default Sidebar;

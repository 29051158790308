/**
 * Module dependencies.
 */

import { Checkbox } from 'pmint-design-system';
import { useField, useFormState } from '@seegno/react-forms';
import React, { ChangeEvent, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  label: string;
  name: string;
};

/**
 * `CheckboxField` component.
 */

function CheckboxField(props: Props): JSX.Element {
  const { className, dataCy, disabled, label, name } = props;
  const { error, meta, onChange, value, ...fieldProps } = useField(name);
  const { isSubmitting } = useFormState();
  const { touched } = meta ?? {};
  const checkboxLabel = useLabel(label, name);
  const hasError = !!error && touched;
  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(target.checked);
    },
    [onChange]
  );

  const isDisabled = disabled || isSubmitting;

  return (
    <Checkbox
      {...fieldProps}
      checked={value}
      className={className}
      data-cy={dataCy}
      disabled={isDisabled}
      hasError={hasError}
      label={checkboxLabel}
      name={name}
      onChange={handleChange}
    />
  );
}

/**
 * Export `CheckboxField` component.
 */

export default CheckboxField;

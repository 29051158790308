/**
 * Module dependencies.
 */

import { i18n } from 'i18next';
import { useTranslation } from 'react-i18next';

/**
 * `TranslateProps` type.
 */

type TranslateProps = {
  i18n: i18n;
  translate: (key: string | string[], options?: Record<string, unknown>) => string;
};

/**
 * Use translate.
 */

function useTranslate(namespaces?: Array<string> | string, options?: any): TranslateProps {
  const {
    t, // eslint-disable-line id-length
    ...rest
  } = useTranslation(namespaces, {
    useSuspense: false,
    ...options
  });

  return {
    ...rest,
    translate: t
  };
}

/**
 * Export `useTranslate`.
 */

export default useTranslate;

/**
 * Module dependencies.
 */

import { Link, Type, color, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColorLight')};
  font-weight: 400;
  margin-right: ${units(0.5)};
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`;

/**
 * `ForgotPassword` component.
 */

function ForgotPassword({ className }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper className={className}>
      <Title>{translate('signIn.forgotPassword.title')}</Title>

      <StyledLink colorTheme={'secondary'}
        size={'small'}
      >
        {translate('signIn.forgotPassword.recoverHere')}
      </StyledLink>
    </Wrapper>
  );
}

/**
 * Export `ForgotPassword` component.
 */

export default ForgotPassword;

/**
 * Module dependencies.
 */

import { Context, createContext, useContext } from 'react';

/**
 * Export `FormErrorsBasePathContext` context.
 */

export const FormErrorsBasePathContext: Context<any> = createContext(null);

/**
 * Export `useFormErrorsBasePath` hook.
 */

export const useFormErrorsBasePath = () => useContext(FormErrorsBasePathContext);

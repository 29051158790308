
/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchEarnDocumentPresignUrlType } from 'client/core/redux/action-types/fetch-earn-document-presign-url';

/**
 * Export `fetchEarnDocumentPesignUrl`.
 */

export default combineReducers(createRequestReducers({
  type: fetchEarnDocumentPresignUrlType
}));

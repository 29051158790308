/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { fetchUserRolesType } from 'client/core/redux/action-types/fetch-user-roles';

/**
 * Export `fetchUserRoles`.
 */

export const fetchUserRoles = () => {
  return createRequestAction({
    endpoint: 'fetchUserRoles',
    handleResponse: (response: any) => response.data,
    type: fetchUserRolesType
  });
};

/**
 * Export `resetFetchUserRoles`.
 */

export const resetFetchUserRoles = createResetRequestAction({
  type: fetchUserRolesType
});

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { logoutType } from 'client/core/redux/action-types/logout';

/**
 * Export `logout`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'POST',
    type: logoutType
  })
);

/**
 * Module dependencies.
 */

import { Button, Modal, Type, color, media, units } from 'pmint-design-system';
import ModalCancelButton from 'client/components/core/modal/cancel-button';
import RawHtml from 'react-components/raw-html';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isVisible: boolean;
  name?: string;
  onContinue: () => void;
  onRequestClose: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: 30px ${units(2.5)};

  ${media.min('xs')`
    padding-left: ${units(4)};
    padding-right: ${units(4)};
  `}

  ${media.min('xl')`
    padding: 50px ${units(15)} ${units(10)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H5).attrs({ as: 'h1' })`
  color: ${color('textColor')};
  font-weight: 500;
  margin-bottom: ${units(6)};
  text-align: center;
`;

/**
 * `SubTitle` styled component.
 */

const SubTitle = styled(Type.Label).attrs({ as: 'h2' })`
  color: ${color('textColor')};
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: ${units(4)};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-weight: 300;
  margin: 34px 0 34px;
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  margin-top: ${units(5)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  background-color: ${props => props.color};
  border-color: ${props => props.color};
  min-height: ${units(8)};
`;

/**
 * `RequestViewDocumentModal` component.
 */

function RequestViewDocumentModal(props: Props): JSX.Element {
  const { isVisible, name, onContinue, onRequestClose } = props;
  const { translate } = useTranslate();

  return (
    <Modal
      isVisible={isVisible}
      onRequestClose={onRequestClose}
    >
      <Wrapper data-cy={'requestViewDocument'}>
        <Title>
          {translate('documents.modals.requestViewDocument.title')}
        </Title>

        {
          name ? (
            <RawHtml element={SubTitle}>
              {translate('documents.modals.requestViewDocument.label', { name })}
            </RawHtml>
          ) : (
            <SubTitle>
              {translate('documents.modals.requestViewDocument.earn-label')}
            </SubTitle>
          )
        }

        <Description>{translate('documents.modals.requestViewDocument.description')}</Description>

        <SideBySide>
          <ModalCancelButton onRequestClose={onRequestClose} />

          <StyledButton
            data-cy={'requestViewDocument-button'}
            onClick={onContinue}
          >
            {translate('common.actions.continue')}
          </StyledButton>
        </SideBySide>
      </Wrapper>
    </Modal>
  );
}

/**
 * Export `RequestViewDocumentModal` component.
 */

export default RequestViewDocumentModal;

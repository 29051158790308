/**
 * Module dependencies.
 */

import { AppError } from 'client/core/types/error';
import { get } from 'lodash';

/**
 * Export `handleError`.
 */

export function handleError(error: AppError) {
  return Promise.reject(get(error, 'response.data'));
}

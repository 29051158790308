/**
 * Module dependencies.
 */

import { Select } from 'pmint-design-system';
import React, { useCallback, useState } from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  defaultValue: string;
  onChange: (data: 'low' | 'medium' | 'high') => void;
};

/**
 * `RiskRating` component.
 */

function RiskRating(props: Props): JSX.Element {
  const { defaultValue, onChange } = props;
  const { translate } = useTranslate();
  const [value, setValue] = useState(defaultValue);
  const options = [
    {
      label: translate('identitiesListDetails.personalInformation.riskRating.low'),
      value: 'low'
    },
    {
      label: translate('identitiesListDetails.personalInformation.riskRating.medium'),
      value: 'medium'
    },
    {
      label: translate('identitiesListDetails.personalInformation.riskRating.high'),
      value: 'high'
    }
  ];

  const handleOnChange = useCallback(
    ({ target: { value } }: any) => {
      onChange(value);
      setValue(value);
    },
    [onChange]
  );

  return (
    <>
      <form onSubmit={event => event.preventDefault()}>
        <Select
          data-cy={'identitiesListDetails-riskRating'}
          hideEmptyOption
          items={options}
          label={translate('identitiesListDetails.personalInformation.labels.riskRating')}
          name={'riskRating'}
          onChange={handleOnChange}
          value={value}
        />
      </form>
    </>
  );
}

/**
 * Export `RiskRating` component.
 */

export default RiskRating;

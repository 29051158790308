/**
 * Module dependencies.
 */

import type { Document } from 'client/core/types/documents';
import { Type, media, units } from 'pmint-design-system';
import { formatDate } from 'client/core/utils/formatter';
import { getPatchIdentityState } from 'client/core/redux/selectors/patch-identity';
import { patchIdentity, resetPatchIdentity } from 'client/core/redux/actions/patch-identity';
import { startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import EditButtons from 'client/components/core/forms/edit-buttons';
import FieldValue from 'client/components/core/field-value';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import React, { useCallback, useEffect, useState } from 'react';
import RiskRating from './risk-rating';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  birthday: string;
  blocked: boolean;
  documents?: Array<Document>;
  gender: string;
  id: string;
  label: string;
  name: string;
  riskRating: string;
};

/**
 * `DocumentsAndFaceImages` type.
 */

type DocumentsAndFaceImages = {
  documents: Document[];
  faceImages: string[];
};

/**
 * Edit personal information schema.
 */

const editPersonalInformationSchema = {
  properties: {
    birthday: { type: 'string' },
    name: { type: 'string' }
  },
  required: []
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `TitleWrapper` styled component.
 */

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(1)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2 ,1fr);
  `}
`;

/**
 * `PersonalInformation` component.
 */

function PersonalInformation(props: Props): JSX.Element {
  const { birthday, blocked, documents, gender, id, label, name, riskRating } = props;
  const initialFormValues = { birthday: formatDate(birthday, { hasTime: false }), name };
  const dispatch = useDispatch();
  const { errors, isSuccessful } = useSelector(getPatchIdentityState);
  const [isEditingFields, setIsEditingFields] = useState(false);
  const [identityName, setIdentityName] = useState(name);
  const [identityBirthday, setIdentityBirthday] = useState(birthday);
  const { showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const translatedStatus = translate(
    `identitiesListDetails.personalInformation.status.${blocked ? 'blocked' : 'active'}`
  );
  const handleEditFields = useCallback(() => {
    return setIsEditingFields(true);
  }, [setIsEditingFields]);

  const handleCloseEditMode = useCallback(() => {
    return setIsEditingFields(false);
  }, [setIsEditingFields]);

  const handleRiskRatingChange = useCallback(
    (riskRating: 'low' | 'medium' | 'high') => {
      dispatch(patchIdentity(id, { riskRating }));
    },
    [dispatch, id]
  );

  const handleSubmit = useCallback(
    (values: any) => {
      if (!documents) {
        return;
      }

      const documentsAndFaceImages = documents.reduce(
        (acc: DocumentsAndFaceImages, document: Document) => {
          const { type } = document;

          if (type === 'FaceImage') {
            if (document.url) {
              acc.faceImages.push(document.url);
            }

            return acc;
          }

          acc.documents.push(document);

          return acc;
        },
        { documents: [], faceImages: [] }
      );

      return dispatch(patchIdentity(id, { ...values, ...documentsAndFaceImages })).then(() => {
        setIdentityName(values.name);
        setIdentityBirthday(values.birthday);
        handleCloseEditMode();
      });
    },
    [dispatch, documents, handleCloseEditMode, id]
  );

  useEffect(() => {
    if (isSuccessful) {
      showSuccessMessage(translate('identitiesListDetails.editIdentity.successMessage'));
    }

    return () => dispatch(resetPatchIdentity());
  }, [dispatch, isSuccessful, showSuccessMessage, translate]);

  useNetworkErrorMessage('identitiesListDetails.errors.network', errors);

  return (
    <Wrapper>
      <Form
        initialValues={initialFormValues}
        jsonSchema={editPersonalInformationSchema}
        onSubmit={handleSubmit}
      >
        <TitleWrapper>
          <Title>
            {translate('identitiesListDetails.personalInformation.title')}
          </Title>

          <EditButtons
            formValues={initialFormValues}
            handleCloseEditMode={handleCloseEditMode}
            handleEditFields={handleEditFields}
            isEditingFields={isEditingFields}
          />
        </TitleWrapper>

        <SideBySide>
          {isEditingFields ? (
            <InputField
              data-cy={'identitiesListDetails-personalInformation-fullName-edit'}
              label={translate('identitiesListDetails.personalInformation.labels.fullName')}
              name={'name'}
            />
          ) : (
            <FieldValue
              data-cy={'identitiesListDetails-personalInformation-fullName'}
              label={translate('identitiesListDetails.personalInformation.labels.fullName')}
            >
              {identityName}
            </FieldValue>
          )}

          <FieldValue
            data-cy={'identitiesListDetails-personalInformation-label'}
            label={translate('identitiesListDetails.personalInformation.labels.partnerOwner')}
          >
            {label}
          </FieldValue>
        </SideBySide>

        <SideBySide>
          {isEditingFields ? (
            <InputField
              data-cy={'identitiesListDetails-personalInformation-dateOfBirth-edit'}
              label={translate('identitiesListDetails.personalInformation.labels.dateOfBirth')}
              name={'birthday'}
            />
          ) : (
            <FieldValue
              data-cy={'identitiesListDetails-personalInformation-dateOfBirth'}
              label={translate('identitiesListDetails.personalInformation.labels.dateOfBirth')}
            >
              {formatDate(identityBirthday, { hasTime: false })}
            </FieldValue>
          )}

          <FieldValue
            data-cy={'identitiesListDetails-personalInformation-gender'}
            label={translate('identitiesListDetails.personalInformation.labels.gender')}
          >
            {startCase(gender)}
          </FieldValue>
        </SideBySide>

        <SideBySide>
          <RiskRating
            defaultValue={riskRating}
            onChange={handleRiskRatingChange}
          />

          <FieldValue
            data-cy={'identitiesListDetails-personalInformation-status'}
            label={translate('identitiesListDetails.personalInformation.labels.status')}
          >
            {translatedStatus}
          </FieldValue>
        </SideBySide>
      </Form>
    </Wrapper>
  );
}

/**
 * Export `PersonalInformation` component.
 */

export default PersonalInformation;

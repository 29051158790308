
/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { insertOperationDocumentType } from 'client/core/redux/action-types/insert-operation-document';

/**
 * `Options` type.
 */

type Options = {
  label: string;
  operationId: string;
  url: string;
};

/**
 * Export `insertOperationDocument`.
 */

export function insertOperationDocument({
  label,
  operationId,
  url
}: Options) {
  return createRequestAction({
    data: { label, url },
    endpoint: {
      name: 'insertOperationDocument',
      params: { operationId }
    },
    handleResponse: ({ data }: any) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: insertOperationDocumentType
  });
}

/**
 * Export `resetInsertOperationDocument`.
 */

export const resetInsertOperationDocument = createResetRequestAction({
  method: 'POST',
  type: insertOperationDocumentType
});

/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/operations';
import { Type, color, media, units } from 'pmint-design-system';
import React from 'react';
import UacManagementDetailsListContent from './list-content';
import UacManagementDetailsListHeader from './list-header';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  operations: Array<Operation>;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 1 / -1;
  margin-bottom: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(7)};
  `}
`;

/**
 * `UacDetailsListEmptyState` styled component.
 */

const UacDetailsListEmptyState = styled(Type.Label).attrs({ as: 'p' })`
  border: 1px solid black;
  border-radius: ${units(1)};
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  padding: ${units(2)};
  text-align: center;
`;

/**
 * `UacManagementDetailsList` component.
 */

function UacManagementDetailsList({ operations }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      {operations.length ? (
        <>
          <UacManagementDetailsListHeader />

          <UacManagementDetailsListContent operations={operations} />
        </>
      ) : (
        <UacDetailsListEmptyState data-cy={'uacManagementDetailsList-emptyState'}>
          {translate('uacManagementDetails.listEmptyState')}
        </UacDetailsListEmptyState>
      )}
    </Wrapper>
  );
}

/**
 * Export `UacManagementDetailsList` component.
 */

export default UacManagementDetailsList;

/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { reviewIdentityType } from 'client/core/redux/action-types/review-identity';

/**
 * `Values` type.
 */

type Values = {
  identityId: string;
  order: string;
};

/**
 * Export `reviewIdentity`.
 */

export const reviewIdentity = ({ identityId: id, order }: Values) => {
  return createRequestAction({
    data: { order },
    endpoint: {
      name: 'reviewIdentity',
      params: { id }
    },
    handleResponse: ({ data }: any) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: reviewIdentityType
  });
};

/**
 * Export `resetReviewIdentity`.
 */

export const resetReviewIdentity = createResetRequestAction({
  method: 'POST',
  type: reviewIdentityType
});


/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/earn-operations';
import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchOperationState`.
 */

export const getFetchOperationState: RequestStateSelector = createRequestStateSelector('fetchOperation');

/**
 * Export `getOperation`.
 */

export function getOperation(state: unknown): Operation {
  return getFetchOperationState(state)?.data || {};
}

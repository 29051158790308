/**
 * Module dependencies.
 */

import React, { Component, ComponentType } from 'react';

/**
 * Export `ErrorInfo` type.
 */

export type ErrorInfo = {
  componentStack: string;
};

/**
 * Export `ErrorProps` type.
 */

export type ErrorProps = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
  errorComponent: ComponentType<ErrorProps>;
};

/**
 * `State` type.
 */

type State = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
};

/**
 * `ErrorBoundary` component.
 */

class ErrorBoundary extends Component<Props, State> {

  /**
   * Initial state.
   */

  state: State = {
    error: null,
    errorInfo: null,
    hasError: false
  };

  /**
   * Component did catch.
   */

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo, hasError: true });
  }

  /**
   * Render.
   */

  render(): JSX.Element {
    const { error, errorInfo, hasError } = this.state;

    if (hasError) {
      const ErrorComponent = this.props.errorComponent;

      return (
        <ErrorComponent
          error={error}
          errorInfo={errorInfo}
        />
      );
    }

    return this.props.children;
  }

}

/**
 * Export `ErrorBoundary` component.
 */

export default ErrorBoundary;

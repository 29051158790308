
/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchEarnPresignUrlType } from 'client/core/redux/action-types/fetch-earn-presign-url';
import { get as getFp } from 'lodash/fp';

/**
 * `EarnOptions` type.
 */

type EarnOptions = {
  fileName: string;
  operationId: string;
};

/**
 * Export `fetchEarnPresignUrl`.
 */

export function fetchEarnPresignUrl({ fileName, operationId }: EarnOptions) {
  return createRequestAction({
    endpoint: {
      name: 'fetchEarnPresignUrl',
      params: { fileName, operationId }
    },
    handleResponse: getFp('data'),
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchEarnPresignUrlType
  });
}

/**
 * Export `resetEarnFetchPresignUrl`.
 */

export const resetFetchEarnPresignUrl = createResetRequestAction({
  type: fetchEarnPresignUrlType
});


/**
 * Module dependencies.
 */

import { Input } from 'pmint-design-system';
import { useField, useFormState } from '@seegno/react-forms';
import React, { ChangeEvent, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {
  'data-cy'?: string;
  disabled?: boolean;
  id?: string;
  label: string;
  name: string;
  type?: string;
  value?: string;
};

/**
 * `InputField` component.
 */

function InputField(props: Props): JSX.Element {
  const { disabled, id, label, name, type, value, ...rest } = props;
  const { error, meta, onBlur, onChange, onFocus, value: formsValue } = useField(name);
  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(target.value);
    },
    [onChange]
  );

  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const inputLabel = useLabel(label, name);

  return (
    <Input
      disabled={isDisabled}
      hasError={hasError}
      id={id}
      label={inputLabel}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      type={type}
      value={formsValue || value}
      {...rest}
    />
  );
}

/**
 * Export `InputField` component.
 */

export default InputField;

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchIdentityVerificationsState`.
 */

export const getFetchIdentityVerificationsState = createRequestStateSelector('fetchIdentityVerifications');

/**
 * Export `getIdentityVerifications`.
 */

export const getIdentityVerifications = (state: unknown) => {
  return getFetchIdentityVerificationsState(state)?.data ?? {};
};


/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { formatDate } from 'client/core/utils/formatter';
import React from 'react';
import styled from 'styled-components';
import useFormatter from 'client/hooks/use-formatter';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  amount: string;
  createdAt: string;
  currency: string;
  destinationAddress: string;
  handleRowClick: (id: string) => void;
  id: string;
  type: string;
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;

  ${media.min('xs')`
    grid-template-columns: 0.4fr 0.4fr 0.1fr 0.2fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover,
  &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: ${units(3)};
  margin-left: ${units(1)};
`;

/**
 * Export `ListRow` component.
 */

function ListRow({
  amount,
  createdAt,
  currency,
  destinationAddress,
  handleRowClick,
  id,
  type
}: Props): JSX.Element {
  const normalizedAmount = useFormatter({ currency, value: amount });
  const { translate } = useTranslate();

  return (
    <RowWrapper onClick={() => handleRowClick(id)}>
      <Value data-cy={'transactions-content-address'}>{destinationAddress}</Value>

      <Value data-cy={'transactions-content-amount'}>
        {`${normalizedAmount}`}
      </Value>

      <Value data-cy={'transactions-content-type'}>
        {translate([`transactions.type.${type}`, 'NaN'])}
      </Value>

      <Value data-cy={'transactions-content-createdAt'}>
        {formatDate(createdAt, { hasTime: true })}
      </Value>
    </RowWrapper>
  );
}

/**
 * Export `ListRow` component.
 */

export default ListRow;


/**
 * Routes.
 */

const routes = {
  dashboard: {
    admin: '/dashboard/admin',
    base: '/dashboard',
    identitiesList: '/dashboard/identities-list',
    identitiesListDetails: '/dashboard/identities-list/details',
    myAccount: '/dashboard/my-account',
    operationDetails: '/dashboard/operations/details',
    operations: '/dashboard/operations',
    partnerDetails: '/dashboard/partners/details',
    partners: '/dashboard/partners',
    transactions: '/dashboard/transactions',
    transactionsDetails: '/dashboard/transactions/details',
    uacManagement: '/dashboard/uac-management',
    uacManagementDetails: '/dashboard/uac-management/details'
  },
  home: '/',
  signIn: '/sign-in'
};

/**
 * Export `routes`.
 */

export default routes;

/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchIdentityOperationsType } from 'client/core/redux/action-types/fetch-identity-operations';
import { get as getFp } from 'lodash/fp';

/**
 * Export `fetchIdentityOperations`.
 */

export const fetchIdentityOperations = (identityId: string) => {
  return createRequestAction({
    endpoint: {
      name: 'identityOperations',
      params: { id: identityId }
    },
    handleResponse: getFp('data'),
    type: fetchIdentityOperationsType
  });
};

/**
 * Export `resetFetchIdentityOperations`.
 */

export const resetFetchIdentityOperations = createResetRequestAction({
  type: fetchIdentityOperationsType
});

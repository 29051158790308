/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-bottom: 1px solid ${color('gray600')};
  display: grid;
  grid-column-gap: ${units(1.75)};
  grid-template-columns: 1fr 1fr 2fr 2fr 3fr;
  justify-items: start;

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(1)} 0;
  `}
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 500;
  line-height: 24px;
  margin-left: ${units(1)};
`;

/**
 * `OperationsListHeader` component.
 */

function OperationsListHeader(): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Value>{translate('identitiesListDetails.operationsList.labels.type')}</Value>

      <Value>{translate('identitiesListDetails.operationsList.labels.amount')}</Value>

      <Value>{translate('identitiesListDetails.operationsList.labels.createdAt')}</Value>

      <Value>{translate('identitiesListDetails.operationsList.labels.status')}</Value>

      <Value>{translate('identitiesListDetails.operationsList.labels.transaction')}</Value>
    </Wrapper>
  );
}

/**
 * Export `OperationsListHeader` component.
 */

export default OperationsListHeader;

/**
 * Module dependencies.
 */

import type { FetchIdentitiesType } from 'client/core/types/identities';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchIdentitiesType } from 'client/core/redux/action-types/fetch-identities';

/**
 * Export `fetchIdentities`.
 */

export const fetchIdentities = ({ filter = {}, number = '0', search = '', size = '20' }: FetchIdentitiesType) => {
  return createRequestAction({
    endpoint: 'identities',
    getQuery: () => ({
      filter: {
        ...filter,
        search
      },
      page: {
        number,
        size
      }
    }),
    handleResponse: (response: any) => response.data,
    type: fetchIdentitiesType
  });
};

/**
 * Export `resetFetchIdentities`.
 */

export const resetFetchIdentities = createResetRequestAction({
  type: fetchIdentitiesType
});

/**
 * Module dependencies.
 */

import { units } from 'pmint-design-system';

/**
 * Export `layout`.
 */

export const layout = {
  header: {
    height: {
      line: units(1)
    }
  }
};


/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getPostIdentityCommentState`.
 */

export const getPostIdentityCommentState: RequestStateSelector = createRequestStateSelector('postIdentityComment');

/**
 * Module dependencies.
 */

import {
  IdentityDocumentUpload,
  performIdentityDocumentUpload
} from 'client/core/redux/actions/perform-identity-document-upload';

import { Modal, Type, color, media, units } from 'pmint-design-system';
import { resetFetchIdentityDocuments } from 'client/core/redux/actions/fetch-identity-documents';
import { resetFetchPresignUrl } from 'client/core/redux/actions/fetch-presign-url';
import { resetUploadIdentityDocument } from 'client/core/redux/actions/upload-identity-document';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import FileInput from 'client/components/core/file-input';
import Form from 'client/components/core/forms/form';
import ModalCancelButton from 'client/components/core/modal/cancel-button';
import React, { useCallback, useEffect, useMemo } from 'react';
import Select from 'client/components/core/forms/fields/select';
import SubmitButton from 'client/components/core/forms/submit-button';
import closeIcon from 'pmint-design-system/icons/close-24px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  identityId: string;
  isVisible: boolean;
  onRequestClose: () => void;
};

/**
 * Initial values.
 */

const initialValues = {
  document: null,
  documentSide: 'FrontSide',
  documentType: 'IdentityCard'
};

/**
 * Browse schema.
 */

const browseSchema = {
  properties: {
    document: { type: 'object' }
  },
  required: ['document']
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: 30px ${units(2.5)};

  ${media.min('xs')`
    padding-left: ${units(4)};
    padding-right: ${units(4)};
  `}

  ${media.min('xl')`
    padding: 50px ${units(15)} ${units(10)};
  `}
`;

/**
 * `StyledTitle` styled component.
 */

const StyledTitle = styled(Type.H5).attrs({ as: 'h1' })`
  color: ${color('textColor')};
  font-weight: 500;
  margin-bottom: 50px;
  text-align: center;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-weight: 300;
  margin-bottom: 34px;
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  margin-top: ${units(5)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  min-height: ${units(8)};
`;

/**
 * `AddDocumentModal` component.
 */

function AddDocumentModal({ identityId, isVisible, onRequestClose }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const documentSideOptions = useMemo(() => {
    return [
      {
        label: translate('modals.uploadDocument.documents.sides.frontSide'),
        value: 'FrontSide'
      },
      {
        label: translate('modals.uploadDocument.documents.sides.backSide'),
        value: 'BackSide'
      }
    ];
  }, [translate]);

  const documentTypeOptions = useMemo(() => {
    return [
      {
        label: translate('modals.uploadDocument.documents.types.identityCard'),
        value: 'IdentityCard'
      },
      {
        label: translate('modals.uploadDocument.documents.types.driverLicense'),
        value: 'DriverLicense'
      },
      {
        label: translate('modals.uploadDocument.documents.types.other'),
        value: 'Other'
      },
      {
        label: translate('modals.uploadDocument.documents.types.passport'),
        value: 'Passport'
      },
      {
        label: translate('modals.uploadDocument.documents.types.residencePermit'),
        value: 'ResidencePermit'
      },
      {
        label: translate('modals.uploadDocument.documents.types.utilityBill'),
        value: 'UtilityBill'
      }
    ];
  }, [translate]);

  const onSubmit = useCallback(
    async ({ document, documentSide, documentType }: IdentityDocumentUpload) => {
      try {
        await dispatch(
          performIdentityDocumentUpload({
            document,
            documentSide,
            documentType,
            identityId
          })
        );

        showSuccessMessage(translate('modals.uploadDocument.success'));
        onRequestClose();
      } catch (error) {
        showErrorMessage(translate('modals.uploadDocument.error'));
      }
    },
    [dispatch, identityId, onRequestClose, showErrorMessage, showSuccessMessage, translate]
  );

  useEffect(() => {
    return () => {
      dispatch(resetFetchPresignUrl());
      dispatch(resetUploadIdentityDocument());
      dispatch(resetFetchIdentityDocuments());
    };
  }, [dispatch]);

  return (
    <Modal
      closeButtonAriaLabel={translate('common.actions.close')}
      closeIcon={closeIcon}
      isVisible={isVisible}
      onRequestClose={onRequestClose}
    >
      <Form
        initialValues={initialValues}
        jsonSchema={browseSchema}
        onSubmit={onSubmit}
      >
        <Wrapper>
          <StyledTitle>{translate('modals.uploadDocument.title')}</StyledTitle>

          <Description>{translate('modals.uploadDocument.description')}</Description>

          <Select
            items={documentSideOptions}
            label={translate('modals.uploadDocument.labels.documentSide')}
            name={'documentSide'}
          />

          <Select
            items={documentTypeOptions}
            label={translate('modals.uploadDocument.labels.documentType')}
            name={'documentType'}
          />

          <FileInput
            isModalVisible={isVisible}
            name={'document'}
          />

          <SideBySide>
            <ModalCancelButton onRequestClose={onRequestClose} />

            <StyledSubmitButton>{translate('common.actions.continue')}</StyledSubmitButton>
          </SideBySide>
        </Wrapper>
      </Form>
    </Modal>
  );
}

/**
 * Export `AddDocumentModal` component.
 */

export default AddDocumentModal;

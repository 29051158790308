/**
 * Module dependencies.
 */

import type { AuthenticationStatus } from 'client/core/redux/reducer/authentication';
import { get } from 'lodash';

/**
 * Export `getAuthenticationStatus`.
 */

export function getAuthenticationStatus(state: any): AuthenticationStatus {
  return get(state, 'authentication.status', 'unknown');
}

/**
 * Export `getAuthenticationToken`.
 */

export function getAuthenticationToken(state: any): string | undefined {
  return get(state, 'authentication.token');
}

/**
 * Module dependencies.
 */

import axios from 'axios';

/**
 * `UploadDocument` type.
 */

type UploadDocument = {
  file: File;
  url: string;
};

/**
 * Export `uploadDocument`.
 */

export function uploadDocument(options: UploadDocument) {
  const { file, url } = options;
  const formData = new FormData();

  formData.append('Content-Type', file.type);
  formData.append('file', file);

  return axios.post(url, formData);
}


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { postIdentityCommentType } from 'client/core/redux/action-types/post-identity-comment';

/**
 * Incoming 'CommentData' type
 */

type CommentData = {
  text: string;
};

/**
 * Export `postIdentityComment`.
 */

export function postIdentityComment(identityId: string, data: CommentData) {
  return createRequestAction({
    data,
    endpoint: {
      name: 'identityComments',
      params: { id: identityId }
    },
    handleResponse: (response: any) => response.data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: postIdentityCommentType
  });
}

/**
 * Export `resetPostIdentityComment`.
 */

export const resetPostIdentityComment = createResetRequestAction({
  method: 'POST',
  type: postIdentityCommentType
});

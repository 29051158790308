/**
 * Module dependencies.
 */

import type { CloseUACCaseValuesType } from 'client/core/types/uacs';
import { Modal, Type, color, media, units } from 'pmint-design-system';
import { closeUAC, resetCloseUAC } from 'client/core/redux/actions/close-uac';
import { getCloseUACState } from 'client/core/redux/selectors/close-uac';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import CheckboxField from 'client/components/core/forms/fields/checkbox';
import Form from 'client/components/core/forms/form';
import ModalCancelButton from 'client/components/core/modal/cancel-button';
import RawHtml from 'react-components/raw-html';
import React, { useCallback, useEffect } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import TextAreaField from 'client/components/core/forms/fields/text-area';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isVisible: boolean;
  name: string;
  onRequestClose: () => void;
  uacId: string;
};

/**
 * Initial values.
 */

const initialValues = {
  sarWasFilled: false
};

/**
 * Export `closeCaseSchema`.
 */

export const closeCaseSchema = {
  additionalProperties: false,
  properties: {
    closingNotes: {
      maxLength: 5000,
      minLength: 1,
      type: 'string'
    },
    sarWasFilled: { type: 'boolean' }
  },
  required: ['closingNotes', 'sarWasFilled'],
  type: 'object'
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: 30px ${units(2.5)};

  ${media.min('xs')`
    padding-left: ${units(4)};
    padding-right: ${units(4)};
  `}

  ${media.min('xl')`
    padding: 50px ${units(15)} ${units(10)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H5).attrs({ as: 'h1' })`
  color: ${color('textColor')};
  font-size: 50;
  font-weight: 500;
  margin-bottom: ${units(4)};
  text-align: center;
`;

/**
 * `Name` styled component.
 */

const Name = styled(Type.Label).attrs({ as: 'h2' })`
  color: ${color('textColor')};
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: ${units(4)};
`;

/**
 * `SubTitle` styled component.
 */

const SubTitle = styled(Type.Label).attrs({ as: 'h2' })`
  color: ${color('textColor')};
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: ${units(4)};
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  margin-top: ${units(5)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)<{
  color?: string;
}>`
  background-color: ${props => props.color ?? 'black'};
  border-color: ${props => props.color ?? 'black'};
  min-height: ${units(8)};
`;

/**
 * `CloseCaseModal` component.
 */

function CloseCaseModal(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const { errors, isSuccessful } = useSelector(getCloseUACState);
  const history = useHistory();
  const { showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const { isVisible, name, onRequestClose, uacId } = props;

  const handleCloseCaseModalSubmit = useCallback(
    (values: CloseUACCaseValuesType) => {
      dispatch(closeUAC(uacId, values)).then(() => {
        showSuccessMessage(translate('uacManagementDetails.modal.closeCaseSuccess'));

        history.push(routes.dashboard.uacManagementDetails);
      });

      return Promise.resolve();
    },
    [dispatch, history, showSuccessMessage, translate, uacId]
  );

  useNetworkErrorMessage('uacManagementDetails.modal.errors', errors);

  useEffect(() => {
    if (errors || isSuccessful) {
      dispatch(resetCloseUAC());
    }
  }, [dispatch, errors, isSuccessful]);

  return (
    <Modal isVisible={isVisible}
      onRequestClose={onRequestClose}
    >
      <Form
        errorsBasePath={'uacManagementDetails.modal.errors'}
        initialValues={initialValues}
        jsonSchema={closeCaseSchema}
        onSubmit={handleCloseCaseModalSubmit}
      >
        <Wrapper data-cy={'uacManagementDetails-closeCaseModal'}>
          <Title data-cy={'uacManagementDetails-closeCaseModal-title'}>
            {translate('uacManagementDetails.modal.title')}
          </Title>

          <Name data-cy={'uacManagementDetails-closeCaseModal-name'}>{name}</Name>

          <RawHtml data-cy={'uacManagementDetails-closeCaseModal-subTitle'}
            element={SubTitle}
          >
            {translate('uacManagementDetails.modal.description')}
          </RawHtml>

          <CheckboxField
            dataCy={'uacManagementDetails-closeCaseModal-sarWasFilled'}
            label={translate('uacManagementDetails.modal.sarWasFilled')}
            name={'sarWasFilled'}
          />

          <TextAreaField
            dataCy={'uacManagementDetails-closeCaseModal-closingComment'}
            label={translate('uacManagementDetails.modal.closingComment')}
            name={'closingNotes'}
            type={'text'}
          />

          <SideBySide>
            <ModalCancelButton
              dataCy={'uacManagementDetails-closeCaseModal-cancelButton'}
              onRequestClose={onRequestClose}
            />

            <StyledSubmitButton dataCy={'uacManagementDetails-closeCaseModal-proceedButton'}>
              {translate('common.actions.proceed')}
            </StyledSubmitButton>
          </SideBySide>
        </Wrapper>
      </Form>
    </Modal>
  );
}

/**
 * Export `CloseCaseModal` component.
 */

export default CloseCaseModal;

/**
 * Module dependencies.
 */

import { trim } from 'lodash';

/**
 * Export `getFieldValue`.
 */

export const getFieldValue = (fieldValue: string) => {
  const trimmedValue = trim(fieldValue);

  if (trimmedValue.length === 0) {
    return undefined;
  }

  return trimmedValue;
};

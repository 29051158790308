
/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `rejectOperationState`.
 */

export const rejectOperationState: RequestStateSelector = createRequestStateSelector('rejectOperation');

/**
 * Module dependencies.
 */

import { Button, Type, color, units } from 'pmint-design-system';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  documentUrl: string | undefined;
};

/**
 * `StyledButtonWrapper` styled component.
 */

const StyledButtonWrapper = styled.div`
  text-align: center;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  align-items: center;
  min-height: ${units(8)};
  min-width: ${units(30)};
`;

/**
 * `PDFDocument` component.
 */

function PDFDocument({ documentUrl }: Props): JSX.Element {
  const { translate } = useTranslate();
  const handlePdfViewButtonClick = useCallback(() => {
    window.open(documentUrl);
  }, [documentUrl]);

  return (
    <>
      <Type.Label
        as={'h2'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={units(8)}
      >
        {translate('documents.modals.viewDocument.viewPdfLabel')}
      </Type.Label>

      <StyledButtonWrapper>
        <StyledButton onClick={handlePdfViewButtonClick}>
          {translate('documents.modals.viewDocument.viewPdfButton')}
        </StyledButton>
      </StyledButtonWrapper>
    </>
  );
}

/**
 * Export `PDFDocument` component.
 */

export default PDFDocument;

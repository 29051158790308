/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { fetchIdentitiesCountType } from 'client/core/redux/action-types/fetch-identities-count';

/**
 * Export `fetchIdentitiesCount`.
 */

export const fetchIdentitiesCount = () => {
  return createRequestAction({
    endpoint: 'identitiesCount',
    handleResponse: (response: any) => response.data,
    type: fetchIdentitiesCountType
  });
};

/**
 * Export `resetFetchIdentitiesCount`.
 */

export const resetFetchIdentitiesCount = createResetRequestAction({
  type: fetchIdentitiesCountType
});

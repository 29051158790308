/**
 * Module dependencies.
 */

import { Link, Type, color, media, units } from 'pmint-design-system';
import type { Operation } from 'client/core/types/operations';
import { formatDate } from 'client/core/utils/formatter';
import { get, template } from 'lodash';
import { theme } from 'styled-tools';
import React, { useCallback } from 'react';
import config from 'config';
import styled from 'styled-components';
import urlJoin from 'url-join';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  operations: Array<Operation>;
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;
  transition: ${theme('transitions.defaultTransition')} background-color;

  ${media.min('xs')`
    grid-template-columns: 1fr 1fr 2fr 2fr 3fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover,
  &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  margin-left: ${units(1)};
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  align-items: center;
  color: ${color('textColor')};
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: start;
`;

/**
 * Resolve explorer url.
 */

function resolveExplorerUrl(endpoint: string, interpolations?: any): string {
  const baseUrl = config.get<string>('explorer.baseUrl');
  const urls = { transactions: '/tx/{{transactionHash}}' };

  return urlJoin(baseUrl, template(get(urls, 'endpoint'), { interpolate: /{{([\s\S]+?)}}/g })(interpolations));
}

/**
 * `OperationsListContent` component.
 */

function OperationsListContent({ operations }: Props): JSX.Element {
  const { translate } = useTranslate();
  const onExplorerLinkClick = useCallback((transactionHash: string) => {
    const explorerLink = resolveExplorerUrl('transactions', { transactionHash });

    window.open(explorerLink);
  }, []);

  return (
    <>
      {operations.map(({ amount, createdAt, id, status, transactionHash, type }) => (
        <RowWrapper data-cy={`identitiesListDetails-operationsList-${id}`}
          key={id}
        >
          <Value>{translate(`identitiesListDetails.operationsList.values.types.${type}`)}</Value>

          <Value>{amount}</Value>

          <Value>{formatDate(createdAt, { hasTime: true })}</Value>

          <Value>{translate(`identitiesListDetails.operationsList.values.status.${status}`)}</Value>

          <Value>
            <StyledLink onClick={() => onExplorerLinkClick(transactionHash)}
              size={'small'}
            >
              {translate('identitiesListDetails.operationsList.viewInExplorer')}
            </StyledLink>
          </Value>
        </RowWrapper>
      ))}
    </>
  );
}

/**
 * Export `OperationsListContent` component.
 */

export default OperationsListContent;

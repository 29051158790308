/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `refreshTokenType`.
 */

export const refreshTokenType = 'REFRESH_TOKEN';

/**
 * Export `refreshTokenTypes`.
 */

export const refreshTokenTypes = createRequestActionTypes({
  method: 'POST',
  type: refreshTokenType
});

/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getSignInState`.
 */

export const getSignInState: RequestStateSelector = createRequestStateSelector('signIn');

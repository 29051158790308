
/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchIdentityCommentsType } from 'client/core/redux/action-types/fetch-identity-comments';
import { get as getFp } from 'lodash/fp';

/**
 * Export `fetchIdentityComments`.
 */

export function fetchIdentityComments(identityId: string, sort?: string) {
  let queryString;

  if (sort) {
    queryString = {
      getQuery: () => {
        return { sort };
      }
    };
  }

  return createRequestAction({
    endpoint: {
      name: 'identityComments',
      params: { id: identityId }
    },
    handleResponse: getFp('data'),
    type: fetchIdentityCommentsType,
    ...queryString
  });
}

/**
 * Export `resetFetchIdentityComments`.
 */

export const resetFetchIdentityComments = createResetRequestAction({
  type: fetchIdentityCommentsType
});

/**
 * Module dependencies.
 */

import type { CloseUACCaseValuesType } from 'client/core/types/uacs';
import { closeUACType } from 'client/core/redux/action-types/close-uac';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';

/**
 * Export `closeUAC`.
 */

export const closeUAC = (id: string, closeData: CloseUACCaseValuesType) =>
  createRequestAction({
    data: closeData,
    endpoint: {
      name: 'closeUAC',
      params: { id }
    },
    handleResponse: ({ data }: any) => data,
    method: 'POST',
    type: closeUACType
  });

/**
 * Export `resetCloseUAC`.
 */

export const resetCloseUAC = createResetRequestAction({
  method: 'POST',
  type: closeUACType
});

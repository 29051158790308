/**
 * Module dependencies.
 */

import type { Identity } from 'client/core/types/identities';
import { ifProp } from 'styled-tools';
import { resetReopenIdentityReview } from 'client/core/redux/actions/reopen-identity-review';
import { useDispatch } from 'react-redux';
import ContactInformation from './contact-information';
import HomeInformation from './home-information';
import PersonalInformation from './personal-information';
import React, { useEffect } from 'react';
import ReviewIdentity from './review-identity';
import TaxInformation from './tax-information';
import config from 'config';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  identity: Identity;
  isSelected: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isSelected: boolean | undefined;
}>`
  display: ${ifProp('isSelected', 'block', 'none')};
`;

/**
 * `IdentityDetails` component.
 */

function IdentityDetails({ identity, isSelected }: Props): JSX.Element {
  const { isAcuantActive } = config.get<{ isAcuantActive: boolean }>('identityReview');
  const {
    address,
    birthday,
    blocked,
    documents,
    email,
    gender,
    id,
    label,
    name,
    phone: { number: phoneNumber },
    riskRating,
    tax
  } = identity;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetReopenIdentityReview());
    };
  }, [dispatch]);

  return (
    <Wrapper isSelected={isSelected}>
      <PersonalInformation
        birthday={birthday}
        blocked={blocked}
        documents={documents}
        gender={gender}
        id={id}
        label={label}
        name={name}
        riskRating={riskRating}
      />

      <ContactInformation email={email}
        phoneNumber={phoneNumber}
      />

      <TaxInformation taxInformation={tax} />

      <HomeInformation homeInformation={address} />

      {!isAcuantActive && <ReviewIdentity identity={identity} />}
    </Wrapper>
  );
}

/**
 * Export `IdentityDetails` component.
 */

export default IdentityDetails;


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchPartnerOperationsType } from 'client/core/redux/action-types/fetch-partner-operations';
import { get as getFp } from 'lodash/fp';

/**
 * Export `fetchPartnerOperations`.
 */

export function fetchPartnerOperations(partner: string) {
  return createRequestAction({
    endpoint: {
      name: 'fetchPartnerOperations',
      params: { partner }
    },
    handleResponse: getFp('data.data'),
    type: fetchPartnerOperationsType
  });
}

/**
 * Export `resetFetchPartnerOperations`.
 */

export const resetFetchPartnerOperations = createResetRequestAction({
  type: fetchPartnerOperationsType
});

/**
 * Module dependencies.
 */

import { clamp, isEmpty, isNil, omit } from 'lodash';
import { units } from 'pmint-design-system';
import { useField, useFormState } from '@seegno/react-forms';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import Dropzone from './dropzone';
import FileDetails from './file-details';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  disabled?: boolean;
  isModalVisible: boolean;
  name: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `FileInput` component.
 */

function FileInput(props: Props): JSX.Element {
  const { disabled, isModalVisible, name } = props;
  const { translate } = useTranslate();
  const { showErrorMessage } = useSnackbar();
  const { error, meta, onChange, ...fieldProps } = useField(name);
  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<unknown | null>(null);
  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const handleDrop = useCallback((files: File[]) => {
    if (!isEmpty(files)) {
      setSelectedFile(files[0]);
    }
  }, []);

  const handleRemove = useCallback(() => {
    setSelectedFile(null);
    setProgress(0);
    setFileError(null);
    onChange(null);
  }, [onChange]);

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.addEventListener('load', () => {
      try {
        onChange(selectedFile);
      } catch (error) {
        showErrorMessage(translate('fileInput.fileError'));
        setFileError(error);
      }
    });

    fileReader.addEventListener('progress', (event: ProgressEvent) => {
      const progress = clamp(event.total / event.loaded, 0, 1);

      setProgress(progress);
    });

    fileReader.addEventListener('error', () => {
      showErrorMessage(translate('fileInput.fileError'));
      setFileError(fileReader.error);
    });

    fileReader.readAsDataURL(selectedFile);

    return () => {
      fileReader.abort();
    };
  }, [onChange, selectedFile, showErrorMessage, translate]);

  useEffect(() => {
    if (!isModalVisible) {
      const timeout = setTimeout(() => {
        onChange(null);
        setSelectedFile(null);
        setFileError(null);
        setProgress(0);
      }, 350);

      return () => clearTimeout(timeout);
    }
  }, [isModalVisible, onChange]);

  return (
    <Wrapper>
      {selectedFile ? (
        <FileDetails file={selectedFile}
          hasError={!isNil(fileError)}
          onRemove={handleRemove}
          progress={progress}
        />
      ) : (
        <Dropzone
          disabled={isDisabled}
          hasError={hasError}
          isSubmitting={isSubmitting}
          name={name}
          onDrop={handleDrop}
          {...(omit(fieldProps, ['value']) as any)}
        />
      )}
    </Wrapper>
  );
}

/**
 * Export `FileInput` component.
 */

export default FileInput;


/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `approveTransactionType`.
 */

export const approveTransactionType = 'APPROVE_TRANSACTION';

/**
 * Export `approveTransactionTypes`.
 */

export const approveTransactionTypes = createRequestActionTypes({
  type: approveTransactionType
});


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchOperationDocumentsType } from 'client/core/redux/action-types/fetch-operation-documents';
import { get as getFp } from 'lodash/fp';

/**
 * Export `fetchOperationDocuments`.
 */

export function fetchOperationDocuments(operationId: string) {
  return createRequestAction({
    endpoint: {
      name: 'fetchOperationDocuments',
      params: { operationId }
    },
    handleResponse: getFp('data'),
    type: fetchOperationDocumentsType
  });
}

/**
 * Export `resetFetchOperationDocuments`.
 */

export const resetFetchOperationDocuments = createResetRequestAction({
  type: fetchOperationDocumentsType
});

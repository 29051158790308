
/**
 * Module dependencies.
 */

import { fetchEarnPresignUrl } from 'client/core/redux/actions/fetch-earn-presign-url';
import { fetchOperationDocuments } from 'client/core/redux/actions/fetch-operation-documents';
import { insertOperationDocument } from 'client/core/redux/actions/insert-operation-document';
import { uploadDocument } from 'client/core/utils/upload-document';

/**
 * `Values` type.
 */

type Values = {
  file: {
    operationDocument: File;
  };
  operationId: string;
};

/**
 * Export `performOperationDocumentUpload`.
 */

export const performOperationDocumentUpload = (values: Values) => async (dispatch: any) => {
  const { file, operationId } = values;
  const { operationDocument: { name: fileName } } = file;
  const { value: { data } } = await dispatch(fetchEarnPresignUrl({ fileName, operationId }));
  const { fields: { key }, url } = data;
  const fileUrl = `${url}${key}`;

  await dispatch(uploadDocument({ ...data, file }));
  await dispatch(insertOperationDocument({
    label: file.operationDocument.name,
    operationId,
    url: fileUrl
  }));

  await dispatch(fetchOperationDocuments(operationId));
};


/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchDocumentPresignUrlType } from 'client/core/redux/action-types/fetch-document-presign-url';

/**
 * Export `fetchDocumentPresignUrl`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchDocumentPresignUrlType
  })
);

/**
 * Module dependencies.
 */

import type { Document } from 'client/core/types/documents';
import { createRequestStateSelector } from 'core-redux/request';
import { get } from 'lodash';

/**
 * Export `getFetchIdentityDocumentsState`.
 */

export const getFetchIdentityDocumentsState = createRequestStateSelector('fetchIdentityDocuments');

/**
 * Export `getIdentityDocuments`.
 */

export const getIdentityDocuments = (state: unknown): Array<Document> => {
  return get(getFetchIdentityDocumentsState(state), 'data') || [];
};

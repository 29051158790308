
/**
 * Module dependencies.
 */

import { approveTransactionType } from 'client/core/redux/action-types/approve-transaction';
import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';

/**
 * Export `approveTransaction`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'POST',
    type: approveTransactionType
  })
);

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchUacsType } from 'client/core/redux/action-types/fetch-uacs';

/**
 * Export `fetchUacs`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchUacsType
  })
);


/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { patchUserType } from 'client/core/redux/action-types/patch-user';

/**
 * Export `patchUser`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'PATCH',
    type: patchUserType
  })
);

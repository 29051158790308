
/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchEarnDocumentPresignUrlType } from 'client/core/redux/action-types/fetch-earn-document-presign-url';
import { get as getFp } from 'lodash/fp';

/**
 * `Options` type.
 */

type Options = {
  documentId: string;
};

/**
 * Export `fetchEarnDocumentPresignUrl`.
 */

export function fetchEarnDocumentPresignUrl({ documentId }: Options) {
  return createRequestAction({
    endpoint: {
      name: 'fetchEarnDocumentPresignUrl',
      params: { documentId }
    },
    handleResponse: getFp('data'),
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchEarnDocumentPresignUrlType
  });
}

/**
 * Export `resetFetchEarnDocumentPresignUrl`.
 */

export const resetFetchEarnDocumentPresignUrl = createResetRequestAction({
  type: fetchEarnDocumentPresignUrlType
});

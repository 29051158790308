/**
 * Module dependencies.
 */

import type { Identity } from 'client/core/types/identities';
import { createRequestStateSelector } from 'core-redux/request';
import { get } from 'lodash';

/**
 * Export `getFetchIdentitiesState`.
 */

export const getFetchIdentitiesState = createRequestStateSelector('fetchIdentities');

/**
 * Export `getAllIdentities`.
 */

export const getAllIdentities = (state: unknown): Array<Identity> => {
  return get(state, 'identities', []);
};

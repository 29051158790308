
/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchOperationType } from 'client/core/redux/action-types/fetch-operation';
import { normalizeEarnOperation } from 'client/core/utils/normalizer';

/**
 * Export `fetchOperation`.
 */

export function fetchOperation(operationId: string) {
  return createRequestAction({
    endpoint: {
      name: 'fetchOperation',
      params: { operationId }
    },
    handleResponse: ({ data }: any) => normalizeEarnOperation(data),
    type: fetchOperationType
  });
}

/**
 * Export `resetFetchOperation`.
 */

export const resetFetchOperation = createResetRequestAction({
  type: fetchOperationType
});


/**
 * Module dependencies.
 */

import { Route, Switch, useLocation } from 'react-router-dom';
import { ifProp } from 'styled-tools';
import { isNil } from 'lodash';
import { matchPath } from 'react-router';
import { media, units } from 'pmint-design-system';
import DashboardContent from 'client/components/dashboard/dashboard-content';
import DashboardHeader from 'client/components/dashboard/dashboard-header';
import DashboardMenuList from 'client/components/dashboard/dashboard-menu-list';
import DefaultErrorMessage from 'client/components/core/error-boundaries/default-error-message';
import ErrorBoundary from 'client/components/core/error-boundaries/error-boundary';
import IdentitiesList from './identities-list';
import IdentitiesListDetails from './identities-list-details';
import MyAccount from 'client/containers/dashboard/my-account';
import OperationDetails from './operation-details';
import Operations from 'client/containers/dashboard/operations';
import PageLayout from 'client/components/page-layout';
import PartnerDetails from 'client/containers/dashboard/partner-details';
import Partners from 'client/containers/dashboard/partners';
import React from 'react';
import Redirect from 'client/components/routing/redirect';
import TransactionListDetails from 'client/containers/dashboard/transaction-details';
import Transactions from 'client/containers/dashboard/transactions';
import UacManagement from 'client/containers/dashboard/uac-management';
import UacManagementDetails from 'client/containers/dashboard/uac-management-details';
import routes from 'core/routes';
import styled, { css } from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isDashboard: boolean | undefined;
}>`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(3)};
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content min-content auto;

  ${ifProp(
    'isDashboard',
    css`
      grid-template-rows: min-content auto;
    `
  )}

  ${media.min('md')`
    grid-template-columns: repeat(8, 1fr);
  `}
`;

/**
 * `Dashboard` container.
 */

function Dashboard(): JSX.Element {
  const location = useLocation();
  const isDashboard = !isNil(
    matchPath(location.pathname, {
      exact: true,
      path: routes.dashboard.base
    })
  );

  return (
    <PageLayout sidebarContent={<DashboardMenuList />}>
      <Wrapper isDashboard={isDashboard}>
        <DashboardHeader />

        <ErrorBoundary errorComponent={DefaultErrorMessage}>
          <Switch>
            <Route
              exact
              path={routes.dashboard.base}
            >
              <DashboardContent />
            </Route>

            <Route
              exact
              path={routes.dashboard.myAccount}
            >
              <MyAccount />
            </Route>

            <Route
              exact
              path={routes.dashboard.operations}
            >
              <Operations />
            </Route>

            <Route
              exact
              path={routes.dashboard.operationDetails}
            >
              <OperationDetails />
            </Route>

            <Route
              exact
              path={routes.dashboard.partners}
            >
              <Partners />
            </Route>

            <Route
              exact
              path={routes.dashboard.partnerDetails}
            >
              <PartnerDetails />
            </Route>

            <Route
              exact
              path={routes.dashboard.uacManagement}
            >
              <UacManagement />
            </Route>

            <Route
              exact
              path={routes.dashboard.uacManagementDetails}
            >
              <UacManagementDetails />
            </Route>

            <Route
              exact
              path={routes.dashboard.identitiesList}
            >
              <IdentitiesList />
            </Route>

            <Route
              exact
              path={routes.dashboard.identitiesListDetails}
            >
              <IdentitiesListDetails />
            </Route>

            <Route
              exact
              path={routes.dashboard.transactions}
            >
              <Transactions />
            </Route>

            <Route
              exact
              path={routes.dashboard.transactionsDetails}
            >
              <TransactionListDetails />
            </Route>

            <Redirect
              status={302}
              to={routes.dashboard.base}
            />
          </Switch>
        </ErrorBoundary>
      </Wrapper>
    </PageLayout>
  );
}

/**
 * Export `Dashboard` container.
 */

export default Dashboard;

/**
 * Module dependencies.
 */

import { AnyAction } from 'redux';
import type { FetchIdentitiesType } from 'client/core/types/identities';
import { color, media, units } from 'pmint-design-system';
import { fetchIdentities, resetFetchIdentities } from 'client/core/redux/actions/fetch-identities';
import { getAllIdentities } from 'client/core/redux/selectors/fetch-identities';
import { ifProp, theme } from 'styled-tools';
import { reduce } from 'lodash';
import { searchIdentities } from 'client/core/redux/actions/identities';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import IdentitiesListContent from './list-content';
import IdentitiesListHeader from './list-header';
import IdentitiesListSearchBox from './list-search-box';
import ListEmptyState from 'client/components/core/lists/empty-state';
import RawHtml from 'react-components/raw-html';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 2 / -2;
  margin-bottom: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(4)};
  `}
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  margin: ${units(5)} 0 ${units(9)};
  text-align: center;
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  ${theme('typography.styles.label')}

  color: ${color('textColor')};

  > a {
    color: ${color('textColor')};
    text-decoration: none;

    &:hover, &:focus {
      color: ${color('textColorLight')};
    }
  }
`;

/**
 * `FilterWrapper` styled component.
 */

const FilterWrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * Filter button.
 */

const FilterButton = styled.button<{
  isActive: boolean;
}>`
  background-color: ${ifProp('isActive', color('gray400'), color('gray200'))};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: ${units(0.5)};
  outline: none;
  padding: ${units(2)} ${units(2)};
`;

/**
 * Action types.
 */

const actionTypes = {
  SELECT_ACUANT_APPROVED: 'SELECT_ACUANT_APPROVED',
  SELECT_ACUANT_PENDING: 'SELECT_ACUANT_PENDING',
  SELECT_APPROVED_IN_PRIME_TRUST: 'SELECT_APPROVED_IN_PRIME_TRUST',
  SELECT_BLOCKED: 'SELECT_BLOCKED',
  SELECT_PENDING_IN_PRIME_TRUST: 'SELECT_PENDING_IN_PRIME_TRUST'
};

/**
 * Reducer function.
 */

function reducer(state: any, action: AnyAction) {
  switch (action.type) {
    case actionTypes.SELECT_ACUANT_APPROVED:
      return {
        ...state,
        acuantApproved: !state.acuantApproved
      };

    case actionTypes.SELECT_ACUANT_PENDING:
      return {
        ...state,
        acuantPending: !state.acuantPending
      };

    case actionTypes.SELECT_BLOCKED:
      return {
        ...state,
        blocked: !state.blocked
      };

    case actionTypes.SELECT_APPROVED_IN_PRIME_TRUST:
      return {
        ...state,
        approvedInPrimeTrust: !state.approvedInPrimeTrust
      };

    case actionTypes.SELECT_PENDING_IN_PRIME_TRUST:
      return {
        ...state,
        pendingInPrimeTrust: !state.pendingInPrimeTrust
      };

    default:
      return state;
  }
}

/**
 * `FiltersKey` type.
 */

type FiltersKey = 'acuantApproved' | 'acuantPending' | 'approvedInPrimeTrust' | 'blocked' | 'pendingInPrimeTrust' | 'pendingInPublicMint';

/**
 * `Filter` type.
 */

type Filter = {
  kycProviderStatus: 'acuant:approved' | 'acuant:pending' | 'primeTrust:approved' | 'primeTrust:pending' | 'publicMint:pending';
} | {
  blocked: true;
};

/**
 * Available filters.
 */

const availableFilters: Record<FiltersKey, Filter> = {
  acuantApproved: { kycProviderStatus: 'acuant:approved' },
  acuantPending: { kycProviderStatus: 'acuant:pending' },
  approvedInPrimeTrust: { kycProviderStatus: 'primeTrust:approved' },
  blocked: { blocked: true },
  pendingInPrimeTrust: { kycProviderStatus: 'primeTrust:pending' },
  pendingInPublicMint: { kycProviderStatus: 'publicMint:pending' }
};

/**
 * Resolve identities filter.
 */

function resolveIdentitiesFilter(state: any) {
  const result = reduce<any, any>(state, (acc, value: any, key: any) => {
    if (!state[key]) {
      return acc;
    }

    const [entry] = Object.entries(availableFilters[key as FiltersKey]);

    if (entry[0] === 'kycProviderStatus') {
      return {
        ...acc,
        kycProviderStatus: acc.kycProviderStatus ? `${acc.kycProviderStatus},${entry[1]}` : entry[1]
      };
    }

    return {
      ...acc,
      ...availableFilters[key as FiltersKey]
    };
  }, {});

  return result;
}

/**
 * `IdentitiesList` component.
 */

function IdentitiesList(): JSX.Element {
  const reduxDispatch = useDispatch();
  const { state: locationState } = useLocation<{ searchWord: string }>();
  const [searchWord, setSearchWord] = useState(locationState?.searchWord || '');
  const identities = useSelector(getAllIdentities);
  const { translate } = useTranslate();
  const [state, dispatch] = useReducer(reducer, {
    acuantApproved: false,
    acuantPending: false,
    approvedInPrimeTrust: false,
    blocked: false,
    pendingInPrimeTrust: false,
    pendingInPublicMint: false
  });

  const handleFetchIdentities = useCallback(({ number }: FetchIdentitiesType = {}) => {
    const filter = resolveIdentitiesFilter(state);

    reduxDispatch(fetchIdentities({
      filter,
      number,
      search: searchWord
    }));
  }, [reduxDispatch, searchWord, state]);

  const handleSearchBoxChange = useCallback((value: string) => {
    reduxDispatch(searchIdentities(value));
    setSearchWord(value);
  }, [reduxDispatch]);

  const filterButtons = useMemo(() => {
    return [{
      dataCy: 'identitiesList-filter-acuantApproved',
      isActive: state.acuantApproved,
      key: actionTypes.SELECT_ACUANT_APPROVED,
      name: translate('identitiesList.filter.options.acuantApproved')
    }, {
      dataCy: 'identitiesList-filter-acuantPending',
      isActive: state.acuantPending,
      key: actionTypes.SELECT_ACUANT_PENDING,
      name: translate('identitiesList.filter.options.acuantPending')
    }, {
      dataCy: 'identitiesList-filter-blocked',
      isActive: state.blocked,
      key: actionTypes.SELECT_BLOCKED,
      name: translate('identitiesList.filter.options.blocked')
    }, {
      dataCy: 'identitiesList-filter-approvedInPrimeTrust',
      isActive: state.approvedInPrimeTrust,
      key: actionTypes.SELECT_APPROVED_IN_PRIME_TRUST,
      name: translate('identitiesList.filter.options.primeTrustApproved')
    }, {
      dataCy: 'identitiesList-filter-pendingInPrimeTrust',
      isActive: state.pendingInPrimeTrust,
      key: actionTypes.SELECT_PENDING_IN_PRIME_TRUST,
      name: translate('identitiesList.filter.options.primeTrustPending')
    }];
  }, [
    state.acuantApproved,
    state.acuantPending,
    state.approvedInPrimeTrust,
    state.blocked,
    state.pendingInPrimeTrust,
    translate
  ]);

  useEffect(() => {
    handleFetchIdentities();

    return () => {
      dispatch(resetFetchIdentities());
    };
  }, [dispatch, handleFetchIdentities]);

  return (
    <Wrapper>
      <DescriptionWrapper data-cy={'identitiesList-description'}>
        <StyledRawHtml>
          {translate('identitiesList.description')}
        </StyledRawHtml>
      </DescriptionWrapper>

      <FilterWrapper>
        <IdentitiesListSearchBox
          onChange={handleSearchBoxChange}
          searchWord={searchWord}
        />

        {filterButtons.map(({
          dataCy,
          isActive,
          key,
          name
        }) => (
          <FilterButton
            data-cy={dataCy}
            isActive={isActive}
            key={key}
            onClick={() => {
              reduxDispatch(searchIdentities());
              dispatch({ type: key });
            }}
          >
            {name}
          </FilterButton>
        ))}

      </FilterWrapper>

      {identities.length ? (
        <>
          <IdentitiesListHeader />

          <IdentitiesListContent
            handleFetchIdentities={handleFetchIdentities}
            identities={identities}
            searchWord={searchWord}
          />
        </>
      ) : (
        <ListEmptyState
          dataCy={'identitiesList-emptyState'}
          description={translate('identitiesList.listEmptyState')}
        />
      )}
    </Wrapper>
  );
}

/**
 * Export `IdentitiesList` component.
 */

export default IdentitiesList;

/**
 * Module dependencies.
 */

import { Button, Fill, Icon, Type, color, units } from 'pmint-design-system';
import { Trans } from 'react-i18next';
import { ifProp, theme } from 'styled-tools';
import { useDropzone } from 'react-dropzone';
import React, { FocusEventHandler } from 'react';
import dragDropIcon from 'client/assets/svg/drag-and-drop.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  disabled: boolean;
  hasError: boolean;
  isSubmitting: boolean;
  name: string;
  onBlur: FocusEventHandler<HTMLElement>;
  onDrop: (files: File[]) => void;
  onFocus: FocusEventHandler<HTMLElement>;
  value: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  disabled: boolean;
  hasError: boolean;
}>`
  background-color: ${color('gray100')};
  border: 1px dashed ${color('gray500')};
  border-radius: ${units(0.5)};
  cursor: pointer;
  outline: none;
  padding: ${units(5)};
  position: relative;
  text-align: center;
  transition: background-color ${theme('transitions.defaultTransition')};

  &:hover {
    background-color: ${color('gray200')};
  }

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    `
  )}

  ${ifProp(
    'hasError',
    css`
      border-color: ${color('errorDark')};
    `
  )}
`;

/**
 * `StyledInput` styled component.
 */

const StyledInput = styled(Fill).attrs({ as: 'input' })`
  cursor: pointer;
  height: 100%;
  opacity: 0;
  width: 100%;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  margin-top: ${units(2)};
  position: relative;
`;

/**
 * `Dropzone` component.
 */

function Dropzone(props: Props): JSX.Element {
  const { disabled, hasError, name, onBlur, onDrop, onFocus, ...rest } = props;

  const { translate } = useTranslate();
  const { getInputProps, getRootProps } = useDropzone({
    accept: ['image/*', 'application/pdf'],
    multiple: false,
    onDrop
  });

  return (
    <Wrapper {...getRootProps({ onBlur, onFocus })}
      disabled={disabled}
      hasError={hasError}
    >
      <StyledInput
        disabled={disabled}
        hasError={hasError}
        id={name}
        name={name}
        {...rest}
        {...getInputProps()}
        style={{ display: 'initial' }}
      />

      <Icon color={color('bender2')}
        icon={dragDropIcon}
        size={units(8)}
      />

      <Type.Paragraph color={color('textColor')}
        fontWeight={400}
        paddingTop={units(2)}
      >
        <Trans
          components={[
            <Type.Small as={'span'}
              color={color('textColorLight')}
              display={'block'}
              key={0}
              marginTop={units(1.5)}
            >
              {' '}
            </Type.Small>
          ]}
          i18nKey={'common.fields.file.description'}
        />

        <StyledButton colorTheme={'gray'}
          size={'medium'}
          tabIndex={-1}
          variant={'fill'}
        >
          {translate('common.fields.file.buttonLabel')}
        </StyledButton>
      </Type.Paragraph>
    </Wrapper>
  );
}

/**
 * Export `Dropzone` component.
 */

export default Dropzone;

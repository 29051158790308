/**
 * Module dependencies.
 */

import {
  Route,
  RouteComponentProps,
  Redirect as RouterRedirect
} from 'react-router-dom';

import React from 'react';

/**
 * `Props` type.
 *
 * These are all props from react-router's Redirect plus `status`.
 */

type Props = {
  exact?: boolean;
  from?: string;
  push?: boolean;
  status: number;
  strict?: boolean;
  to: string | Record<string, any>;
};

/**
 * `Redirect` component.
 */

function Redirect(props: Props): JSX.Element {
  const { status, ...rest } = props;

  return (
    <Route
      render={({ staticContext }: RouteComponentProps<any>): JSX.Element => {
        // The `staticContext` object only exists on the server and in this case
        // is used to store the HTTP status code that should be set in the response.
        if (staticContext) {
          (staticContext as any).status = status;
        }

        return <RouterRedirect {...rest} />;
      }}
    />
  );
}

/**
 * Export `Redirect` component.
 */

export default Redirect;

/**
 * Module dependencies.
 */

import { Component } from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';

/**
 * `Props` type.
 */

type Props = {
  location: Location;
};

/**
 * `ScrollRestoration` component.
 */

class ScrollRestoration extends Component<Props> {

  /**
   * Component did update.
   */

  componentDidUpdate({ location: previousLocation }: Props) {
    const { location } = this.props;

    if (get(location, 'pathname') !== get(previousLocation, 'pathname')) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Render.
   */

  render(): null {
    return null;
  }

}

/**
 * Export `ScrollRestoration` component.
 */

export default withRouter(ScrollRestoration as any);

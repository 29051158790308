
/**
 * Module dependencies.
 */

import type { OperationDocument } from 'client/core/types/earn-operations';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchOperationDocumentsState`.
 */

export const getFetchOperationDocumentsState = createRequestStateSelector('fetchOperationDocuments');

/**
 * Export `getOperationDocuments`.
 */

export function getOperationDocuments(state: unknown): Array<OperationDocument> {
  return getFetchOperationDocumentsState(state)?.data || [];
}


/**
 * Module dependencies.
 */

import { Fill, Loader, Type, color, units } from 'pmint-design-system';
import { getFetchPartnerState } from 'client/core/redux/selectors/fetch-partner';
import { ifProp, theme } from 'styled-tools';
import { useSelector } from 'react-redux';
import FieldValue from 'client/components/core/field-value';
import React from 'react';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isSelected: boolean;
  partnerInformation: Array<{
    label: string;
    value: string;
  }>;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
    isSelected?: boolean;
}>`
  display: none;
  grid-column: 2 / -2;
  margin-bottom: ${units(2)};

  ${ifProp('isSelected', css`
    display: block;
  `)}
`;

/**
 * `LoaderWrapper` styled component.
 */

const LoaderWrapper = styled(Fill)`
  align-items: center;
  animation: ${theme('keyframes.fadeIn')};
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: opacity ${theme('transitions.defaultTransition')};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `FieldsWrapper` styled component.
 */

const FieldsWrapper = styled.div`
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(2, 1fr);
`;

/**
 * `InformationContent` component.
 */

function InformationContent({ isSelected, partnerInformation }: Props): JSX.Element {
  const { translate } = useTranslate();
  const { isLoading } = useSelector(getFetchPartnerState);

  return (
    <Wrapper isSelected={isSelected}>
      {isLoading ? (
        <LoaderWrapper>
          <Loader
            colorTheme={color('bender2')}
            size={units(6)}
            stroke={units(0.5)}
          />
        </LoaderWrapper>
      ) : (
        <>
          <Title data-cy={`partnerDetails-title`}>
            {translate('partnerDetails.title')}
          </Title>

          <FieldsWrapper>
            {partnerInformation.map(({ label, value }) => (
              <FieldValue
                data-cy={`partnerDetails-information-${label.split(' ').join('_')}`}
                key={label}
                label={label}
              >
                {value}
              </FieldValue>
            ))}
          </FieldsWrapper>
        </>
      )}
    </Wrapper>
  );
}

/**
 * Export `InformationContent` component.
 */

export default InformationContent;


/**
 * Module dependencies.
 */

import { Fill, Loader, color, units } from 'pmint-design-system';
import { fetchPartnerOperations, resetFetchPartnerOperations } from 'client/core/redux/actions/fetch-partner-operations';
import { getFetchPartnerOperationsState, getPartnerOperations } from 'client/core/redux/selectors/fetch-partner-operations';
import { ifProp, theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import OperationsList from './operations-list';
import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  isSelected: boolean;
  partner: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
    isSelected?: boolean;
}>`
  display: none;
  grid-column: 2 / -2;
  margin-bottom: ${units(2)};

  ${ifProp('isSelected', css`
    display: block;
  `)}
`;

/**
 * `LoaderWrapper` styled component.
 */

const LoaderWrapper = styled(Fill)`
  align-items: center;
  animation: ${theme('keyframes.fadeIn')};
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: opacity ${theme('transitions.defaultTransition')};
`;

/**
 * `OperationsContent` component.
 */

function OperationsContent({ isSelected, partner }: Props): JSX.Element {
  const dispatch = useDispatch();
  const partnerOperations = useSelector(getPartnerOperations);
  const { isLoading } = useSelector(getFetchPartnerOperationsState);
  const parsedOperations = useMemo(() => {
    return partnerOperations.filter(operation => operation.type);
  }, [partnerOperations]);

  useEffect(() => {
    dispatch(fetchPartnerOperations(partner));
  }, [dispatch, partner]);

  useEffect(() => {
    return () => {
      dispatch(resetFetchPartnerOperations());
    };
  }, [dispatch]);

  return (
    <Wrapper isSelected={isSelected}>
      {!isLoading ? (
        <OperationsList
          partner={partner}
          partnerOperations={parsedOperations}
        />
      ) : (
        <LoaderWrapper>
          <Loader
            colorTheme={color('bender2')}
            size={units(6)}
            stroke={units(0.5)}
          />
        </LoaderWrapper>
      )}
    </Wrapper>
  );
}

/**
 * Export `OperationsContent` component.
 */

export default OperationsContent;

/**
 * Module dependencies.
 */

import { Redirect, useLocation } from 'react-router-dom';
import { Type, color, media, units } from 'pmint-design-system';
import { fetchUacById, resetFetchUacById } from 'client/core/redux/actions/fetch-uac-by-id';
import { get } from 'lodash';
import { getFetchUacByIdState } from 'client/core/redux/selectors/fetch-uac-by-id';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'client/components/core/loading';
import React, { useEffect } from 'react';
import Tabs, { TabItem } from 'client/components/core/tabs';
import UacManagementDetailsIdentityInformation from './identity-information';
import UacManagementDetailsList from './list';
import UacManagementDetailsUacInformation from './uac-information';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-row-gap: ${units(5)};
  margin-bottom: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(7)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H5)`
  color: ${color('bender7')};
  font-weight: 450;
  grid-column: 1 / -1;
`;

/**
 * `StyledTabs` styled component.
 */

const StyledTabs = styled(Tabs)`
  grid-column: 1 / -1;
`;

/**
 * `UacManagementDetailsContent` component.
 */

function UacManagementDetailsContent(): JSX.Element {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { translate } = useTranslate();
  const { uacId } = useQueryString();
  const activeUacId = uacId || get(state, 'uacId');
  const { data: activeUac, errors, isFailed, isLoading } = useSelector(getFetchUacByIdState);
  const tabItems: TabItem[] = [
    {
      id: 'transactions',
      isSelected: true,
      label: translate('uacManagementDetails.tabs.transactions')
    }
  ];

  useEffect(() => {
    if (!activeUacId) {
      return;
    }

    dispatch(fetchUacById(activeUacId));

    return () => dispatch(resetFetchUacById());
  }, [activeUacId, dispatch]);

  useNetworkErrorMessage('uacManagementDetails.errors', errors);

  if (isFailed || !activeUacId) {
    return <Redirect to={routes.dashboard.uacManagement} />;
  }

  if (!activeUac || isLoading) {
    return <Loading isVisible />;
  }

  const { createdAt, id, operations = [], status } = activeUac;

  return (
    <Wrapper>
      <Title data-cy={'uacManagementDetails-title'}>{translate('uacManagementDetails.title')}</Title>

      <UacManagementDetailsUacInformation
        createdAt={createdAt}
        id={id}
        status={translate(`uacManagementDetails.status.${status}`)}
      />

      <UacManagementDetailsIdentityInformation uacId={id} />

      <StyledTabs items={tabItems} />

      <UacManagementDetailsList operations={operations} />
    </Wrapper>
  );
}

/**
 * Export `UacManagementDetailsContent` component.
 */

export default UacManagementDetailsContent;

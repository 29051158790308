
/**
 * Module dependencies.
 */

import type { Document } from 'client/core/types/documents';
import type { Operation as EarnOperation } from 'client/core/types/earn-operations';
import type { Operation } from 'client/core/types/operations';
import type { Uac, UacDetails } from 'client/core/types/uacs';
import { formatDate } from 'client/core/utils/formatter';

/**
 * Export `normalizeAmount`.
 */

export const normalizeAmount = (amount: number): number => {
  return Math.trunc(amount * Math.pow(10, 2)) / Math.pow(10, 2);
};

/**
 * Export `normalizeIdentityDocuments`.
 */

export const normalizeIdentityDocuments = (documents: Array<Document>): Array<Document> => {
  return documents.map(document => {
    const { createdAt, deletedAt, id, label, side, type } = document;

    return {
      createdAt: formatDate(createdAt, { hasTime: true }),
      deletedAt,
      id,
      label,
      side,
      type
    };
  });
};

/**
 * Export `normalizeUacs`.
 */

export const normalizeUacs = (uacs: Array<Uac>): Array<Uac> => {
  return uacs.map(uac => {
    const { amount, createdAt } = uac;
    const parsedAmount = normalizeAmount(Number(amount));

    return {
      ...uac,
      amount: `$ ${parsedAmount} USD`,
      createdAt: formatDate(createdAt, { hasTime: false })
    };
  });
};

/**
 * Export `normalizeUacData`.
 */

export const normalizeUacData = (uac: UacDetails) => {
  const { createdAt, deposits, id, identity, status, withdrawals } = uac;

  const operations = [...deposits ?? [], ...withdrawals ?? []].map<Operation>(operation => {
    const { amount, createdAt } = operation;
    const parsedAmount = normalizeAmount(Number(amount));

    return {
      ...operation,
      amount: `$ ${parsedAmount} USD`,
      createdAt: formatDate(createdAt, { hasTime: true })
    };
  });

  return {
    createdAt: formatDate(createdAt, { hasTime: true }),
    id,
    identity,
    operations,
    status
  };
};

/**
 * Normalize earn operation.
 */

export function normalizeEarnOperation(operation: EarnOperation): EarnOperation {
  return { ...operation, createdAt: formatDate(operation.createdAt, { hasTime: false }) };
}


/**
 * Module dependencies.
 */

import { Fill, Loader, color, media, units } from 'pmint-design-system';
import type { Operation } from 'client/core/types/earn-operations';
import { Trans } from 'react-i18next';
import {
  fetchOperations,
  resetFetchOperations
} from 'client/core/redux/actions/fetch-operations';

import { formatDate } from 'client/core/utils/formatter';
import {
  getFetchOperationsState,
  getOperations
} from 'client/core/redux/selectors/fetch-operations';
import { theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import List from './list';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 2 / -2;
  margin-bottom: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(7)};
  `}
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  margin: ${units(5)} 0 ${units(9)};
  text-align: center;
`;

/**
 * `LoaderWrapper` styled component.
 */

const LoaderWrapper = styled(Fill)`
  align-items: center;
  animation: ${theme('keyframes.fadeIn')};
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: opacity ${theme('transitions.defaultTransition')};
`;

/**
 * `Content` component.
 */

function Content(): JSX.Element {
  const dispatch = useDispatch();
  const operations = useSelector(getOperations);
  const { isLoading } = useSelector(getFetchOperationsState);
  const { translate } = useTranslate();
  const parsedOperations = useMemo(() => {
    return operations.map(operation => {
      return {
        ...operation,
        createdAt: formatDate(operation.createdAt, { hasTime: false }),
        partner: translate(`operations.listValues.${operation.partner}`),
        status: translate(`operations.listValues.${operation.status}`),
        type: translate(`operations.listValues.${operation.type}`)
      } as Operation;
    });
  }, [operations, translate]);

  useEffect(() => {
    dispatch(fetchOperations());

    return () => {
      dispatch(resetFetchOperations());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <DescriptionWrapper data-cy={'operations-description'}>
        <Trans components={{ bold: <b /> }}>
          {translate('operations.description')}
        </Trans>
      </DescriptionWrapper>

      {isLoading ? (
        <LoaderWrapper>
          <Loader
            colorTheme={color('bender2')}
            size={units(6)}
            stroke={units(0.5)}
          />
        </LoaderWrapper>
      ) : <List operations={parsedOperations} />
      }
    </Wrapper>
  );
}

/**
 * Export `Content` component.
 */

export default Content;

/**
 * Module dependencies.
 */

import { color } from 'pmint-design-system';
import React from 'react';
import Tab from './tab';
import styled from 'styled-components';

/**
 * Export `TabItem` type.
 */

export type TabItem = {
  dataCy?: string;
  id: string;
  isSelected: boolean;
  label: string;
  onSelectTab?: () => void;
};

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  dataCy?: string;
  items: Array<TabItem>;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-bottom: 1px solid ${color('gray500')};
`;

/**
 * `Tabs` component.
 */

function Tabs(props: Props): JSX.Element {
  const { items, ...rest } = props;

  return (
    <Wrapper {...rest}
      role={'tab-list'}
    >
      {items.map(({ dataCy, id, isSelected, label, onSelectTab }) => (
        <Tab
          dataCy={dataCy}
          isSelected={isSelected}
          key={id}
          label={label}
          onSelectTab={onSelectTab}
        />
      ))}
    </Wrapper>
  );
}

/**
 * Export `Tabs` component.
 */

export default Tabs;

/**
 * Module dependencies.
 */

import { applyMiddleware, compose, createStore } from 'redux';

import { canUseDOM } from 'client/core/utils/dom';
import { isProduction } from 'core/utils/environment';
import { promiseMiddleware } from 'core-redux';
import authMiddleware from 'client/core/redux/middleware/auth-middleware';
import reducer from 'client/core/redux/reducer';
import requestMiddleware from 'client/core/redux/middleware/request-middleware';
import thunkMiddleware from 'redux-thunk';

/**
 * Compose enhancer.
 */

// eslint-disable-next-line no-underscore-dangle
const composeEnhancer = canUseDOM() && !isProduction() && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Export `configureStore`.
 */

export default function configureStore(initialState = {}) {
  return createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(authMiddleware, requestMiddleware, thunkMiddleware, promiseMiddleware))
  );
}

/**
 * Module dependencies.
 */

import { GlobalStyle } from 'pmint-design-system';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from 'client/styles/theme';
import AnalyticsTracker from 'client/containers/core/analytics-tracker';
import ConditionalRoute from 'client/components/routing/conditional-route';
import Dashboard from 'client/containers/dashboard';
import DefaultErrorMessage from 'client/components/core/error-boundaries/default-error-message';
import ErrorBoundary from 'client/components/core/error-boundaries/error-boundary';
import FetchUser from 'client/containers/core/fetch-user';
import Home from 'client/containers/home';
import LocaleCookieUrlSyncer from 'client/containers/core/locale-cookie-url-syncer';
import LogoutListener from 'client/containers/core/logout-listener';
import React from 'react';
import Redirect from 'client/components/routing/redirect';
import ScrollRestoration from 'client/components/routing/scroll-restoration';
import SignIn from 'client/containers/sign-in';
import SnackbarProvider from 'client/containers/core/snackbar/snackbar-provider';
import config from 'config';
import routes from 'core/routes';

/**
 * `Root` container.
 */

function Root(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary errorComponent={DefaultErrorMessage}>
        <SnackbarProvider>
          <Helmet defaultTitle={'PMint'}
            titleTemplate={'%s - PMint'}
          >
            <meta content={'The boilerplate for creating amazing react web apps.'}
              name={'description'}
            />

            <meta content={'Seegno'}
              name={'author'}
            />
          </Helmet>

          <GlobalStyle />

          <AnalyticsTracker />

          <LocaleCookieUrlSyncer languages={config.get('localization.whitelist')} />

          <LogoutListener />

          <ScrollRestoration />

          <ErrorBoundary errorComponent={DefaultErrorMessage}>
            <Switch>
              <Route component={Home}
                exact
                path={routes.home}
              />

              <Route component={SignIn}
                exact
                path={routes.signIn}
              />

              <Route path={'*'}>
                <FetchUser>
                  <Switch>
                    <ConditionalRoute component={Dashboard}
                      path={routes.dashboard.base}
                    />

                    <Redirect from={'*'}
                      status={302}
                      to={routes.home}
                    />
                  </Switch>
                </FetchUser>
              </Route>
            </Switch>
          </ErrorBoundary>
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

/**
 * Export `Root` container.
 */

export default Root;

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `patchIdentityType`.
 */

export const patchIdentityType = 'PATCH_IDENTITY';

/**
 * Export `patchIdentityTypes`.
 */

export const patchIdentityTypes = createRequestActionTypes({
  method: 'PATCH',
  type: patchIdentityType
});


/**
 * Module dependencies.
 */

import type { Partner } from 'client/core/types/partners';
import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchPartnersState`.
 */

export const getFetchPartnersState: RequestStateSelector = createRequestStateSelector('fetchPartners');

/**
 * Export `getPartnersInformation`.
 */

export function getPartnersInformation(state: unknown): Array<Partner> {
  return getFetchPartnersState(state)?.data ?? [];
}

/**
 * Module dependencies.
 */

import { Button, units } from 'pmint-design-system';
import type { Identity } from 'client/core/types/identities';

import {
  fetchDocumentPresignUrl,
  resetFetchDocumentPresignUrl
} from 'client/core/redux/actions/fetch-document-presign-url';

import {
  fetchIdentityDocuments,
  resetFetchIdentityDocuments
} from 'client/core/redux/actions/fetch-identity-documents';

import { getActiveDocument } from 'client/core/redux/selectors/documents';
import { getDocumentPresignUrl } from 'client/core/redux/selectors/fetch-document-presign-url';
import { getIdentityDocuments } from 'client/core/redux/selectors/fetch-identity-documents';
import { ifProp } from 'styled-tools';
import { setActiveDocument } from 'client/core/redux/actions/documents';
import { useDispatch, useSelector } from 'react-redux';
import AddDocumentModal from 'client/components/dashboard/modals/add-document-modal';
import DocumentsListContent from 'client/components/core/documents/documents-list-content';
import DocumentsListHeader from 'client/components/core/documents/documents-list-header';
import ListEmptyState from 'client/components/core/lists/empty-state';
import ModalPortal from 'client/components/core/modal/modal-portal';
import React, { useCallback, useEffect, useState } from 'react';
import RequestViewDocumentModal from 'client/components/core/documents/request-view-document-modal';
import ViewDocumentModal from 'client/components/core/documents/view-document-modal';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  activeIdentity: Identity;
  isSelected: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isSelected: boolean | undefined;
}>`
  display: ${ifProp('isSelected', 'block', 'none')};
`;

/**
 * `UploadDocumentButton` styled component.
 */

const UploadDocumentButton = styled(Button)`
  margin-bottom: ${units(4)};
`;

/**
 * `DocumentsDetails` component.
 */

function DocumentsDetails({ activeIdentity, isSelected }: Props): JSX.Element {
  const dispatch = useDispatch();
  const documents = useSelector(getIdentityDocuments);
  const { id, name } = activeIdentity;
  const { translate } = useTranslate();
  const [isAddDocumentModalVisible, setIsAddDocumentModalVisible] = useState(false);
  const [isRequestViewModalVisible, setIsRequestViewModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const activeDocument = useSelector(getActiveDocument);
  const { documentPresignUrl, extension } = useSelector(getDocumentPresignUrl);
  const { side, type } = activeDocument;

  useEffect(() => {
    if (isViewModalVisible) {
      dispatch(fetchDocumentPresignUrl({ documentId: activeDocument.id }));
    }

    return () => dispatch(resetFetchDocumentPresignUrl());
  }, [dispatch, activeDocument.id, isViewModalVisible]);

  const handleShowRequestViewModal = useCallback(
    (documentId: string) => {
      dispatch(setActiveDocument(documentId));

      setIsRequestViewModalVisible(true);
    },
    [dispatch]
  );

  const handleViewDocument = useCallback(() => {
    setIsRequestViewModalVisible(false);
    setIsViewModalVisible(true);
  }, []);

  const handleRequestClose = useCallback(() => {
    setIsRequestViewModalVisible(false);
    setIsViewModalVisible(false);
    setIsAddDocumentModalVisible(false);
  }, []);

  const handleUploadDocumentClick = useCallback(() => {
    setIsAddDocumentModalVisible(true);
  }, []);

  useEffect(() => {
    dispatch(fetchIdentityDocuments(id));

    return () => dispatch(resetFetchIdentityDocuments());
  }, [dispatch, id]);

  return (
    <Wrapper isSelected={isSelected}>
      <UploadDocumentButton onClick={handleUploadDocumentClick}>
        {translate('documents.uploadDocumentButton')}
      </UploadDocumentButton>

      {documents.length ? (
        <>
          <DocumentsListHeader />

          <DocumentsListContent
            documents={documents}
            onRequestViewDocument={handleShowRequestViewModal}
          />
        </>
      ) : (
        <ListEmptyState
          dataCy={'documents-emptyState'}
          description={translate('documents.listEmptyState')}
        />
      )}

      <ModalPortal isOpen={isRequestViewModalVisible}>
        <RequestViewDocumentModal
          isVisible={isRequestViewModalVisible}
          name={name}
          onContinue={handleViewDocument}
          onRequestClose={handleRequestClose}
        />
      </ModalPortal>

      <ModalPortal isOpen={isViewModalVisible}>
        <ViewDocumentModal
          documentPresignUrl={documentPresignUrl}
          extension={extension}
          isVisible={isViewModalVisible}
          onRequestClose={handleRequestClose}
          side={side}
          type={type}
        />
      </ModalPortal>

      <ModalPortal isOpen={isAddDocumentModalVisible}>
        <AddDocumentModal
          identityId={id}
          isVisible={isAddDocumentModalVisible}
          onRequestClose={handleRequestClose}
        />
      </ModalPortal>
    </Wrapper>
  );
}

/**
 * Export `DocumentsDetails` component.
 */

export default DocumentsDetails;

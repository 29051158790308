/**
 * Module dependencies.
 */

import { fetchIdentitiesCount, resetFetchIdentitiesCount } from 'client/core/redux/actions/fetch-identities-count';

import { fetchTotalBalance } from 'client/core/redux/actions/fetch-total-balance';
import { getFetchTotalBalanceState } from 'client/core/redux/selectors/fetch-total-balance';
import { getIdentitiesCount } from 'client/core/redux/selectors/fetch-identities-count';
import { media, units } from 'pmint-design-system';
import { useDispatch, useSelector } from 'react-redux';
import DashboardInfoCard from './dashboard-info-card';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import useFormatter from 'client/hooks/use-formatter';
import useTranslate from 'client/hooks/use-translate';

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-gap: ${units(2)} ${units(4)};
  padding-bottom: ${units(2)};

  ${media.min('xs')`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `DashboardContent` component.
 */

function DashboardContent(): JSX.Element {
  const { translate } = useTranslate();
  const { data: totalBalance } = useSelector(getFetchTotalBalanceState);
  const dispatch = useDispatch();
  const total = useSelector(getIdentitiesCount);
  const formattedBalance = useFormatter({ currency: 'usd', value: totalBalance });
  const handleFetchIdentitiesCount = useCallback(() => {
    return dispatch(fetchIdentitiesCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTotalBalance());
  }, [dispatch]);

  useEffect(() => {
    handleFetchIdentitiesCount();
  }, [handleFetchIdentitiesCount]);

  useEffect(() => {
    return () => dispatch(resetFetchIdentitiesCount());
  }, [dispatch]);

  return (
    <>
      <ContentWrapper data-cy={'dashboardInfoCards'}>
        <DashboardInfoCard
          dataCy={'totalAmount'}
          description={translate('dashboard.content.infoCards.totalAmountDescription')}
          value={formattedBalance}
        />

        <DashboardInfoCard
          dataCy={'totalIdentities'}
          description={translate('dashboard.content.infoCards.totalIdentitiesDescription')}
          value={String(total)}
        />
      </ContentWrapper>
    </>
  );
}

/**
 * Export `DashboardContent` component.
 */

export default DashboardContent;


/**
 * Module dependencies.
 */

import { Modal } from 'pmint-design-system';
import { getPostUserPasswordState } from 'client/core/redux/selectors/post-user-password';
import { performLogin } from 'client/core/redux/actions/perform-login';
import { postUserPassword, resetPostUserPassword } from 'client/core/redux/actions/post-user-password';
import { useDispatch, useSelector } from 'react-redux';
import { useFormActions } from '@seegno/react-forms';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import ChangePasswordForm from './change-password-form';
import Form from 'client/components/core/forms/form';
import React, { useCallback, useEffect } from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  email: string;
  isVisible: boolean;
  onRequestClose: () => void;
};

/**
 * Initial values.
 */

const initialValues = {
  currentPassword: undefined,
  password: undefined,
  repeatPassword: undefined
};

/**
 * Change Password schema.
 */

const changePasswordSchema = {
  additionalProperties: false,
  properties: {
    currentPassword: {
      type: 'string'
    },
    password: {
      maxLength: 256,
      minLength: 8,
      not: {
        const: {
          $data: '1/currentPassword'
        }
      },
      type: 'string'
    },
    repeatPassword: {
      const: {
        $data: '1/password'
      }
    }
  },
  required: ['currentPassword', 'password', 'repeatPassword'],
  type: 'object'
};

/**
 * `ChangePasswordModal` component.
 */

function ChangePasswordModal({ email, isVisible, onRequestClose }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { isSuccessful } = useSelector(getPostUserPasswordState);
  const { translate } = useTranslate();
  const { reset } = useFormActions();

  useEffect(() => {
    if (isSuccessful) {
      showSuccessMessage(translate('myAccount.changePasswordModal.successMessage'));
    }
  }, [dispatch, isSuccessful, showSuccessMessage, translate]);

  useEffect(() => {
    return () => dispatch(resetPostUserPassword());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const { currentPassword, password } = values;

        await dispatch(postUserPassword({ currentPassword, password }));

        dispatch(performLogin({ email, password })) as any;

        onRequestClose();
      } catch (error: any) {
        if (error.status === 403) {
          showErrorMessage(translate('myAccount.changePasswordModal.errors.errorMessageCurrentPassword'));
        } else if (error.status === 400) {
          showErrorMessage(translate('myAccount.changePasswordModal.errors.errorMessageNewPassword'));
        } else {
          showErrorMessage(translate('myAccount.changePasswordModal.errors.errorMessage'));
        }
      }
    },
    [dispatch, email, onRequestClose, showErrorMessage, translate]
  );

  const handleCancel = useCallback(() => {
    onRequestClose();
    reset();
  }, [onRequestClose, reset]);

  return (
    <Form
      errorsBasePath={'myAccount.changePasswordModal.form.errors'}
      initialValues={initialValues}
      jsonSchema={changePasswordSchema}
      onSubmit={handleSubmit}
    >
      <Modal
        closeButtonAriaLabel={translate('common.actions.close')}
        isVisible={isVisible}
        onRequestClose={handleCancel}
      >
        <ChangePasswordForm onRequestClose={onRequestClose} />
      </Modal>
    </Form>
  );
}

/**
 * Export `ChangePasswordModal` component.
 */

export default ChangePasswordModal;

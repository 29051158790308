
/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getPatchUserState`.
 */

export const getPatchUserState: RequestStateSelector = createRequestStateSelector('patchUser');


/**
 * Module dependencies.
 */

import { Box, media, units } from 'pmint-design-system';
import { Redirect, useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { fetchPartner, resetFetchPartner } from 'client/core/redux/actions/fetch-partner';
import { get, merge, omit } from 'lodash';
import { getFetchPartnerState, getPartnerInformation } from 'client/core/redux/selectors/fetch-partner';
import { useDispatch, useSelector } from 'react-redux';
import BackLink from 'client/components/core/back-link';
import InformationContent from 'client/components/dashboard/partner-details/information-content';
import OperationsContent from 'client/components/dashboard/partner-details/operations-content';
import React, { useEffect, useMemo, useState } from 'react';
import Tabs from 'client/components/core/tabs';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `InformationType` type.
 */

type InformationType = 'information' | 'operations' ;

/**
 * Information types.
 */

const informationTypes: { [key: string]: InformationType } = {
  information: 'information',
  operations: 'operations'
};

/**
 * `BackWrapper` styled component.
 */

const BackWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-bottom: ${units(3)};
  margin-left: ${units(3)};
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  grid-column: 2 / -2;
  margin-bottom: ${units(3)};
  text-align: center;
`;

/**
 * `StyledTabs` styled component.
 */

const StyledTabs = styled(Tabs)`
  display: flex;
  grid-column: 2 / -2;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin: 0 ${units(3)};
  `}
`;

/**
 * `PartnerDetails` container.
 */

function PartnerDetails(): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const { translate } = useTranslate();
  const [informationType, setInformationType] = useState<InformationType>(informationTypes.information);
  const tabItems = useMemo(() => [{
    dataCy: 'partnerDetails-tab-information',
    id: 'information',
    isSelected: informationType === informationTypes.information,
    label: translate('partnerDetails.tabs.information'),
    onSelectTab: () => {
      setInformationType(informationTypes.information);
    }
  }, {
    dataCy: 'partnerDetails-tab-operations',
    id: 'operations',
    isSelected: informationType === informationTypes.operations,
    label: translate('partnerDetails.tabs.operations'),
    onSelectTab: () => {
      setInformationType(informationTypes.operations);
    }
  }], [informationType, translate]);

  const statePartner = get(location?.state, 'partner');
  const { partner: queryPartner } = useQueryString();
  const partner = statePartner ?? queryPartner;
  const partnerInformation = useSelector(getPartnerInformation);
  const { errors } = useSelector(getFetchPartnerState);
  const partnerName = translate(`partners.names.${partnerInformation.name}`);
  const parsedPartnerInformation = useMemo(() => {
    const partners = merge(partnerInformation, partnerInformation.limits);
    const partnersDetails = omit(partners, ['limits', 'name']);

    return Object.keys(partnersDetails).map(key => ({
      label: translate(`partnerDetails.labels.${key}`),
      value: get(partnerInformation, key)
    }));
  }, [partnerInformation, translate]);

  useEffect(() => {
    if (partner) {
      dispatch(fetchPartner(partner));
    }
  }, [dispatch, partner]);

  useEffect(() => {
    return () => dispatch(resetFetchPartner());
  }, [dispatch]);

  useNetworkErrorMessage('partnerDetails.errors', errors);

  if (!partner || errors) {
    return <Redirect to={routes.dashboard.partners} />;
  }

  return (
    <>
      <BackWrapper>
        <BackLink to={routes.dashboard.partners} />
      </BackWrapper>

      <DescriptionWrapper>
        <Trans components={{ bold: <b /> }}>
          {translate('partnerDetails.description', { partner: partnerName })}
        </Trans>
      </DescriptionWrapper>

      <StyledTabs items={tabItems} />

      <Box gridColumn={'2 / -2'}>
        <InformationContent
          isSelected={informationType === informationTypes.information}
          partnerInformation={parsedPartnerInformation}
        />

        <OperationsContent
          isSelected={informationType === informationTypes.operations}
          partner={partnerInformation.name as string}
        />
      </Box>
    </>
  );
}

/**
 * Export `PartnerDetails` container.
 */

export default PartnerDetails;


/**
 * Module dependencies.
 */

import { Button, Icon, Loader, color, units } from 'pmint-design-system';
import { isNil } from 'lodash';
import { useFormActions, useFormState } from '@seegno/react-forms';
import React from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element | string;
  dataCy?: string;
  fullWidth?: boolean;
  icon?: string;
  isDirty?: boolean;
};

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  min-height: ${units(8)};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Label` styled component.
 */

const Label = styled.div`
  flex: 1;
  text-align: left;
`;

/**
 * `SubmitButton` component.
 */

function SubmitButton(props: Props): JSX.Element {
  const { children, dataCy, icon = arrowIcon, isDirty, ...rest } = props;
  const { isSubmitting, meta } = useFormState();
  const { submit } = useFormActions();
  const { hasErrors, touched } = meta;
  const isDisabled = hasErrors || (isNil(isDirty) ? !touched : !isDirty);

  return (
    <StyledButton {...rest}
      data-cy={dataCy}
      disabled={isDisabled}
      onClick={submit}
      type={'submit'}
    >
      <ContentWrapper>
        <Label>{children}</Label>

        {isSubmitting ? <Loader /> : (
          <Icon
            color={color('white')}
            icon={icon}
            size={units(3)}
          />
        )}
      </ContentWrapper>
    </StyledButton>
  );
}

/**
 * Export `SubmitButton` component.
 */

export default SubmitButton;

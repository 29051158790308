
/**
 * Module dependencies.
 */

import { Type, media, units } from 'pmint-design-system';
import { User } from 'client/core/types/user';
import { getPatchUserState } from 'client/core/redux/selectors/patch-user';
import { patchUser, resetPatchUser } from 'client/core/redux/actions/patch-user';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import Button from 'client/components/core/button';
import ChangePasswordModal from 'client/components/dashboard/modals/change-password-modal';
import EditButtons from 'client/components/core/forms/edit-buttons';
import FieldValue from 'client/components/core/field-value';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import ModalPortal from 'client/components/core/modal/modal-portal';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  role: string;
  user: User;
};

/**
 * Edit personal information schema.
 */

const editPersonalInformationSchema = {
  properties: {
    fullName: { type: 'string' }
  },
  required: []
};

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `TitleWrapper` styled component.
 */

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(1)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2 ,1fr);
  `}
`;

/**
 * `AccountInfo` component.
 */

function AccountInfo({ role, user }: Props): JSX.Element {
  const { email, fullName } = user;
  const initialFormValues = { fullName };
  const dispatch = useDispatch();
  const { isSuccessful } = useSelector(getPatchUserState);
  const [isEditingFields, setIsEditingFields] = useState(false);
  const [userName, setUserName] = useState(fullName);
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
  const { translate } = useTranslate();

  useEffect(() => {
    if (isSuccessful) {
      showSuccessMessage(translate('myAccount.editUser.successMessage'));
    }
  }, [dispatch, isSuccessful, showSuccessMessage, translate]);

  useEffect(() => {
    return () => dispatch(resetPatchUser());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await dispatch(patchUser(values));

        setIsEditingFields(false);
      } catch (error) {
        showErrorMessage(translate('myAccount.editUser.errorMessage'));
      } finally {
        setUserName(values.fullName);
      }
    },
    [dispatch, showErrorMessage, translate]
  );

  return (
    <Wrapper>
      <Form
        initialValues={initialFormValues}
        jsonSchema={editPersonalInformationSchema}
        onSubmit={handleSubmit}
      >
        <TitleWrapper>
          <Title>
            {translate('myAccount.accountInformation.title')}
          </Title>

          <EditButtons
            formValues={initialFormValues}
            handleCloseEditMode={() => setIsEditingFields(false)}
            handleEditFields={() => setIsEditingFields(true)}
            isEditingFields={isEditingFields}
          />
        </TitleWrapper>

        {isEditingFields ? (
          <InputField
            data-cy={'myAccount-accountInformation-fullName-edit'}
            label={translate('myAccount.accountInformation.labels.fullName')}
            name={'fullName'}
          />
        ) : (
          <FieldValue
            data-cy={'myAccount-accountInformation-fullName'}
            label={translate('myAccount.accountInformation.labels.fullName')}
          >
            {userName}
          </FieldValue>
        )}

        <SideBySide>
          <FieldValue
            data-cy={'myAccount-accountInformation-email'}
            label={translate('myAccount.accountInformation.labels.email')}
          >
            {email}
          </FieldValue>

          <FieldValue
            data-cy={'myAccount-accountInformation-role'}
            label={translate('myAccount.accountInformation.labels.role')}
          >
            {role}
          </FieldValue>
        </SideBySide>
      </Form>

      <SubmitButtonWrapper>
        <Button
          data-cy={'myAccount-changePassword'}
          fullWidth
          onClick={() => { setIsChangePasswordModalVisible(true); }}
        >
          {translate('common.actions.changePassword')}
        </Button>
      </SubmitButtonWrapper>

      <ModalPortal isOpen={isChangePasswordModalVisible}>
        <ChangePasswordModal
          email={email}
          isVisible={isChangePasswordModalVisible}
          onRequestClose={() => { setIsChangePasswordModalVisible(false); }}
        />
      </ModalPortal>
    </Wrapper>
  );
}

/**
 * Export `AccountInfo` component.
 */

export default AccountInfo;

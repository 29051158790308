
/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/earn-operations';
import ListEmptyState from 'client/components/core/lists/empty-state';
import OperationsListContent from './operations-list-content';
import OperationsListHeader from './operations-list-header';
import React from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  partner: string;
  partnerOperations: Array<Operation>;
};

/**
 * `OperationsList` component.
 */

function OperationsList({ partner, partnerOperations }: Props): JSX.Element {
  const { translate } = useTranslate();

  return !partnerOperations.length ? (
    <ListEmptyState
      dataCy={'partnerDetails-operations-emptyState'}
      description={translate('partnerDetails.operationsList.emptyState')}
    />
  ) : (
    <>
      <OperationsListHeader />

      <OperationsListContent
        operations={partnerOperations}
        partner={partner}
      />
    </>
  );
}

/**
 * Export `OperationsList` component.
 */

export default OperationsList;

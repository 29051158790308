/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { reopenIdentityReviewType } from 'client/core/redux/action-types/reopen-identity-review';

/**
 * Export `reopenIdentityReview`.
 */

export const reopenIdentityReview = (id: string) => {
  return createRequestAction({
    endpoint: {
      name: 'reopenIdentityReview',
      params: { id }
    },
    handleResponse: ({ data }: any) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: reopenIdentityReviewType
  });
};

/**
 * Export `resetReopenIdentityReview`.
 */

export const resetReopenIdentityReview = createResetRequestAction({
  method: 'POST',
  type: reopenIdentityReviewType
});


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchPartnerType } from 'client/core/redux/action-types/fetch-partner';
import { get as getFp } from 'lodash/fp';

/**
 * Export `fetchPartner`.
 */

export function fetchPartner(partner: string) {
  return createRequestAction({
    endpoint: {
      name: 'fetchPartner',
      params: { partner }
    },
    handleResponse: getFp('data.data'),
    type: fetchPartnerType
  });
}

/**
 * Export `resetFetchPartner`.
 */

export const resetFetchPartner = createResetRequestAction({
  type: fetchPartnerType
});

/**
 * Module dependencies.
 */

import { fetchUser } from 'client/core/redux/actions/fetch-user';
import { getAuthenticationStatus } from 'client/core/redux/selectors/authentication';
import { getUser } from 'client/core/redux/selectors/fetch-user';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'client/components/core/loading';
import React, { useEffect } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
};

/**
 * `FetchUser` container.
 */

function FetchUser({ children }: Props): JSX.Element {
  const authenticationStatus = useSelector(getAuthenticationStatus);
  const dispatch = useDispatch();
  const isAuthenticated = authenticationStatus === 'authenticated';
  const user = useSelector(getUser);

  useEffect(() => {
    if (!isAuthenticated || isNil(user)) {
      dispatch(fetchUser());
    }
  }, [dispatch, isAuthenticated, user]);

  if (!isAuthenticated && isNil(user)) {
    return <Loading isVisible />;
  }

  return children;
}

/**
 * Export `FetchUser` container.
 */

export default FetchUser;

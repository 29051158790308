/**
 * Module dependencies.
 */

import type { Document } from 'client/core/types/documents';
import { Link, Type, color, media, units } from 'pmint-design-system';
import type { OperationDocument } from 'client/core/types/earn-operations';
import { theme } from 'styled-tools';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  documents: Array<Document> | Array<OperationDocument>;
  onRequestViewDocument: (documentId: string) => void;
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;
  transition: ${theme('transitions.defaultTransition')} background-color;

  ${media.min('xs')`
    grid-template-columns: 4fr 4fr 2fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover,
  &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  align-items: center;
  color: ${color('textColor')};
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: start;
`;

/**
 * `DocumentsListContent` component.
 */

function DocumentsListContent({ documents, onRequestViewDocument }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <>
      {documents.map(({ createdAt, id, label }) => (
        <RowWrapper
          data-cy={`documentsList-${id}`}
          key={id}
        >
          <Value>{label}</Value>

          <Value>{createdAt}</Value>

          <StyledLink
            data-cy={`documentsList-view-${id}`}
            onClick={() => onRequestViewDocument(id)}
            size={'small'}
          >
            {translate('documentsList.view')}
          </StyledLink>
        </RowWrapper>
      ))}
    </>
  );
}

/**
 * Export `DocumentsListContent` component.
 */

export default DocumentsListContent;

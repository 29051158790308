/**
 * Module dependencies.
 */

import { getErrorPaths } from 'client/core/utils/errors';
import { useFieldState } from '@seegno/react-forms';
import { useFormErrorsBasePath } from 'client/components/core/forms/form-errors-base-path-context';

import useTranslate from './use-translate';

/**
 * `useLabel` hook.
 */

const useLabel = (label: string, name: string): string => {
  const { translate } = useTranslate();
  const { error, meta } = useFieldState(name);
  const basePath = useFormErrorsBasePath();

  if (!!error && meta.touched) {
    return translate(getErrorPaths(basePath, error.rule, name), error.args);
  }

  return label;
};

/**
 * Export `useLabel` hook.
 */

export default useLabel;


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchTransactionByIdType } from 'client/core/redux/action-types/fetch-transaction-by-id';

/**
 * Export `fetchTransactionById`.
 */

export const fetchTransactionById = (transactionId: string) => {
  return createRequestAction({
    endpoint: {
      name: 'transactionById',
      params: { id: transactionId }
    },
    handleResponse: (data: any) => data,
    type: fetchTransactionByIdType
  });
};

/**
 * Export `resetFetchTransactionById`.
 */

export const resetFetchTransactionById = createResetRequestAction({
  type: fetchTransactionByIdType
});

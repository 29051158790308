/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  dataCy: string;
  description: string;
};

/**
 * `EmptyState` styled component.
 */

const EmptyState = styled(Type.Label).attrs({ as: 'p' })`
  border: 1px solid black;
  border-radius: ${units(1)};
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  padding: ${units(2)};
  text-align: center;
`;

/**
 * `ListEmptyState` component.
 */

function ListEmptyState({ dataCy, description }: Props): JSX.Element {
  return <EmptyState data-cy={dataCy}>{description}</EmptyState>;
}

/**
 * Export `ListEmptyState` component.
 */

export default ListEmptyState;

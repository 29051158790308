/**
 * Module dependencies.
 */

import { units } from 'pmint-design-system';
import BackLink from 'client/components/core/back-link';
import React from 'react';
import UACManagementList from 'client/components/dashboard/uac-management/list';
import routes from 'core/routes';
import styled from 'styled-components';

/**
 * `BackWrapper` styled component.
 */

const BackWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-left: ${units(3)};
`;

/**
 * `UacManagement` container.
 */

function UacManagement(): JSX.Element {
  return (
    <>
      <BackWrapper>
        <BackLink to={routes.dashboard.base} />
      </BackWrapper>

      <UACManagementList />
    </>
  );
}

/**
 * Export `UacManagement` container.
 */

export default UacManagement;

/**
 * Module dependencies.
 */

import { Input } from 'pmint-design-system';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  onChange: (value: string) => void;
  searchWord?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 1 / 8;
`;

/**
 * `IdentitiesListSearchBox` component.
 */

function IdentitiesListSearchBox(props: Props): JSX.Element {
  const { onChange, searchWord } = props;
  const { translate } = useTranslate();
  const [value, setValue] = useState(searchWord || '');
  const handleOnChange = useCallback(
    ({ target: { value } }: any) => {
      onChange(value);
      setValue(value);
    },
    [onChange]
  );

  return (
    <Wrapper>
      <Input
        data-cy={'identitiesList-searchBox'}
        label={translate('identitiesList.search.label')}
        onChange={handleOnChange}
        value={value}
      />
    </Wrapper>
  );
}

/**
 * Export `IdentitiesListSearchBox` component.
 */

export default IdentitiesListSearchBox;

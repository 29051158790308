
/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { insertOperationDocumentType } from 'client/core/redux/action-types/insert-operation-document';

/**
 * Export `insertOperationDocument`.
 */

export default combineReducers(createRequestReducers({
  method: 'POST',
  type: insertOperationDocumentType
}));


/**
 * Module dependencies.
 */

import { Button, units } from 'pmint-design-system';
import type { OperationDocument } from 'client/core/types/earn-operations';

import {
  fetchEarnDocumentPresignUrl,
  resetFetchEarnDocumentPresignUrl
} from 'client/core/redux/actions/fetch-earn-document-presign-url';

import { getEarnDocumentPresignUrl } from 'client/core/redux/selectors/fetch-earn-document-presign-url';
import { ifProp } from 'styled-tools';
import { performOperationDocumentUpload } from 'client/core/redux/actions/perform-operation-document-upload';
import { resetFetchEarnPresignUrl } from 'client/core/redux/actions/fetch-earn-presign-url';
import { resetFetchOperationDocuments } from 'client/core/redux/actions/fetch-operation-documents';
import { resetInsertOperationDocument } from 'client/core/redux/actions/insert-operation-document';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import DocumentsListContent from 'client/components/core/documents/documents-list-content';
import DocumentsListHeader from 'client/components/core/documents/documents-list-header';
import ListEmptyState from 'client/components/core/lists/empty-state';
import ModalPortal from 'client/components/core/modal/modal-portal';
import React, { useCallback, useEffect, useReducer } from 'react';
import RequestViewDocumentModal from 'client/components/core/documents/request-view-document-modal';
import UploadDocumentModal from './upload-document-modal';
import ViewDocumentModal from 'client/components/core/documents/view-document-modal';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isSelected: boolean;
  operationDocuments: Array<OperationDocument>;
  operationId: string;
};

/**
 * State type.
 */

type State = {
  activeDocumentId?: string;
  isUploadModalVisible: boolean;
  isViewDocumentModalVisible: boolean;
};

/**
 * Action types options type.
 */

type actionTypesOptions = 'CLOSE_MODALS'
  | 'OPEN_REQUEST_VIEW_DOCUMENT_MODAL'
  | 'OPEN_UPLOAD_DOCUMENT_MODAL'
  | 'OPEN_VIEW_DOCUMENT_MODAL';

/**
 * Action type.
 */

type Action = {
  payload?: string;
  type: actionTypesOptions;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
    isSelected?: boolean;
  }>`
  display: ${ifProp('isSelected', 'block', 'none')};
`;

/**
 * `UploadDocumentButton` styled component.
 */

const UploadDocumentButton = styled(Button)`
  margin-bottom: ${units(4)};
`;

/**
 * Action types.
 */

const actionTypes: {[key: string]: actionTypesOptions} = {
  CLOSE_MODALS: 'CLOSE_MODALS',
  OPEN_REQUEST_VIEW_DOCUMENT_MODAL: 'OPEN_REQUEST_VIEW_DOCUMENT_MODAL',
  OPEN_UPLOAD_DOCUMENT_MODAL: 'OPEN_UPLOAD_DOCUMENT_MODAL',
  OPEN_VIEW_DOCUMENT_MODAL: 'OPEN_VIEW_DOCUMENT_MODAL'
};

/**
 * Reducer function.
 */

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case actionTypes.OPEN_REQUEST_VIEW_DOCUMENT_MODAL:
      return {
        ...state,
        activeDocumentId: action.payload
      };

    case actionTypes.OPEN_UPLOAD_DOCUMENT_MODAL:
      return {
        ...state,
        isUploadModalVisible: true,
        isViewDocumentModalVisible: false
      };

    case actionTypes.OPEN_VIEW_DOCUMENT_MODAL:
      return {
        ...state,
        isUploadModalVisible: false,
        isViewDocumentModalVisible: true
      };

    case actionTypes.CLOSE_MODALS:
      return {
        activeDocumentId: '',
        isUploadModalVisible: false,
        isViewDocumentModalVisible: false
      };

    default:
      return state;
  }
}

/**
 * Use document content.
 */

function useDocumentContent(operationId: string) {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, {
    activeDocumentId: '',
    isUploadModalVisible: false,
    isViewDocumentModalVisible: false
  });

  const handleShowApproveModal = useCallback(() => {
    dispatch({ type: actionTypes.OPEN_UPLOAD_DOCUMENT_MODAL });
  }, []);

  const handleViewDocument = useCallback(() => {
    dispatch({ type: actionTypes.OPEN_VIEW_DOCUMENT_MODAL });
  }, []);

  const handleRequestClose = useCallback(() => {
    dispatch({ type: actionTypes.CLOSE_MODALS });
  }, []);

  const handleRequestViewDocument = useCallback((id: string) => {
    dispatch({ payload: id, type: actionTypes.OPEN_REQUEST_VIEW_DOCUMENT_MODAL });
  }, []);

  const handleDocumentUpload = useCallback(async (operationDocument: any) => {
    await reduxDispatch(performOperationDocumentUpload({ file: operationDocument, operationId }));

    dispatch({ type: actionTypes.CLOSE_MODALS });
  }, [operationId, reduxDispatch]);

  return {
    handleDocumentUpload,
    handleRequestClose,
    handleRequestViewDocument,
    handleShowApproveModal,
    handleViewDocument,
    state
  };
}

/**
 * `DocumentsContent` component.
 */

function DocumentsContent({
  isSelected,
  operationDocuments,
  operationId
}: Props): JSX.Element {
  const reduxDispatch = useDispatch();
  const { showErrorMessage } = useSnackbar();
  const { translate } = useTranslate();
  const {
    handleDocumentUpload,
    handleRequestClose,
    handleRequestViewDocument,
    handleShowApproveModal,
    handleViewDocument,
    state
  } = useDocumentContent(operationId);
  const { documentPresignUrl, extension } = useSelector(getEarnDocumentPresignUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.activeDocumentId) {
      dispatch(fetchEarnDocumentPresignUrl({ documentId: state.activeDocumentId }));
    }
  }, [dispatch, state.activeDocumentId]);

  useEffect(() => {
    return () => {
      dispatch(resetFetchEarnDocumentPresignUrl());
    };
  }, [dispatch]);

  const onSubmit = useCallback(async (operationDocument: any) => {
    try {
      await handleDocumentUpload(operationDocument);
    } catch (error) {
      showErrorMessage(translate('operationDetails.errors.uploadDocument'));
    }
  }, [handleDocumentUpload, showErrorMessage, translate]);

  useEffect(() => {
    return () => {
      reduxDispatch(resetFetchEarnPresignUrl());
      reduxDispatch(resetFetchOperationDocuments());
      reduxDispatch(resetInsertOperationDocument());
    };
  }, [reduxDispatch]);

  return (
    <Wrapper isSelected={isSelected}>
      <UploadDocumentButton onClick={handleShowApproveModal}>
        {translate('documents.uploadDocumentButton')}
      </UploadDocumentButton>

      {operationDocuments.length ? (
        <>
          <DocumentsListHeader />

          <DocumentsListContent
            documents={operationDocuments}
            onRequestViewDocument={handleRequestViewDocument}
          />
        </>
      ) : (
        <ListEmptyState
          dataCy={'documents-emptyState'}
          description={translate('documentsList.emptyState')}
        />
      )}

      <ModalPortal isOpen={!!state.activeDocumentId}>
        {state.isViewDocumentModalVisible && state.activeDocumentId ? (
          <ViewDocumentModal
            documentPresignUrl={documentPresignUrl}
            extension={extension}
            isVisible={state.isViewDocumentModalVisible}
            onRequestClose={handleRequestClose}
          />
        ) : (
          <RequestViewDocumentModal
            isVisible={!!state.activeDocumentId}
            onContinue={handleViewDocument}
            onRequestClose={handleRequestClose}
          />
        )}
      </ModalPortal>

      <ModalPortal isOpen={state.isUploadModalVisible}>
        <UploadDocumentModal
          description={translate('operationDetails.modals.description')}
          isVisible={state.isUploadModalVisible}
          onRequestClose={handleRequestClose}
          onSubmit={onSubmit}
          title={translate('operationDetails.modals.title')}
        />
      </ModalPortal>
    </Wrapper>
  );
}

/**
 * Export `DocumentsContent` component.
 */

export default DocumentsContent;

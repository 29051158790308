
/**
 * Module dependencies.
 */

import { Fill, Loader, color, media, units } from 'pmint-design-system';
import { Trans } from 'react-i18next';
import { fetchPartners, resetFetchPartners } from 'client/core/redux/actions/fetch-partners';
import { getFetchPartnersState, getPartnersInformation } from 'client/core/redux/selectors/fetch-partners';
import { theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import CollapseItem from './collapse-item';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 2 / -2;
  margin-bottom: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(7)};
  `}
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  margin: ${units(5)} 0 ${units(9)};
  text-align: center;
`;

/**
 * `LoaderWrapper` styled component.
 */

const LoaderWrapper = styled(Fill)`
  align-items: center;
  animation: ${theme('keyframes.fadeIn')};
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: opacity ${theme('transitions.defaultTransition')};
`;

/**
 * `Content` component.
 */

function Content(): JSX.Element {
  const dispatch = useDispatch();
  const partners = useSelector(getPartnersInformation);
  const { isLoading } = useSelector(getFetchPartnersState);
  const { translate } = useTranslate();
  const parsedPartners = useMemo(() => {
    return partners.map(partner => ({
      content: {
        currentBalance: partner.balance,
        destinationAddress: partner.address
      },
      key: partner.name,
      partnerName: translate(`partners.names.${partner.name}`)
    }));
  }, [partners, translate]);

  useEffect(() => {
    dispatch(fetchPartners());

    return () => {
      dispatch(resetFetchPartners());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <DescriptionWrapper>
        <Trans components={{ bold: <b /> }}>
          {translate('partners.description')}
        </Trans>
      </DescriptionWrapper>

      {isLoading ? (
        <LoaderWrapper>
          <Loader
            colorTheme={color('bender2')}
            size={units(6)}
            stroke={units(0.5)}
          />
        </LoaderWrapper>
      ) : parsedPartners.map(({
        content,
        key,
        partnerName
      }: any) => (
        <CollapseItem
          content={content}
          key={key}
          partnerKey={key}
          partnerName={partnerName}
        />
      ))}
    </Wrapper>
  );
}

/**
 * Export `Content` component.
 */

export default Content;

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `fetchIdentityDocumentsType`.
 */

export const fetchIdentityDocumentsType = 'FETCH_IDENTITY_DOCUMENTS';

/**
 * Export `fetchIdentityDocumentsTypes`.
 */

export const fetchIdentityDocumentsTypes = createRequestActionTypes({
  type: fetchIdentityDocumentsType
});


/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchPartnerType } from 'client/core/redux/action-types/fetch-partner';

/**
 * Export `fetchPartner`.
 */

export default combineReducers(createRequestReducers({
  type: fetchPartnerType
}));


/**
 * Module dependencies.
 */

import type { AnyAction } from 'redux';
import { Box, media, units } from 'pmint-design-system';
import { Redirect, useLocation } from 'react-router-dom';
import { fetchTransactionById, resetFetchTransactionById } from 'client/core/redux/actions/fetch-transaction-by-id';
import { get } from 'lodash';
import { getFetchTransactionByIdState } from 'client/core/redux/selectors/fetch-transaction-by-id';
import { useDispatch, useSelector } from 'react-redux';
import BackLink from 'client/components/core/back-link';
import Loading from 'client/components/core/loading';
import React, { useCallback, useEffect, useReducer } from 'react';
import Tabs from 'client/components/core/tabs';
import TransactionDetails from 'client/components/dashboard/transactions-list-details/transaction-details';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `BackWrapper` styled component.
 */

const BackWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-bottom: ${units(3)};
  margin-left: ${units(3)};
`;

/**
 * `StyledTabs` styled component.
 */

const StyledTabs = styled(Tabs)<{
  transaction: string;
}>`
  display: flex;
  grid-column: 2 / -2;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin: 0 ${units(3)};
  `}
`;

/**
 * Reducer function.
 */

function reducer(state: any, action: AnyAction) {
  switch (action.type) {
    case 'selectTransaction':
      return {
        isTransactionSelected: true
      };

    default:
      return state;
  }
}

/**
 * `TransactionListDetails` container.
 */

function TransactionListDetails(): JSX.Element {
  const {
    data: activeTransaction,
    errors,
    isFailed,
    isLoading
  } = useSelector(getFetchTransactionByIdState);

  const reduxDispatch = useDispatch();
  const { transactionId } = useQueryString();
  const { state: locationState } = useLocation();
  const { translate } = useTranslate();
  const activeTransactionId = transactionId ?? get(locationState, 'transactionId');
  const [state, dispatch] = useReducer(reducer, {
    isTransactionSelected: true
  });

  const handleFetchTransactionById = useCallback(
    (activeTransactionId: string) => {
      return reduxDispatch(fetchTransactionById(activeTransactionId));
    },
    [reduxDispatch]
  );

  useEffect(() => {
    handleFetchTransactionById(activeTransactionId);

    return () => reduxDispatch(resetFetchTransactionById());
  }, [activeTransactionId, dispatch, handleFetchTransactionById, reduxDispatch]);

  useNetworkErrorMessage('identitiesListDetails.errors', errors);

  if (isFailed || !activeTransactionId) {
    return <Redirect to={routes.dashboard.transactions} />;
  }

  if (!activeTransaction || isLoading) {
    return <Loading isVisible />;
  }

  const tabItems = [
    {
      dataCy: 'transactionDetails-tab-transaction',
      id: 'transaction',
      isSelected: state.isTransactionSelected,
      label: translate('transactionDetails.tabs.transaction'),
      onSelectTab: () => {
        dispatch({ type: 'selectTransaction' });
      }
    }
  ];

  return (
    <>
      <BackWrapper>
        <BackLink to={routes.dashboard.transactions} />
      </BackWrapper>

      <StyledTabs
        items={tabItems}
        transaction={activeTransaction.data}
      />

      <Box gridColumn={'2 / -2'}>
        <TransactionDetails
          isSelected={state.isTransactionSelected}
          transaction={activeTransaction.data}
        />
      </Box>
    </>
  );
}

/**
 * Export `TransactionListDetails` container.
 */

export default TransactionListDetails;

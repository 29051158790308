/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { reopenIdentityReviewType } from 'client/core/redux/action-types/reopen-identity-review';

/**
 * Export `reopenIdentityReview`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'POST',
    type: reopenIdentityReviewType
  })
);

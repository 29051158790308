
/**
 * Module dependencies.
 */

import { Modal, Type, color, media, units } from 'pmint-design-system';
import FileInput from 'client/components/core/file-input';
import Form from 'client/components/core/forms/form';
import ModalCancelButton from 'client/components/core/modal/cancel-button';
import React from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  description: string;
  isVisible: boolean;
  onRequestClose: () => void;
  onSubmit: (operationDocument: any) => Promise<any>;
  title: string;
};

/**
 * Initial values.
 */

const initialValues = {
  operationDocument: null
};

/**
 * Browse schema.
 */

const browseSchema = {
  properties: {
    operationDocument: { type: 'object' }
  },
  required: ['operationDocument']
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(4)} ${units(2.5)};

  ${media.min('xs')`
    padding-left: ${units(4)};
    padding-right: ${units(4)};
  `}

  ${media.min('xl')`
    padding: ${units(6)} ${units(15)} ${units(10)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H5).attrs({ as: 'h1' })`
  color: ${color('textColor')};
  font-weight: 500;
  margin-bottom: ${units(6)};
  text-align: center;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-weight: 300;
  margin-bottom: ${units(4)};
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  margin-top: ${units(5)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  min-height: ${units(8)};
`;

/**
 * `UploadDocumentModal` component.
 */

function UploadDocumentModal(props: Props): JSX.Element {
  const {
    description,
    isVisible,
    onRequestClose,
    onSubmit,
    title
  } = props;

  const { translate } = useTranslate();

  return (
    <Modal
      isVisible={isVisible}
      onRequestClose={onRequestClose}
    >
      <Form
        initialValues={initialValues}
        jsonSchema={browseSchema}
        onSubmit={onSubmit}
      >
        <Wrapper>
          <Title>
            {title}
          </Title>

          <Description>
            {description}
          </Description>

          <FileInput
            isModalVisible={isVisible}
            name={'operationDocument'}
          />

          <SideBySide>
            <ModalCancelButton onRequestClose={onRequestClose} />

            <StyledSubmitButton>
              {translate('common.actions.continue')}
            </StyledSubmitButton>
          </SideBySide>
        </Wrapper>
      </Form>
    </Modal>
  );
}

/**
 * Export `UploadDocumentModal` component.
 */

export default UploadDocumentModal;

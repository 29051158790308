
/**
 * Module dependencies.
 */

import { Box, units } from 'pmint-design-system';
import { getAuthenticationStatus } from 'client/core/redux/selectors/authentication';
import { getFetchUserState, getUser, getUserRole } from 'client/core/redux/selectors/fetch-user';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import AccountInfo from 'client/components/dashboard/my-account/account';
import BackLink from 'client/components/core/back-link';
import Loading from 'client/components/core/loading';
import React from 'react';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';

/**
 * `BackWrapper` styled component.
 */

const BackWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-bottom: ${units(3)};
  margin-left: ${units(3)};
`;

/**
 * `MyAccount` container.
 */

function MyAccount(): JSX.Element {
  const user = useSelector(getUser);
  const role = useSelector(getUserRole);
  const { errors } = useSelector(getFetchUserState);
  const authenticationStatus = useSelector(getAuthenticationStatus);
  const isAuthenticated = authenticationStatus === 'authenticated';

  useNetworkErrorMessage('defaultErrors.network', errors);

  if (!isAuthenticated || isNil(user)) {
    return <Loading isVisible />;
  }

  return (
    <>
      <BackWrapper>
        <BackLink to={routes.dashboard.base} />
      </BackWrapper>

      <Box gridColumn={'2 / -2'}>
        <AccountInfo
          role={role}
          user={user.user}
        />
      </Box>
    </>
  );
}

/**
 * Export `MyAccount` container.
 */

export default MyAccount;

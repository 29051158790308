/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { fetchUacsType } from 'client/core/redux/action-types/fetch-uacs';
import { normalizeUacs } from 'client/core/utils/normalizer';

/**
 * Export `fetchUacs`.
 */

export const fetchUacs = () =>
  createRequestAction({
    endpoint: 'fetchUacs',
    handleResponse: ({ data }: any) => normalizeUacs(data),
    type: fetchUacsType
  });

/**
 * Export `resetFetchUacs`.
 */

export const resetFetchUacs = createResetRequestAction({
  type: fetchUacsType
});

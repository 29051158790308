/**
 * Module dependencies.
 */

import { format } from './formats';
import { isProduction } from 'core/utils/environment';
import XHR from 'i18next-xhr-backend';
import config from 'config';
import i18next from 'i18next';
import urlJoin from 'url-join';

/**
 * Base path.
 */

const basePath = config.get<string>('basePath');

/**
 * Locales path.
 */

const localesPath = config.get<string>('localization.route');

/**
 * `i18next` configuration.
 */

i18next.use(XHR).init({
  backend: {
    loadPath: urlJoin('/', basePath, localesPath, '/{{lng}}/{{ns}}.json')
  },
  debug: !isProduction(),
  defaultNS: config.get('localization.defaultNamespace'),
  fallbackLng: config.get('localization.fallbackLocale'),
  interpolation: {
    format
  },
  ns: config.get('localization.namespaces')
} as any);

/**
 * Export `i18n`.
 */

export const i18n = i18next;


/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/earn-operations';
import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchPartnerOperationsState`.
 */

export const getFetchPartnerOperationsState: RequestStateSelector = createRequestStateSelector('fetchPartnerOperations');

/**
 * Export `getPartnerOperations.
 */

export function getPartnerOperations(state: unknown): Array<Operation> {
  return getFetchPartnerOperationsState(state)?.data ?? [];
}


/**
 * Module dependencies.
 */

import { BigNumber } from 'bignumber.js';
import { Type, units } from 'pmint-design-system';
import { fetchPartner, resetFetchPartner } from 'client/core/redux/actions/fetch-partner';
import { getPartnerInformation } from 'client/core/redux/selectors/fetch-partner';
import { useDispatch, useSelector } from 'react-redux';
import FieldValue from 'client/components/core/field-value';
import React, { useEffect } from 'react';
import config from 'config';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  amount: string;
  partner: string;
  type: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
  margin-top: ${units(2)};
`;

/**
 * `FieldsWrapper` styled component.
 */

const FieldsWrapper = styled.div`
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(2, 1fr);
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `PartnerInformation` component.
 */

function PartnerInformation({
  amount,
  partner,
  type
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const { address, balance } = useSelector(getPartnerInformation);
  const partnersAddresses: any = config.get('partners');
  const balanceBigNumber = new BigNumber(balance);
  const balanceAfterOperation = type === 'receive' ? new BigNumber(balanceBigNumber)
    .minus(amount)
    .toFixed(2)
    .toString() : new BigNumber(amount)
    .plus(balanceBigNumber)
    .toFixed(2)
    .toString();

  const destinationAddress = type === 'receive' ? partnersAddresses[partner].circleAddress : address;
  const { translate } = useTranslate();

  useEffect(() => {
    dispatch(fetchPartner(partner));
  }, [dispatch, partner]);

  useEffect(() => {
    return () => dispatch(resetFetchPartner());
  }, [dispatch]);

  return (
    <Wrapper>
      <Title>
        {translate('operationDetails.partnerInformation.title')}
      </Title>

      <FieldsWrapper>
        <FieldValue
          data-cy={'operationDetails-information-partnerBalance'}
          key={'partnerBalance'}
          label={translate('operationDetails.partnerInformation.partnerBalance')}
        >
          {balance}
        </FieldValue>

        <FieldValue
          data-cy={'operationDetails-information-balanceAfterOperation'}
          key={'partnerBalanceAfterOperation'}
          label={translate('operationDetails.partnerInformation.balanceAfterOperation')}
        >
          {balanceAfterOperation}
        </FieldValue>
      </FieldsWrapper>

      <FieldValue
        data-cy={'operationDetails-information-destinationAddress'}
        key={'partnerDestinationAddress'}
        label={translate('operationDetails.partnerInformation.destinationAddress')}
      >
        {destinationAddress}
      </FieldValue>
    </Wrapper>
  );
}

/**
 * Export `PartnerInformation` component.
 */

export default PartnerInformation;

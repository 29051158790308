
/**
 * Module dependencies.
 */

import { approveOperation, resetApproveOperation } from 'client/core/redux/actions/approve-operation';
import { approveOperationState } from 'client/core/redux/selectors/approve-operation';
import { color, media, states, units } from 'pmint-design-system';
import { ifProp } from 'styled-tools';
import { rejectOperation, resetRejectOperation } from 'client/core/redux/actions/reject-operation';
import { rejectOperationState } from 'client/core/redux/selectors/reject-operation';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import Button from 'client/components/core/button';
import React, { useCallback, useEffect } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  disabled: boolean;
  operationId: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  justify-items: center;
  margin: ${units(4)} 0;

  ${media.min('xs')`
    grid-column-gap: ${units(3)};
    grid-template-columns: repeat(2 ,1fr);
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  background-color: ${ifProp('isRejectButton', color('errorDark'), color('bender2'))};
  border: 1px solid ${ifProp('isRejectButton', color('errorDark'), color('bender2'))};

  ${states.interaction`
    background-color: ${ifProp('isRejectButton', color('red100'), color('bender3'))};
    border-color: ${ifProp('isRejectButton', color('red100'), color('bender3'))};
  `}
`;

/**
 * `ActionButtons` component.
 */

function ActionButtons(props: Props): JSX.Element {
  const { disabled, operationId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    errors: approveErrors,
    isLoading: isLoadingApprove,
    isSuccessful: isSuccessfulApprove
  } = useSelector(approveOperationState);

  const {
    errors: rejectErrors,
    isLoading: isLoadingReject,
    isSuccessful: isRejectSuccessful
  } = useSelector(rejectOperationState);

  const { showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const onApproveButtonClick = useCallback(() => {
    dispatch(approveOperation({ operationId }));
  }, [dispatch, operationId]);

  const onRejectButtonClick = useCallback(() => {
    dispatch(rejectOperation({ operationId }));
  }, [dispatch, operationId]);

  useNetworkErrorMessage('operationDetails.errors.network', approveErrors || rejectErrors);

  useEffect(() => {
    if (isSuccessfulApprove || isRejectSuccessful) {
      showSuccessMessage(translate('operationDetails.actionButtons.success'));

      history.push(routes.dashboard.operations);
    }
  }, [dispatch, history, isRejectSuccessful, isSuccessfulApprove, showSuccessMessage, translate]);

  useEffect(() => {
    return () => {
      dispatch(resetApproveOperation());
      dispatch(resetRejectOperation());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <StyledButton
        data-cy={'operationDetails-information-approve'}
        isDisabled={disabled}
        isLoading={isLoadingApprove}
        onClick={onApproveButtonClick}
      >
        {translate('common.actions.approve')}
      </StyledButton>

      <StyledButton
        data-cy={'operationDetails-information-reject'}
        isLoading={isLoadingReject}
        isRejectButton
        onClick={onRejectButtonClick}
      >
        {translate('common.actions.reject')}
      </StyledButton>
    </Wrapper>
  );
}

/**
 * Export `ActionButtons` component.
 */

export default ActionButtons;

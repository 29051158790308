/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/operations';
import { Type, color, media, units } from 'pmint-design-system';
import InfiniteScrollList from 'client/components/core/lists/infinite-scroll-list';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  operations: Array<Operation>;
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;

  ${media.min('xs')`
    grid-template-columns: 3fr 3fr 4fr 2fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover,
  &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
`;

/**
 * `UacManagementDetailsListContent` component.
 */

function UacManagementDetailsListContent({ operations }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <InfiniteScrollList dataCy={'uacManagementDetailsListContent-list'}
      items={operations}
    >
      {({ index, style }: any) => {
        const { amount, createdAt, id, transferType, type } = operations[index];

        return (
          <RowWrapper key={id}
            style={style}
          >
            <Value data-cy={'uacManagementDetailsListContent-createdAt'}>{createdAt}</Value>

            <Value data-cy={'uacManagementDetailsListContent-type'}>
              {translate(`uacManagementDetails.listContent.types.${type}`)}
            </Value>

            <Value data-cy={'uacManagementDetailsListContent-transferType'}>
              {translate(`uacManagementDetails.listContent.methods.${transferType}`)}
            </Value>

            <Value data-cy={'uacManagementDetailsListContent-amount'}>{amount}</Value>
          </RowWrapper>
        );
      }}
    </InfiniteScrollList>
  );
}

/**
 * Export `UacManagementDetailsListContent` component.
 */

export default UacManagementDetailsListContent;

/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchTotalBalanceState`.
 */

export const getFetchTotalBalanceState: RequestStateSelector<string> = createRequestStateSelector('fetchTotalBalance');

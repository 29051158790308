/**
 * Module dependencies.
 */

import { setAuthenticatedType } from 'client/core/redux/action-types/set-authenticated';

/**
 * Export `setAuthenticated`.
 */

export function setAuthenticated() {
  return {
    type: setAuthenticatedType
  };
}

/**
 * Module dependencies.
 */

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cookies from 'browser-cookies';
import qs from 'qs';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  languages: Array<string>;
};

/**
 * `LocaleCookieUrlSyncer` container.
 */

const LocaleCookieUrlSyncer = ({ languages }: Props) => {
  const { i18n } = useTranslate();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const cookieLocale = cookies.get('locale');

    if (cookieLocale !== i18n.language) {
      cookies.set('locale', i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    const { search, state } = location;
    const queryParameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { locale, ...restQuery } = queryParameters;

    if (languages.length > 1) {
      if (i18n.language !== locale) {
        // Set the locale query param to match the app's language.
        history.replace({
          search: qs.stringify({
            ...restQuery,
            locale: i18n.language
          }),
          state
        });
      }
    } else if (locale) {
      // Remove the locale query param when there is only one language.
      history.replace({ search: qs.stringify(restQuery), state });
    }
  }, [history, i18n.language, languages, location]);

  return null;
};

/**
 * Export `LocaleCookieUrlSyncer` container.
 */

export default LocaleCookieUrlSyncer;

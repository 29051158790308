
/**
 * Module dependencies.
 */

import { Loader, Button as PmintButton, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element | string;
  'data-cy'?: string;
  fullWidth?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRejectButton?: boolean;
  onClick: () => void;
};

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(PmintButton)`
  max-width: ${units(40)};
  min-height: ${units(8)};
  width: 100%;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Label` styled component.
 */

const Label = styled.div`
  flex: 1;
`;

/**
 * `Button` component.
 */

const Button = (props: Props): JSX.Element => {
  const {
    children,
    isDisabled,
    isLoading,
    onClick,
    ...rest
  } = props;

  return (
    <StyledButton
      {...rest}
      disabled={isDisabled}
      onClick={onClick}
    >
      <ContentWrapper>
        <Label>
          {children}
        </Label>

        {isLoading && <Loader /> }
      </ContentWrapper>
    </StyledButton>
  );
};

/**
 * Export `Button` component.
 */

export default Button;

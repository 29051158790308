/**
 * Module dependencies.
 */

import { calculateTotalBalance } from 'client/core/utils/web3';
import { fetchTotalBalanceTypes } from 'client/core/redux/action-types/fetch-total-balance';

/**
 * Export `fetchTotalBalance`.
 */

export const fetchTotalBalance = () => {
  return {
    payload: calculateTotalBalance(),
    type: fetchTotalBalanceTypes
  };
};

/**
 * Module dependencies.
 */

import { Textarea } from 'pmint-design-system';
import { getFieldValue } from 'client/core/utils/form';
import { useField, useFormState } from '@seegno/react-forms';
import React, { ChangeEvent, FocusEventHandler, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  label: string;
  name: string;
  type: string;
};

/**
 * `TextAreaField` component.
 */

function TextAreaField(props: Props): JSX.Element {
  const { dataCy, disabled, label, name, ...rest } = props;
  const { error, meta, onBlur, onChange, onFocus, value } = useField(name);

  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const handleBlur = useCallback(
    (event: FocusEventHandler<HTMLElement>) => {
      if (onBlur) {
        onBlur(event);
        onChange(getFieldValue(value));
      }
    },
    [onBlur, onChange, value]
  );

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(target.value);
    },
    [onChange]
  );

  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const textareaLabel = useLabel(label, name);

  return (
    <Textarea
      {...rest}
      data-cy={dataCy}
      disabled={isDisabled}
      hasError={hasError}
      label={textareaLabel}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      value={value}
    />
  );
}

/**
 * Export `TextAreaField` component.
 */

export default TextAreaField;

/**
 * Module dependencies.
 */

import { canUseDOM } from './dom';
import { createPortal } from 'react-dom';

/**
 * Export `createReactPortal`.
 */

export function createReactPortal(children: JSX.Element, elementId: string) {
  if (!canUseDOM()) {
    return null;
  }

  const id = document.getElementById(elementId);

  if (!id) {
    return null;
  }

  return createPortal(children, id);
}


/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/earn-operations';
import { Type, color, media, units } from 'pmint-design-system';
import { useHistory } from 'react-router-dom';
import InfiniteScrollList from 'client/components/core/lists/infinite-scroll-list';
import React, { useCallback } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  operations: Array<Operation>;
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a<{
    style?: any;
}>`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;

  ${media.min('xs')`
    grid-template-columns: 2fr 1fr 2fr 2fr 3fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover, &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  margin-left: ${units(1)};
`;

/**
 * `ListContent` component.
 */

function ListContent({ operations }: Props): JSX.Element {
  const history = useHistory();
  const handleRowClick = useCallback((id: string) => {
    history.push({
      pathname: routes.dashboard.operationDetails,
      state: { operationId: id }
    });
  }, [history]);

  return (
    <InfiniteScrollList items={operations}>
      {({ index, style }) => {
        const {
          amount,
          createdAt,
          id,
          partner,
          status,
          type
        } = operations[index];

        return (
          <RowWrapper
            key={id}
            onClick={() => {
              handleRowClick(id);
            }}
            style={style}
          >
            <Value data-cy={`operations-content-amount-${id}`}>
              {amount}
            </Value>

            <Value data-cy={`operations-content-partner-${id}`}>
              {partner}
            </Value>

            <Value data-cy={`operations-content-type-${id}`}>
              {type}
            </Value>

            <Value data-cy={`operations-content-status-${id}`}>
              {status}
            </Value>

            <Value data-cy={`operations-content-createdAt-${id}`}>
              {createdAt}
            </Value>
          </RowWrapper>
        );
      }}
    </InfiniteScrollList>
  );
}

/**
 * Export `ListContent` component.
 */

export default ListContent;


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchPartnersType } from 'client/core/redux/action-types/fetch-partners';
import { get as getFp } from 'lodash/fp';

/**
 * Export `fetchPartners`.
 */

export function fetchPartners() {
  return createRequestAction({
    endpoint: 'fetchPartners',
    handleResponse: getFp('data.data'),
    type: fetchPartnersType
  });
}

/**
 * Export `resetFetchPartners`.
 */

export const resetFetchPartners = createResetRequestAction({
  type: fetchPartnersType
});

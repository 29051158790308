/**
 * Module dependencies.
 */

import { closeUACType } from 'client/core/redux/action-types/close-uac';
import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';

/**
 * Export `closeUAC`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'POST',
    type: closeUACType
  })
);

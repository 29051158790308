/**
 * Module dependencies.
 */

import { AnyAction, combineReducers } from 'redux';
import { logoutTypes } from 'client/core/redux/action-types/logout';
import { refreshTokenTypes } from 'client/core/redux/action-types/refresh-token';
import { setAuthenticatedType } from 'client/core/redux/action-types/set-authenticated';
import { signInTypes } from 'client/core/redux/action-types/sign-in';

/**
 * Export `AuthenticationStatus` type.
 */

export type AuthenticationStatus = 'unknown' | 'unauthenticated' | 'authenticated';

/**
 * Status.
 */

function status(state: AuthenticationStatus = 'unknown', action: AnyAction): AuthenticationStatus {
  const { payload, type } = action ?? {};

  switch (type) {
    case refreshTokenTypes.successType:
      return payload?.token ? 'authenticated' : 'unauthenticated';

    case setAuthenticatedType:
      return 'authenticated';

    case logoutTypes.successType:
    case refreshTokenTypes.failureType:
      return 'unauthenticated';

    default:
      return state;
  }
}

/**
 * Token.
 */

function token(state: string | null = null, action: AnyAction): string | null {
  const { payload, type } = action ?? {};

  switch (type) {
    case logoutTypes.failureType:
    case refreshTokenTypes.successType:
    case signInTypes.successType:
      return payload?.token ?? null;

    case logoutTypes.successType:
    case refreshTokenTypes.failureType:
    case signInTypes.failureType:
      return null;

    default:
      return state;
  }
}

/**
 * Export `authentication`.
 */

export default combineReducers({
  status,
  token
});

/**
 * Module dependencies.
 */

import type { Uac } from 'client/core/types/uacs';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchUacsState`.
 */

export const getFetchUacsState = createRequestStateSelector('fetchUacs');

/**
 * Export `getUacs`.
 */

export const getUacs = (state: unknown): Array<Uac> => {
  return getFetchUacsState(state)?.data || [];
};


/**
 * Module dependencies.
 */

import { approveOperationType } from 'client/core/redux/action-types/approve-operation';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';

/**
 * `Options` type.
 */

type Options = {
  operationId: string;
};

/**
 * Export `approveOperation`.
 */

export function approveOperation({ operationId }: Options) {
  return createRequestAction({
    endpoint: {
      name: 'approveOrRejectOperation',
      params: { operationId, order: 'approve' }
    },
    handleResponse: ({ data }: any) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: approveOperationType
  });
}

/**
 * Export `resetApproveOperation`.
 */

export const resetApproveOperation = createResetRequestAction({
  method: 'POST',
  type: approveOperationType
});

/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchIdentityVerificationsType } from 'client/core/redux/action-types/fetch-identity-verifications';
import { get as getFp } from 'lodash/fp';

/**
 * Export `fetchIdentityVerifications`.
 */

export const fetchIdentityVerifications = (identityId: string) => {
  return createRequestAction({
    endpoint: {
      name: 'identityVerifications',
      params: { id: identityId }
    },
    handleResponse: getFp('data'),
    type: fetchIdentityVerificationsType
  });
};

/**
 * Export `resetFetchIdentityVerifications`.
 */

export const resetFetchIdentityVerifications = createResetRequestAction({
  type: fetchIdentityVerificationsType
});

/**
 * Module dependencies.
 */

import { Modal, Type, color, media, units } from 'pmint-design-system';

import DocumentImage from 'client/components/core/documents/document-image';
import PDFDocument from './pdf-document';
import React from 'react';
import closeIcon from 'pmint-design-system/icons/close-24px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  documentPresignUrl?: string;
  extension?: string;
  isVisible: boolean;
  onRequestClose: () => void;
  side?: string;
  type?: string;
};

/**
 * `Label` styled component.
 */

const Label = styled(Type.Label).attrs({ as: 'h2' })`
  color: ${color('textColor')};
  font-weight: 400;
  margin-bottom: ${units(4.25)};
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: 30px ${units(2.5)};

  ${media.min('xs')`
    padding-left: ${units(4)};
    padding-right: ${units(4)};
  `}

  ${media.min('xl')`
    padding: 50px ${units(15)} ${units(10)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H5).attrs({ as: 'h1' })`
  color: ${color('textColor')};
  font-weight: 500;
  margin-bottom: ${units(6)};
  text-align: center;
`;

/**
 * `StyledDocumentImage` styled component.
 */

const StyledDocumentImage = styled(DocumentImage)`
  margin-left: auto;
  margin-right: auto;
`;

/**
 * `ViewDocumentModal` component.
 */

function ViewDocumentModal({
  documentPresignUrl,
  extension,
  isVisible,
  onRequestClose,
  side,
  type
}: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Modal
      closeButtonAriaLabel={translate('common.actions.close')}
      closeIcon={closeIcon}
      isVisible={isVisible}
      onRequestClose={onRequestClose}
    >
      <Wrapper data-cy={'viewDocument'}>
        <Title>
          {translate('documents.modals.viewDocument.title')}
        </Title>

        {
          side && (
            <Label>
              {`${translate(`documents.types.${type}`)}
           - ${translate(`documents.sides.${side}`)}`}
            </Label>
          )
        }

        {extension === '.pdf' ? (
          <PDFDocument documentUrl={documentPresignUrl} />
        ) : (
          <StyledDocumentImage documentUrl={documentPresignUrl} />
        )}
      </Wrapper>
    </Modal>
  );
}

/**
 * Export `ViewDocumentModal` component.
 */

export default ViewDocumentModal;

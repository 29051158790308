/**
 * Module dependencies.
 */

import { Select as PmintSelect } from 'pmint-design-system';
import { useField, useFormState } from '@seegno/react-forms';
import React, { ChangeEvent, FocusEventHandler, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  disabled?: boolean;
  emptyOptionLabel?: string;
  items: Array<{
    label: string;
    value: string;
  }>;
  label: string;
  name: string;
  onChange?: (value: string) => void;
};

/**
 * `Select` component.
 */

function Select(props: Props): JSX.Element {
  const { disabled, emptyOptionLabel, items, label, name, ...rest } = props;

  const { error, meta, onBlur, onChange, onFocus, value } = useField(name);

  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const handleBlur = useCallback(
    (event: FocusEventHandler<HTMLElement>) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(target.value);

      if (props.onChange) {
        props.onChange(target.value);
      }
    },
    [onChange, props]
  );

  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const selectLabel = useLabel(label, name);

  return (
    <PmintSelect
      {...rest}
      disabled={isDisabled}
      emptyOptionLabel={emptyOptionLabel ? emptyOptionLabel : null}
      hasError={hasError}
      hideEmptyOption
      items={items}
      label={selectLabel}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      value={value}
    />
  );
}

/**
 * Export `Select` component.
 */

export default Select;

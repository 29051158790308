/**
 * Module dependencies.
 */

import { Icon, IconButton, Type, color, media, states, units } from 'pmint-design-system';

import { ifProp, prop } from 'styled-tools';
import { truncate } from 'lodash';
import React from 'react';
import deleteIcon from 'client/assets/svg/delete.svg';
import documentIcon from 'client/assets/svg/document.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  file: File;
  hasError: boolean;
  onRemove: () => void;
  progress: number;
};

/**
 * Progress style.
 */

const progressStyle = css`
  bottom: 0;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  hasError: boolean;
  progress: number;
}>`
  align-items: center;
  background-color: ${color('gray200')};
  border-radius: ${units(1)};
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-areas:
    'name action'
    'info info';
  grid-template-columns: 1fr min-content;
  grid-template-rows: repeat(3, min-content);
  overflow: hidden;
  padding: ${units(1.5)} ${units(1)};
  position: relative;

  ${media.min('sm')`
    grid-template-areas: "icon name info action";
    grid-template-columns: min-content 1fr 1fr min-content;
    grid-template-rows: auto;
  `}

  &::after,
  &::before {
    ${progressStyle}

    content: '';
  }

  &::before {
    background-color: ${color('gray300')};
  }

  &::after {
    background-color: ${color('bender2')};
    transform: scaleX(${prop('progress', 0)});
    transform-origin: left center;
  }

  ${ifProp(
    'hasError',
    css`
      &::after {
        background-color: ${color('error')};
        transform: scaleX(1);
      }
    `
  )}
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  display: none;

  ${media.min('sm')`
    display: block;
    grid-area: icon;
  `}
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  background-color: transparent;
  grid-area: action;
  justify-self: end;

  ${states.interaction`
    background-color: ${color('gray300')};
  `}
`;

/**
 * `AccessibleProgress` styled component.
 */

const AccessibleProgress = styled.progress`
  ${progressStyle}

  appearance: none;
  opacity: 0;
`;

/**
 * Truncate file name.
 */

function truncateFileName(fileName: string) {
  const maxSize = 18;

  if (fileName.length < maxSize) {
    return fileName;
  }

  const match = fileName.match(/\.[^.]{1,4}$/);

  if (!match) {
    return truncate(fileName, {
      length: maxSize,
      omission: '...'
    });
  }

  const [extension] = match;

  return truncate(fileName, {
    length: maxSize,
    omission: `[...]${extension}`
  });
}

/**
 * `FileDetails` component.
 */

function FileDetails(props: Props): JSX.Element {
  const { file, hasError, onRemove, progress } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper hasError={hasError}
      progress={progress}
    >
      <StyledIcon color={color('gray500')}
        icon={documentIcon}
        size={units(4)}
      />

      <Type.Paragraph color={color('textColor')}
        fontWeight={400}
        gridArea={'name'}
      >
        {truncateFileName(file.name)}
      </Type.Paragraph>

      <Type.Small as={'p'}
        color={color('textColor')}
        gridArea={'info'}
      >
        {translate('common.fields.file.fileSize', { size: file.size })}
      </Type.Small>

      <StyledIconButton
        aria-label={translate('common.actions.remove')}
        colorTheme={'secondary'}
        icon={deleteIcon}
        onClick={onRemove}
      />

      <AccessibleProgress value={progress} />
    </Wrapper>
  );
}

/**
 * Export `FileDetails` component.
 */

export default FileDetails;

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import type { Uac } from 'client/core/types/uacs';
import { useHistory } from 'react-router-dom';
import InfiniteScrollList from 'client/components/core/lists/infinite-scroll-list';
import React, { useCallback } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  uacs: Array<Uac>;
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;

  ${media.min('xs')`
    grid-template-columns: 3fr 2fr 4fr 4fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover,
  &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
`;

/**
 * `UACManagementListContent` component.
 */

function UACManagementListContent({ uacs }: Props): JSX.Element {
  const history = useHistory();
  const handleRowClick = useCallback(
    (id: string) => {
      history.push({
        pathname: routes.dashboard.uacManagementDetails,
        state: { uacId: id }
      });
    },
    [history]
  );

  return (
    <InfiniteScrollList items={uacs}>
      {({ index, style }: any) => {
        const { amount, createdAt, id, identity } = uacs[index];

        return (
          <RowWrapper key={id}
            onClick={() => handleRowClick(id)}
            style={style}
          >
            <Value data-cy={'uacManagementListContent-id'}>{id}</Value>

            <Value data-cy={'uacManagementListContent-createdAt'}>{createdAt}</Value>

            <Value data-cy={'uacManagementListContent-identity'}>{identity}</Value>

            <Value data-cy={'uacManagementListContent-amount'}>{amount}</Value>
          </RowWrapper>
        );
      }}
    </InfiniteScrollList>
  );
}

/**
 * Export `UACManagementListContent` component.
 */

export default UACManagementListContent;


/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/earn-operations';
import { Type, color, media, units } from 'pmint-design-system';
import { formatDate } from 'client/core/utils/formatter';
import { theme } from 'styled-tools';
import { useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  operations: Array<Operation>;
  partner: string;
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;
  transition: background-color ${theme('transitions.defaultTransition')};

  ${media.min('xs')`
    grid-template-columns: 3fr 3fr 2fr 2fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover, &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  margin-left: ${units(1)};
`;

/**
 * `OperationsListContent` component.
 */

function OperationsListContent({ operations, partner }: Props): JSX.Element {
  const { translate } = useTranslate();
  const history = useHistory();
  const handleRowClick = useCallback((id: string) => {
    history.push({
      pathname: routes.dashboard.operationDetails,
      state: { operationId: id, to: `${routes.dashboard.partnerDetails}?partner=${partner}` }
    });
  }, [history, partner]);

  return (<>
    {operations.map(({
      amount,
      createdAt,
      id,
      status,
      type
    }) => (
      <RowWrapper
        key={id}
        onClick={() => {
          handleRowClick(id);
        }}
      >
        <Value data-cy={`partnerDetails-operationsList-${id}-type`}>
          {translate(`partnerDetails.operationsList.values.type.${type}`)}
        </Value>

        <Value data-cy={`partnerDetails-operationsList-${id}-amount`}>
          {amount}
        </Value>

        <Value data-cy={`partnerDetails-operationsList-${id}-status`}>
          {translate(`partnerDetails.operationsList.values.status.${status}`)}
        </Value>

        <Value data-cy={`partnerDetails-operationsList-${id}-createdAt`}>
          {formatDate(createdAt, { hasTime: false })}
        </Value>
      </RowWrapper>
    ))}
  </>);
}

/**
 * Export `OperationsListContent` component.
 */

export default OperationsListContent;

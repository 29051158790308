/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { get } from 'lodash';
import { getAuthenticationStatus } from 'client/core/redux/selectors/authentication';
import { theme } from 'styled-tools';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLayout from 'client/components/page-layout';
import React, { useEffect } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import SignInForm from 'client/components/sign-in/sign-in-form';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(5.75)};
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content auto;
  padding-bottom: ${units(2)};

  ${media.min('md')`
    grid-template-columns: 2fr 4fr 2fr;
    min-height: 100vh;
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  grid-column: 2;
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('bender2')};
  grid-column: 1 / -1;
  height: ${theme('layout.header.height.line')};
`;

/**
 * `SignIn` container.
 */

function SignIn(): JSX.Element {
  const { translate } = useTranslate();
  const authenticationStatus = useSelector(getAuthenticationStatus);
  const history = useHistory();
  const { state } = useLocation();
  const redirectUrl = get(state, 'redirectUrl', routes.dashboard.base);

  useEffect(() => {
    if (authenticationStatus === 'authenticated') {
      return history.push(redirectUrl);
    }
  }, [authenticationStatus, history, redirectUrl]);

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <Wrapper>
        <Line />

        <ContentWrapper>
          <Type.H4 color={color('bender2')}
            marginBottom={units(6)}
          >
            {translate('signIn.hiThere')}
          </Type.H4>

          <SignInForm redirectUrl={redirectUrl} />
        </ContentWrapper>
      </Wrapper>
    </PageLayout>
  );
}

/**
 * Export `SignIn` container.
 */

export default SignIn;


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchOperationsType } from 'client/core/redux/action-types/fetch-operations';

/**
 * Export `fetchOperations`.
 */

export function fetchOperations() {
  return createRequestAction({
    endpoint: 'fetchOperations',
    handleResponse: ({ data }: any) => data,
    type: fetchOperationsType
  });
}

/**
 * Export `resetFetchOperations`.
 */

export const resetFetchOperations = createResetRequestAction({
  type: fetchOperationsType
});

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `fetchUacByIdType`.
 */

export const fetchUacByIdType = 'FETCH_UAC_BY_ID';

/**
 * Export `fetchUacByIdTypes`.
 */

export const fetchUacByIdTypes = createRequestActionTypes({
  type: fetchUacByIdType
});

/**
 * Module dependencies.
 */

import { createRequestActionType } from 'core-redux/request';

/**
 * Export `fetchTotalBalanceType`.
 */

export const fetchTotalBalanceType = 'FETCH_TOTAL_BALANCE';

/**
 * Export `fetchTotalBalanceTypes`.
 */

export const fetchTotalBalanceTypes = createRequestActionType({
  type: fetchTotalBalanceType
});


/**
 * Module dependencies.
 */

import { Type, units } from 'pmint-design-system';
import FieldValue from 'client/components/core/field-value';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  operationValues: Array<{
    label: string;
    value: string;
  }>;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 2 / -2;
  margin-bottom: ${units(2)};
`;

/**
 * `FieldsWrapper` styled component.
 */

const FieldsWrapper = styled.div`
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(2, 1fr);
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `PartnerDetails` component.
 */

function PartnerDetails({ operationValues }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Title>
        {translate('operationDetails.title')}
      </Title>

      <FieldsWrapper>
        {operationValues.map(({ label, value }) => (
          <FieldValue
            key={value}
            label={label}
          >
            {value}
          </FieldValue>
        ))}
      </FieldsWrapper>
    </Wrapper>
  );
}

/**
 * Export `PartnerDetails` component.
 */

export default PartnerDetails;

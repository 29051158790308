/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { fetchUacs, resetFetchUacs } from 'client/core/redux/actions/fetch-uacs';

import { getUacs } from 'client/core/redux/selectors/fetch-uacs';
import { theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import RawHtml from 'react-components/raw-html';
import React, { useEffect } from 'react';
import UACManagementListContent from './list-content';
import UACManagementListHeader from './list-header';
import config from 'config';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-column: 2 / -2;
  margin-bottom: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(7)};
  `}
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  margin: ${units(5)} 0 ${units(9)};
  text-align: center;
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  ${theme('typography.styles.label')}

  color: ${color('textColor')};

  > a {
    color: ${color('textColor')};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${color('textColorLight')};
    }
  }
`;

/**
 * `UacListEmptyState` styled component.
 */

const UacListEmptyState = styled(Type.Label).attrs({ as: 'p' })`
  border: 1px solid black;
  border-radius: ${units(1)};
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  padding: ${units(2)};
  text-align: center;
`;

/**
 * `UACManagementList` component.
 */

function UACManagementList(): JSX.Element {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const supportEmail = config.get('supportEmail');
  const uacs = useSelector(getUacs);

  useEffect(() => {
    dispatch(fetchUacs());

    return () => dispatch(resetFetchUacs());
  }, [dispatch]);

  return (
    <Wrapper>
      <DescriptionWrapper data-cy={'uacManagementList-description'}>
        <StyledRawHtml>{translate('uacManagement.description', { supportEmail })}</StyledRawHtml>
      </DescriptionWrapper>

      {uacs.length ? (
        <>
          <UACManagementListHeader />

          <UACManagementListContent uacs={uacs} />
        </>
      ) : (
        <UacListEmptyState data-cy={'uacManagementList-emptyState'}>
          {translate('uacManagement.listEmptyState')}
        </UacListEmptyState>
      )}
    </Wrapper>
  );
}

/**
 * Export `UACManagementList` component.
 */

export default UACManagementList;


/**
 * Module dependencies.
 */

import { color, media, units } from 'pmint-design-system';
import { getPostIdentityCommentState } from 'client/core/redux/selectors/post-identity-comment';
import { ifProp } from 'styled-tools';
import { isEmpty } from 'lodash';
import { useFieldState, useFormActions } from '@seegno/react-forms';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import TextAreaField from 'client/components/core/forms/fields/text-area';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${units(4)};

  > button {
    max-width: ${units(27)};
    flex-grow: 1;
    flex-shrink: 1;

    ${media.min('xs')`
      flex-grow: 0;
    `}
  }
`;

/**
 * `CharCounterWrapper` styled component.
 */

const CharCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${units(2)};
  color: ${color('textColorLight')};
`;

/**
 * `CharCounter` styled component.
 */

const CharCounter = styled.div<{
  error: boolean | undefined;
}>`
  ${ifProp('error', css`color: ${color('error')};`)}
`;

/**
 * `CommentsForm` component.
 */

function CommentsForm(): JSX.Element {
  const { translate } = useTranslate();
  const { reset } = useFormActions();
  const { value } = useFieldState('text');
  const { isSuccessful } = useSelector(getPostIdentityCommentState);

  useEffect(() => {
    if (isSuccessful) {
      reset();
    }
  }, [isSuccessful, reset]);

  return (
    <>
      <TextAreaField
        dataCy={'identitiesListDetails-comments-comment'}
        label={translate('identitiesListDetails.comments.newComment.comment')}
        name={'text'}
        type={'text'}
      />

      <SubmitButtonWrapper>
        <CharCounterWrapper>
          <CharCounter error={value ? value.length > 255 : false}>
            {value ? value.length : '0'}
          </CharCounter>

          {'/255'}
        </CharCounterWrapper>

        <SubmitButton
          dataCy={'identitiesListDetails-comments-submit'}
          fullWidth
          isDirty={!isEmpty(value)}
        >
          {translate('identitiesListDetails.comments.newComment.submit')}
        </SubmitButton>
      </SubmitButtonWrapper>
    </>
  );
}

/**
 * Export `CommentsForm` component.
 */

export default CommentsForm;


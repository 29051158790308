
/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { postUserPasswordType } from 'client/core/redux/action-types/post-user-password';

/**
 * Export `postUserPassword`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'POST',
    type: postUserPasswordType
  })
);

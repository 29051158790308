/**
 * Module dependencies.
 */

import { Context, createContext, useContext } from 'react';
import { noop } from 'lodash';

/**
 * `SnackbarContextType` type.
 */

type SnackbarContextType = {
  showErrorMessage: (content: string, options?: Record<string, any>) => void;
  showInfoMessage: (content: string, options?: Record<string, any>) => void;
  showSuccessMessage: (content: string, options?: Record<string, any>) => void;
  showWarningMessage: (content: string, options?: Record<string, any>) => void;
};

/**
 * Export `SnackbarContext` context.
 */

export const SnackbarContext: Context<SnackbarContextType> = createContext({
  showErrorMessage: noop,
  showInfoMessage: noop,
  showSuccessMessage: noop,
  showWarningMessage: noop
});

/**
 * Export `useSnackbar` hook.
 */

export const useSnackbar = () => useContext(SnackbarContext);


/**
 * Module dependencies.
 */

import type { Comment } from 'client/core/types/comment';
import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchCommentsOperationsState`.
 */

export const getFetchIdentityCommentsState: RequestStateSelector = createRequestStateSelector('fetchIdentityComments');

/**
 * Export `getIdentityComments`.
 */

export function getIdentityComments(state: unknown): Array<Comment> {
  return getFetchIdentityCommentsState(state)?.data ?? [];
}

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';
import { get } from 'lodash';

/**
 * `PresignData` type.
 */

type PresignData = {
  documentPresignUrl: string;
  extension: string;
};

/**
 * Export `getFetchDocumentPresignUrlState`.
 */

export const getFetchDocumentPresignUrlState = createRequestStateSelector('fetchDocumentPresignUrl');

/**
 * Export `getDocumentPresignUrl`.
 */

export const getDocumentPresignUrl = (state: unknown) => {
  return get<Partial<PresignData>>(getFetchDocumentPresignUrlState(state), 'data', {}) ?? {};
};

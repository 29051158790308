
/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { useFormState } from '@seegno/react-forms';
import ModalCancelButton from 'client/components/core/modal/cancel-button';
import PasswordField from 'client/components/core/forms/fields/password';
import React from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  onRequestClose: () => void;
  userId?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(3.75)} ${units(2.5)};

  ${media.min('xs')`
    padding-left: ${units(4)};
    padding-right: ${units(4)};
  `}

  ${media.min('xl')`
    padding: ${units(6.25)} ${units(15)} ${units(10)};
  `}
`;

/**
 * `StyledTitle` styled component.
 */

const StyledTitle = styled(Type.H5).attrs({ as: 'h1' })`
  color: ${color('textColor')};
  font-weight: 500;
  margin-bottom: ${units(6.25)};
  text-align: center;
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  margin-top: ${units(5)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  min-height: ${units(8)};
`;

/**
 * `ChangePasswordForm` component.
 */

function ChangePasswordForm({ onRequestClose }: Props): JSX.Element {
  const { translate } = useTranslate();
  const { errors } = useFormState();

  useNetworkErrorMessage('myAccount.changePasswordModal.form.errors', errors);

  return (
    <Wrapper>
      <StyledTitle>{translate('myAccount.changePasswordModal.title')}</StyledTitle>

      <PasswordField
        label={translate('myAccount.changePasswordModal.form.fields.currentPassword')}
        name={'currentPassword'}
      />

      <PasswordField
        label={translate('myAccount.changePasswordModal.form.fields.newPassword')}
        name={'password'}
      />

      <PasswordField
        label={translate('myAccount.changePasswordModal.form.fields.repeatPassword')}
        name={'repeatPassword'}
      />

      <SideBySide>
        <ModalCancelButton onRequestClose={onRequestClose} />

        <StyledSubmitButton>{translate('common.actions.continue')}</StyledSubmitButton>
      </SideBySide>
    </Wrapper>
  );
}

/**
 * Export `ChangePasswordForm` component.
 */

export default ChangePasswordForm;

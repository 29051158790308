/**
 * Module dependencies.
 */

import { Document } from 'client/core/types/documents';
import { get } from 'lodash';

/**
 * Export `getAllDocuments`.
 */

export function getAllDocuments(state: unknown) {
  return get(state, 'documents');
}

/**
 * Export `getActiveDocument`.
 */

export function getActiveDocument(state: unknown) {
  const documents = Object.keys(getAllDocuments(state)).length ? getAllDocuments(state) : [];

  return documents.find(({ isActive }: Document) => isActive) || {};
}

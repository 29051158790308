/**
 * Module dependencies.
 */

import { Card, Type, color, units } from 'pmint-design-system';
import { theme } from 'styled-tools';
import React from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  dataCy: string;
  description: string;
  value: string | null;
};

/**
 * `StyledCard` styled component.
 */

const StyledCard = styled(Card)`
  align-items: center;
  border: 1px solid ${color('textColorLight')};
  border-radius: ${units(1)};
  padding: ${units(2)};
  text-align: center;
`;

/**
 * `StyledDescription` styled component.
 */

const StyledDescription = styled.div`
  ${theme('typography.styles.paragraph')};

  color: ${color('textColorLight')};
  font-weight: 400;
  margin-top: ${units(2)};
`;

/**
 * `DashboardInfoCard` component.
 */

function DashboardInfoCard({ dataCy, description, value }: Props): JSX.Element {
  return (
    <StyledCard data-cy={`dashboardInfoCards-${dataCy}`}>
      <Type.H5 color={color('bender6')}
        data-cy={`dashboardInfoCards-${dataCy}-value`}
        fontWeight={500}
      >
        {value}
      </Type.H5>

      <StyledDescription>{description}</StyledDescription>
    </StyledCard>
  );
}

/**
 * Export `DashboardInfoCard` component.
 */

export default DashboardInfoCard;


/**
 * Module dependencies.
 */

import type { FetchTransactionsType, Transaction } from 'client/core/types/transactions';
import { formatDate } from 'client/core/utils/formatter';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListRow from './list-row';
import React, { useCallback, useMemo, useState } from 'react';
import routes from 'core/routes';

/**
 * `Props` type.
 */

type Props = {
  handleFetchTransactions: (data: FetchTransactionsType) => void;
  transactions: Array<Transaction>;
};

/**
 * `TransactionsListContent` component.
 */

function TransactionsListContent({ handleFetchTransactions, transactions }: Props): JSX.Element {
  const history = useHistory();
  const [itemPage, setItemPage] = useState(1);
  const handleRowClick = useCallback(
    (id: string) => {
      history.push({
        pathname: routes.dashboard.transactionsDetails,
        state: {
          transactionId: id
        }
      });
    },
    [history]
  );

  const parsedTransactions = useMemo(() => transactions.map(transaction => ({
    ...transaction,
    createdAt: formatDate(transaction.createdAt, { hasTime: true })
  })), [transactions]);

  const handleNextEvent = useCallback(() => {
    const nextPage = itemPage + 1;

    setItemPage(nextPage);
    handleFetchTransactions({ number: nextPage.toString() });
  }, [handleFetchTransactions, itemPage]);

  return (
    <InfiniteScroll
      dataLength={parsedTransactions.length}
      hasMore
      height={'40vh'}
      loader={null}
      next={handleNextEvent}
    >
      {parsedTransactions.map(transaction => {
        const {
          amount,
          createdAt,
          currency,
          destinationAddress,
          id,
          type
        } = transaction;

        return (
          <ListRow
            amount={amount}
            createdAt={createdAt}
            currency={currency}
            destinationAddress={destinationAddress}
            handleRowClick={handleRowClick}
            id={id}
            key={id}
            type={type}
          />
        );
      })}
    </InfiniteScroll>
  );
}

/**
 * Export `TransactionsListContent` component.
 */

export default TransactionsListContent;

/**
 * Module dependencies.
 */

import { BlockchainProvidersConfig } from 'client/core/types/config';
import PublicMint from '@publicmint/publicmint-web3';
import config from 'config';

/**
 * Blockchain provider configs.
 */

const {
  publicMint,
  universalLedger: { chainId, http, ws }
} = config.get<BlockchainProvidersConfig>('blockchainProviders');

/**
 * Web3 instances.
 */

const customProvider = PublicMint.provider(chainId, 'universalLedger', http, ws);

const publicMintWeb3 = new PublicMint(publicMint, 'http');
const universalLedgerWeb3 = new PublicMint(customProvider, 'http');
const { USD: pmintUsd } = publicMintWeb3.pm.contracts.token;
const { USD: ulUsd } = universalLedgerWeb3.pm.contracts.token;

/**
 * Export `calculateTotalBalance`.
 */

export const calculateTotalBalance = async () => {
  const pmintTotalBalanceInWei = await pmintUsd.totalSupply().call();
  const universalLedgerTotalBalanceInWei = await ulUsd.totalSupply().call();
  const pmintTotalBalanceInWeiBN = new publicMintWeb3.utils.BN(pmintTotalBalanceInWei);
  const universalLedgerTotalBalanceInWeiBN = new publicMintWeb3.utils.BN(universalLedgerTotalBalanceInWei);
  const totalBalance = pmintTotalBalanceInWeiBN.add(universalLedgerTotalBalanceInWeiBN);

  return publicMintWeb3.utils.fromWei(totalBalance);
};

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchTotalBalanceType } from 'client/core/redux/action-types/fetch-total-balance';

/**
 * Export `fetchTotalBalance`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchTotalBalanceType
  })
);

/**
 * Module dependencies.
 */

import { Redirect, Route, useLocation } from 'react-router-dom';
import { getAuthenticationStatus } from 'client/core/redux/selectors/authentication';
import { useSelector } from 'react-redux';
import React, { ComponentType } from 'react';
import routes from 'core/routes';

/**
 * `Props` type.
 */

type Props = {
  children?: JSX.Element;
  component?: ComponentType<any>;
  exact?: boolean;
  path: string;
};

/**
 * `ConditionalRoute` component.
 */

function ConditionalRoute(props: Props): JSX.Element {
  const authenticationStatus = useSelector(getAuthenticationStatus);
  const { pathname: redirectUrl } = useLocation();

  if (authenticationStatus === 'authenticated') {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: routes.signIn,
        state: { redirectUrl }
      }}
    />
  );
}

/**
 * Export `ConditionalRoute` component.
 */

export default ConditionalRoute;

/**
 * Module dependencies.
 */

import type { Identity } from 'client/core/types/identities';
import { Select, Type, color, units } from 'pmint-design-system';
import {
  fetchIdentityVerifications,
  resetFetchIdentityVerifications
} from 'client/core/redux/actions/fetch-identity-verifications';

import { getIdentityVerifications } from 'client/core/redux/selectors/fetch-identity-verifications';
import { ifProp } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  activeIdentity: Identity;
  isSelected: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isSelected: boolean | undefined;
}>`
  display: ${ifProp('isSelected', 'block', 'none')};
`;

/**
 * `SlugWrapper` styled component.
 */

const SlugWrapper = styled.div`
  margin-bottom: ${units(4)};
  width: 30%;
`;

/**
 * `Details` styled component.
 */

const Details = styled.textarea`
  background-color: ${color('gray100')};
  min-height: ${units(50)};
  width: 100%;
`;

/**
 * `EmptyState` styled component.
 */

const EmptyState = styled(Type.Label).attrs({ as: 'p' })`
  border: 1px solid black;
  border-radius: ${units(1)};
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  padding: ${units(2)};
  text-align: center;
`;

/**
 * `KycDetails` component.
 */

function KycDetails({ activeIdentity, isSelected }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { id, verifications: providerStatus } = activeIdentity;
  const { translate } = useTranslate();
  const options = useMemo(() => {
    return Object.entries(providerStatus)
      .filter(verification => verification[1] !== null)
      .map(verification => ({
        label: translate(`identitiesListDetails.kycDetails.slugs.options.${verification[0]}`),
        value: verification[0]
      }));
  }, [providerStatus, translate]);

  const [selectedSlug, setSelectedSlug] = useState(options[0]?.value);
  const verifications = useSelector(getIdentityVerifications);
  const prettyVerification = JSON.stringify(verifications[selectedSlug], undefined, 2);
  const handleSlugChange = useCallback(({ target }: any) => {
    setSelectedSlug(target.value);
  }, []);

  useEffect(() => {
    dispatch(fetchIdentityVerifications(id));

    return () => {
      dispatch(resetFetchIdentityVerifications());
    };
  }, [dispatch, id]);

  return (
    <Wrapper isSelected={isSelected}>
      <SlugWrapper>
        <Select
          hideEmptyOption
          items={options}
          label={translate('identitiesListDetails.kycDetails.slugs.label')}
          name={'slugs'}
          onChange={handleSlugChange}
          value={selectedSlug}
        />
      </SlugWrapper>

      {options.length ? (
        <Details disabled
          value={prettyVerification}
        />
      ) : (
        <EmptyState>{translate('identitiesListDetails.kycDetails.emptyState')}</EmptyState>
      )}
    </Wrapper>
  );
}

/**
 * Export `KycDetails` component.
 */

export default KycDetails;

/**
 * Module dependencies.
 */

import { Icon, Link, Type, color, media, units } from 'pmint-design-system';
import React from 'react';
import logoutIcon from 'client/assets/svg/logout.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  icon: string;
  onLogout?: () => void;
  title: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  grid-column: 1 / -1;
  padding-bottom: ${units(1)};
  position: sticky;
  top: 0;
  z-index: 1;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  border-bottom: 1px solid ${color('gray300')};
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: auto min-content;
  padding: ${units(1.5)} ${units(3)};

  ${media.min('md')`
    padding-bottom: ${units(2.5)};
    padding-top: ${units(2.5)};
`}
`;

/**
 * Content style.
 */

const contentStyle = css`
  align-items: center;
  display: flex;
  overflow: hidden;
`;

/**
 * `LeftContent` styled component.
 */

const LeftContent = styled.div`
  ${contentStyle}

  justify-content: flex-start;
`;

/**
 * `RightContent` styled component.
 */

const RightContent = styled.div`
  ${contentStyle}

  justify-content: flex-end;
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 400;
  margin-left: ${units(1)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * `Header` component.
 */

function Header(props: Props): JSX.Element {
  const { icon, onLogout, title } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <ContentWrapper>
        <LeftContent>
          <StyledIcon color={color('textColorLight')}
            icon={icon}
            size={units(4)}
          />

          <Type.Paragraph
            as={'h1'}
            color={color('textColorLight')}
            fontWeight={400}
            lineHeight={'24px'}
            marginLeft={'10px'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            {title}
          </Type.Paragraph>
        </LeftContent>

        {onLogout && (
          <RightContent>
            <Icon color={color('textColorLight')}
              icon={logoutIcon}
              size={units(3)}
            />

            <StyledLink colorTheme={'secondary'}
              data-cy={'logoutButton'}
              onClick={onLogout}
            >
              {translate('common.actions.logout')}
            </StyledLink>
          </RightContent>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

/**
 * Export `Header` component.
 */

export default Header;


/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { rejectOperationType } from 'client/core/redux/action-types/reject-operation';

/**
 * Export `rejectOperation`.
 */

export default combineReducers(createRequestReducers({
  method: 'POST',
  type: rejectOperationType
}));

/**
 * Module dependencies.
 */

import { AnyAction } from 'redux';
import type { Identity } from 'client/core/types/identities';
import { fetchIdentitiesTypes } from 'client/core/redux/action-types/fetch-identities';
import { searchIdentitiesType } from 'client/core/redux/action-types/identities';
import { unionBy } from 'lodash';

/**
 * Identities.
 */

function identities(state: Array<Identity> = [], action: AnyAction): Array<Identity> {
  const { payload, type } = action ?? {};

  switch (type) {
    case fetchIdentitiesTypes.successType:
      return state ? unionBy(state, payload?.data, 'id') : payload?.data;

    case searchIdentitiesType:
      return state.filter(({ email, name }) => {
        return name.toLowerCase().includes(payload.searchWord) || email.toLowerCase().includes(payload.searchWord);
      });

    default:
      return state;
  }
}

/**
 * Export `identities`.
 */

export default identities;

/**
 * Module dependencies.
 */

import { Link, color, media, units } from 'pmint-design-system';
import { ifProp, theme } from 'styled-tools';
import React from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  dataCy?: string;
  isSelected: boolean;
  label: string;
  onSelectTab: (() => void) | undefined;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  color: ${color('textColorLight')};
  display: inline-block;
  font-weight: 400;
  padding-bottom: ${units(2.5)};
  position: relative;

  > a {
    color: inherit;
    text-decoration: none;

    ${media.min('md')`
      &:first-child {
        margin-left: ${units(3)};
      }

      &:not(:last-child) {
        margin-right: ${units(6)};
      }
    `}
  }

  ${media.max('md')`
    flex: 1;
  `}
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  margin-left: ${units(2)};
`;

/**
 * `Slide` styled component.
 */

const Slide = styled.div<{
  isSelected: boolean | undefined;
}>`
  border-bottom: 1px solid ${color('gray800')};
  bottom: -1px;
  position: absolute;
  transform: scaleX(0);

  ${ifProp(
    'isSelected',
    css`
      transform: scaleX(1);
      transform-origin: left center;
      transition: transform ${theme('transitions.defaultTransition')};
      width: 100%;
    `
  )}
`;

/**
 * `Tab` component.
 */

function Tab(props: Props): JSX.Element {
  const { dataCy, isSelected, label, onSelectTab } = props;

  return (
    <Wrapper aria-selected={isSelected}
      role={'tab'}
    >
      <StyledLink data-cy={dataCy}
        onClick={onSelectTab}
      >
        {label}
      </StyledLink>

      <Slide isSelected={isSelected} />
    </Wrapper>
  );
}

/**
 * Export `Tab` component.
 */

export default Tab;

/**
 * Module dependencies.
 */

import { AnalyticsConfig } from 'client/core/types/config';
import { filter, head } from 'lodash';
import { isProduction } from 'core/utils/environment';
import ReactGA from 'react-ga';
import config from 'config';

/**
 * Analytics ids.
 */

const { clientTrackingId, seegnoTrackingId } = config.get<AnalyticsConfig>('analytics');

/**
 * Trackers config.
 */

const trackersConfig = filter(
  [
    {
      id: clientTrackingId,
      name: 'client'
    },
    {
      id: seegnoTrackingId,
      name: 'seegno'
    }
  ],
  'id'
);

/**
 * Export `initializeAnalytics`.
 */

export const initializeAnalytics = () => {
  const firstTracker = head(trackersConfig);

  if (firstTracker) {
    ReactGA.initialize(firstTracker.id, { debug: !isProduction() });
  }

  trackersConfig.forEach(({ id, name }) => {
    ReactGA.ga('create', id, 'auto', name);
  });
};

/**
 * Export `sendPageToAnalytics`.
 */

export const sendPageToAnalytics = () => {
  const page = window.location.pathname;

  trackersConfig.forEach(({ name }) => {
    ReactGA.ga(`${name}.send`, 'pageview', page);
  });
};

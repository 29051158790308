/**
 * Module dependencies.
 */

import { Password } from 'pmint-design-system';
import { useField, useFormState } from '@seegno/react-forms';
import React, { ChangeEvent, useCallback } from 'react';
import dontSeeIcon from 'pmint-design-system/icons/dont-see-28px.svg';
import seeIcon from 'pmint-design-system/icons/see-28px.svg';
import useLabel from 'client/hooks/use-label';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  disabled?: boolean;
  label: string;
  name: string;
};

/**
 * `PasswordField` component.
 */

function PasswordField(props: Props): JSX.Element {
  const { disabled, label, name } = props;
  const { error, meta, onBlur, onChange, onFocus, value } = useField(name);

  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const { translate } = useTranslate();
  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(target.value);
    },
    [onChange]
  );

  const passwordLabel = useLabel(label, name);

  return (
    <Password
      disabled={isDisabled}
      dontSeeIcon={dontSeeIcon}
      hasError={hasError}
      hideAriaLabel={translate('common.fields.password.hideLabel')}
      label={passwordLabel}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      seeIcon={seeIcon}
      showAriaLabel={translate('common.fields.password.showLabel')}
      value={value}
    />
  );
}

/**
 * Export `PasswordField` component.
 */

export default PasswordField;

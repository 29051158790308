
/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/earn-operations';
import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchOperationsState`.
 */

export const getFetchOperationsState: RequestStateSelector = createRequestStateSelector('fetchOperations');

/**
 * Export `getOperations`.
 */

export function getOperations(state: unknown): Array<Operation> {
  return getFetchOperationsState(state)?.data || [];
}

/**
 * Module dependencies.
 */

import type { Identity } from 'client/core/types/identities';
import {
  IdentityReview,
  performIdentityReview
} from 'client/core/redux/actions/perform-identity-review';

import { Modal, Type, color, media, units } from 'pmint-design-system';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import FileInput from 'client/components/core/file-input';
import Form from 'client/components/core/forms/form';
import ModalCancelButton from 'client/components/core/modal/cancel-button';
import React, { useCallback } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  identity: Identity;
  isVisible: boolean;
  onRequestClose: () => void;
  reviewOrder: string;
};

/**
 * Browse schema.
 */

const browseSchema = {
  properties: {
    document: { type: 'object' }
  },
  required: ['document']
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: 30px ${units(2.5)};

  ${media.min('xs')`
    padding-left: ${units(4)};
    padding-right: ${units(4)};
  `}

  ${media.min('xl')`
    padding: 50px ${units(15)} ${units(10)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H5).attrs({ as: 'h1' })`
  color: ${color('textColor')};
  font-weight: 500;
  margin-bottom: 50px;
  text-align: center;
`;

/**
 * `Name` styled component.
 */

const Name = styled(Type.H5).attrs({ as: 'h2' })`
  color: ${color('textColor')};
  margin-bottom: 34px;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 34px;
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  margin-top: ${units(5)};

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)<{
  color?: string;
}>`
  background-color: ${props => props.color ?? 'black'};
  border-color: ${props => props.color ?? 'black'};
  min-height: ${units(8)};
`;

/**
 * `ReviewIdentityModal` component.
 */

function ReviewIdentityModal(props: Props): JSX.Element {
  const {
    identity: { id: identityId, name },
    isVisible,
    onRequestClose,
    reviewOrder
  } = props;

  const dispatch = useDispatch();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const onSubmit = useCallback(
    async ({ document }: IdentityReview) => {
      try {
        await dispatch(performIdentityReview({ document, identityId, order: reviewOrder }));

        showSuccessMessage(translate('identitiesListDetails.modals.reviewIdentity.success'));
        onRequestClose();
      } catch (error) {
        showErrorMessage(translate('identitiesListDetails.modals.reviewIdentity.error'));
      }
    },
    [dispatch, identityId, onRequestClose, reviewOrder, showErrorMessage, showSuccessMessage, translate]
  );

  return (
    <Modal isVisible={isVisible}
      onRequestClose={onRequestClose}
    >
      <Form jsonSchema={browseSchema}
        onSubmit={onSubmit}
      >
        <Wrapper>
          <Title>{translate(`identitiesListDetails.modals.reviewIdentity.${reviewOrder}.title`)}</Title>

          <Name>{name}</Name>

          <Description>
            {translate(`identitiesListDetails.modals.reviewIdentity.${reviewOrder}.description`)}
          </Description>

          <FileInput isModalVisible={isVisible}
            name={'document'}
          />

          <SideBySide>
            <ModalCancelButton onRequestClose={onRequestClose} />

            <StyledSubmitButton>
              {translate('common.actions.continue')}
            </StyledSubmitButton>
          </SideBySide>
        </Wrapper>
      </Form>
    </Modal>
  );
}

/**
 * Export `ReviewIdentityModal` component.
 */

export default ReviewIdentityModal;

/**
 * Module dependencies.
 */

import { Button, Loader, color, states, units } from 'pmint-design-system';
import { Identity, VerificationStatus } from 'client/core/types/identities';
import { getReopenIdentityReviewState } from 'client/core/redux/selectors/reopen-identity-review';
import { reopenIdentityReview, resetReopenIdentityReview } from 'client/core/redux/actions/reopen-identity-review';
import { theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import ModalPortal from 'client/components/core/modal/modal-portal';
import React, { useCallback, useEffect, useState } from 'react';
import ReviewButtons from './review-buttons';
import ReviewIdentityModal from './review-identity-modal';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  identity: Identity;
};

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  background-color: ${color('bender2')};
  border: 1px solid ${color('bender2')};
  border-radius: ${units(1)};
  box-shadow: none;
  color: ${color('white')};
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: ${units(2)};
  max-width: ${units(40)};
  min-height: ${units(8)};
  outline: 0;
  padding: ${units(1.5)} ${units(3)};
  text-align: center;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color, color;
  width: 100%;

  ${states.interaction`
    background-color: ${color('bender3')};
    border-color: ${color('bender3')};
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Label` styled component.
 */

const Label = styled.div`
  flex: 1;
  text-align: center;
`;

/**
 * `ReviewIdentity` component.
 */

function ReviewIdentity({ identity }: Props): JSX.Element {
  const { id, verifications } = identity;
  const dispatch = useDispatch();
  const [isReviewIdentityModalVisible, setIsReviewIdentityModalVisible] = useState(false);
  const [reviewOrder, setReviewOrder] = useState<VerificationStatus | ''>('');
  const handleReviewIdentity = useCallback((order: VerificationStatus) => {
    setIsReviewIdentityModalVisible(true);
    setReviewOrder(order);
  }, []);

  const handleRequestClose = useCallback(() => {
    setIsReviewIdentityModalVisible(false);
  }, []);

  const history = useHistory();
  const { translate } = useTranslate();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { isLoading: reopenReviewLoading } = useSelector(getReopenIdentityReviewState);
  const handleReopenIdentityReview = useCallback(() => {
    return dispatch(reopenIdentityReview(id))
      .then(() => {
        showSuccessMessage(translate('identitiesListDetails.reopenReview.successMessage'));

        history.push(routes.dashboard.identitiesList);
      })
      .catch(() => showErrorMessage(translate('identitiesListDetails.errors.network.reopenReview')));
  }, [dispatch, history, id, showErrorMessage, showSuccessMessage, translate]);

  useEffect(() => {
    return () => {
      dispatch(resetReopenIdentityReview());
    };
  }, [dispatch]);

  if (verifications?.publicMint === 'pending') {
    return (
      <>
        <ReviewButtons handleReviewIdentity={handleReviewIdentity} />

        <ModalPortal isOpen={isReviewIdentityModalVisible}>
          <ReviewIdentityModal
            identity={identity}
            isVisible={isReviewIdentityModalVisible}
            onRequestClose={handleRequestClose}
            reviewOrder={reviewOrder}
          />
        </ModalPortal>
      </>
    );
  }

  return (
    <>
      <StyledButton data-cy={'identitiesListDetails-reopenReview-button'}
        onClick={handleReopenIdentityReview}
      >
        <ContentWrapper>
          <Label>{translate('identitiesListDetails.reopenReview.button')}</Label>

          {reopenReviewLoading && <Loader />}
        </ContentWrapper>
      </StyledButton>
    </>
  );
}

/**
 * Export `ReviewIdentity` component.
 */

export default ReviewIdentity;

/**
 * Module dependencies.
 */

import { Redirect } from 'react-router-dom';
import { getAuthenticationStatus } from 'client/core/redux/selectors/authentication';
import { useSelector } from 'react-redux';
import React from 'react';
import routes from 'core/routes';

/**
 * `Home` container.
 */

function Home(): JSX.Element {
  const authenticationStatus = useSelector(getAuthenticationStatus);

  if (authenticationStatus === 'authenticated') {
    return <Redirect to={routes.dashboard.base} />;
  }

  return <Redirect to={routes.signIn} />;
}

/**
 * Export `Home` container.
 */

export default Home;

/**
 * Module dependencies.
 */

import { Container, media } from 'pmint-design-system';
import React, { ReactNode } from 'react';
import Sidebar from './sidebar';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  sidebarContent: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'sidebar'
    'content';
  grid-template-rows: auto 1fr;
  min-height: 100vh;

  ${media.min('md')`
    height: 100vh;
    grid-template-areas: "sidebar content";
    grid-template-columns: 33.5% 66.5%;
    grid-template-rows: auto;
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;

  ${media.min('md')`
    overflow-y: auto;
  `}
`;

/**
 * `Main` styled component.
 */

const Main = styled.main`
  flex: 1;
`;

/**
 * `PageLayout` component.
 */

function PageLayout(props: Props): JSX.Element {
  const { children, sidebarContent } = props;

  return (
    <Container>
      <Wrapper>
        <Sidebar>
          {sidebarContent}
        </Sidebar>

        <ContentWrapper>
          <Main>
            {children}
          </Main>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
}

/**
 * Export `PageLayout` component.
 */

export default PageLayout;

/**
 * Module dependencies.
 */

import { Dispatch } from 'redux';
import { fetchIdentityById } from 'client/core/redux/actions/fetch-identity-by-id';
import { fetchPresignUrl } from 'client/core/redux/actions/fetch-presign-url';
import { reviewIdentity } from 'client/core/redux/actions/review-identity';
import { uploadDocument } from 'client/core/utils/upload-document';

/**
 * Export `IdentityReview` type.
 */

export type IdentityReview = {
  document: File;
  identityId: string;
  order: string;
};

/**
 * Export `performIdentityReview`.
 */

export const performIdentityReview = (values: IdentityReview) => async (dispatch: Dispatch) => {
  const { document: file, identityId, order } = values;

  const { name: fileName } = file;
  const {
    value: { data }
  } = await dispatch(
    fetchPresignUrl({
      fileName,
      identityId,
      isComplianceDocument: true
    })
  );

  await uploadDocument({ ...data, file });
  await dispatch(reviewIdentity({ identityId, order }));

  return dispatch(fetchIdentityById(identityId));
};

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { signInType } from 'client/core/redux/action-types/sign-in';

/**
 * Export `signIn`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'POST',
    type: signInType
  })
);

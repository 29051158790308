/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { patchIdentityType } from 'client/core/redux/action-types/patch-identity';

/**
 * Export `patchIdentity`.
 */

export function patchIdentity(identityId: string, data: any) {
  return createRequestAction({
    data,
    endpoint: {
      name: 'patchIdentity',
      params: { identityId }
    },
    handleResponse: (response: any) => response.data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'PATCH',
    type: patchIdentityType
  });
}

/**
 * Export `resetPatchIdentity`.
 */

export const resetPatchIdentity = createResetRequestAction({
  method: 'PATCH',
  type: patchIdentityType
});

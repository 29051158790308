/**
 * Module dependencies.
 */

import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { AppError } from 'client/core/types/error';
import { handleError as baseHandleError } from 'client/core/redux/utils/errors';
import { get, isEmpty, merge, noop } from 'lodash';
import { getAuthenticationToken } from 'client/core/redux/selectors/authentication';
import { getAuthorizationHeader } from 'client/core/utils/headers';
import { refreshToken } from 'client/core/redux/actions/refresh-token';

/**
 * Export `authMiddleware`.
 */

export default ({ dispatch, getState }: MiddlewareAPI) => {
  return (next: Dispatch<any>) => (action: AnyAction) => {
    if (!get(action, 'meta.request') || !get(action, 'meta.auth.isAuthenticated')) {
      return next(action);
    }

    const handleError = get(action, 'meta.request.handleError', baseHandleError);
    const getRequestHeaders = get(action, 'meta.request.getHeaders', noop);
    const getHeaders = () => {
      return Promise.resolve(getRequestHeaders()).then(headers => ({
        ...headers,
        ...getAuthorizationHeader(getAuthenticationToken(getState()))
      }));
    };

    return next(
      merge({}, action, {
        meta: {
          request: {
            getHeaders,
            handleError: (error: AppError) => {
              if (!isEmpty(error.response)) {
                const { code } = error.response.data;

                if (code === 'access_denied') {
                  return dispatch(refreshToken())
                    .then(() =>
                      next(
                        merge({}, action, {
                          meta: {
                            request: {
                              getHeaders
                            }
                          }
                        })
                      )
                    )
                    .then((result: unknown) => get(result, 'value'));
                }
              }

              return handleError(error);
            }
          }
        }
      })
    );
  };
};


/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `fetchTransactionsType`.
 */

export const fetchTransactionsType = 'FETCH_TRANSACTIONS';

/**
 * Export `fetchTransactionsTypes`.
 */

export const fetchTransactionsTypes = createRequestActionTypes({
  type: fetchTransactionsType
});

/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchIdentityByIdType } from 'client/core/redux/action-types/fetch-identity-by-id';

/**
 * Export `fetchIdentityById`.
 */

export const fetchIdentityById = (identityId: string) => {
  return createRequestAction({
    endpoint: {
      name: 'identityById',
      params: { id: identityId }
    },
    handleResponse: ({ data }: any) => data,
    type: fetchIdentityByIdType
  });
};

/**
 * Export `resetFetchIdentityById`.
 */

export const resetFetchIdentityById = createResetRequestAction({
  type: fetchIdentityByIdType
});

/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getReopenIdentityReviewState`.
 */

export const getReopenIdentityReviewState: RequestStateSelector = createRequestStateSelector('reopenIdentityReview');

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';
import { get } from 'lodash';

/**
 * Export `getFetchUserRolesState`.
 */

export const getFetchUserRolesState = createRequestStateSelector('fetchUserRoles');

/**
 * Export `getUserRoles`.
 */

export const getUserRoles = (state: unknown) => {
  return get(getFetchUserRolesState(state), 'data') || {};
};

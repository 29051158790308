/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchIdentityByIdType } from 'client/core/redux/action-types/fetch-identity-by-id';

/**
 * Export `fetchIdentityById`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchIdentityByIdType
  })
);

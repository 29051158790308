/**
 * Module dependencies.
 */

import { SnackbarContext } from './snackbar-context';
import { Transition, TransitionGroup, TransitionStatus } from 'react-transition-group';
import { get, reject } from 'lodash';
import { media, themeProp, units } from 'react-components/styles';
import { switchProp } from 'styled-tools';
import React, { ReactNode, useCallback, useMemo, useReducer } from 'react';
import SnackbarContent from './snackbar-content';
import styled, { css } from 'styled-components';

/**
 * `State` type.
 */

type State = {
  messages: Array<{
    content: string;
    id: number;
    options?: Record<string, any>;
  }>;
  total: number;
};

/**
 * `Options` type.
 */

type Options = {
  payload: any;
  type: string;
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * Action types.
 */

const actionTypes = {
  addMessage: 'ADD_MESSAGE',
  removeMessage: 'REMOVE_MESSAGE'
};

/**
 * Reducer.
 */

const reducer = (state: State, options: Options) => {
  const { payload, type } = options;

  switch (type) {
    case actionTypes.addMessage: {
      const total = state.total + 1;

      return {
        messages: [
          ...state.messages,
          {
            ...get(payload, 'message'),
            id: total
          }
        ],
        total
      };
    }

    case actionTypes.removeMessage:
      return {
        messages: reject(state.messages, payload),
        total: state.total - 1
      };

    default:
      return state;
  }
};

/**
 * State time.
 */

const stateTime = 500;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  bottom: ${units(2)};
  left: ${units(2)};
  max-width: ${units(56)};
  position: fixed;
  right: ${units(2)};
  z-index: ${themeProp('zIndex.snackbar')};

  ${media.min('md')`
    max-width: calc(33.5% - 32px);
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div<{
  state: TransitionStatus;
}>`
  transition: opacity ${themeProp('animations.defaultTransition')};

  &:not(:last-child) {
    margin-bottom: ${units(2)};
  }

  ${switchProp('state', {
    entered: css`
      opacity: 1;
    `,
    entering: css`
      opacity: 1;
    `,
    exited: css`
      opacity: 0;
    `,
    exiting: css`
      opacity: 0;
    `
  })}
`;

/**
 * `SnackbarProvider` container.
 */

function SnackbarProvider(props: Props): JSX.Element {
  const { children } = props;
  const [{ messages }, dispatch] = useReducer(reducer, { messages: [], total: 0 });
  const showMessage = useCallback((content: string, options: Record<string, any>) => {
    dispatch({
      payload: {
        message: { content, options }
      },
      type: actionTypes.addMessage
    });
  }, []);

  const removeMessage = useCallback((id: number) => {
    dispatch({
      payload: { id },
      type: actionTypes.removeMessage
    });
  }, []);

  const value = useMemo(
    () => ({
      showErrorMessage: (content: string, options?: Record<string, any>) => {
        showMessage(content, { ...options, type: 'error' });
      },
      showInfoMessage: (content: string, options?: Record<string, any>) => {
        showMessage(content, { ...options, type: 'info' });
      },
      showSuccessMessage: (content: string, options?: Record<string, any>) => {
        showMessage(content, { ...options, type: 'success' });
      },
      showWarningMessage: (content: string, options?: Record<string, any>) => {
        showMessage(content, { ...options, type: 'warning' });
      }
    }),
    [showMessage]
  );

  return (
    <SnackbarContext.Provider value={value}>
      <>
        {children}

        <Wrapper>
          <TransitionGroup>
            {messages.map(({ content, id, options }) => (
              <Transition key={id}
                timeout={stateTime}
              >
                {state => (
                  <ContentWrapper state={state}>
                    <SnackbarContent id={id}
                      key={id}
                      onDismiss={removeMessage}
                      options={options}
                    >
                      {content}
                    </SnackbarContent>
                  </ContentWrapper>
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </Wrapper>
      </>
    </SnackbarContext.Provider>
  );
}

/**
 * Export `SnackbarProvider` container.
 */

export default SnackbarProvider;

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchIdentitiesType } from 'client/core/redux/action-types/fetch-identities';

/**
 * Export `fetchIdentities`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchIdentitiesType
  })
);

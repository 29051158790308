
/**
 * Module dependencies.
 */

import { approveTransactionType } from 'client/core/redux/action-types/approve-transaction';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';

/**
 * Export `approveTransaction`.
 */

export const approveTransaction = (appId: string, transactionId: string) => {
  return createRequestAction({
    data: { appId },
    endpoint: {
      name: 'approveTransaction',
      params: { id: transactionId }
    },
    handleResponse: (response: any) => response.data,
    method: 'POST',
    type: approveTransactionType

  });
};

/**
 * Export `resetApproveTransaction`.
 */

export const resetApproveTransaction = createResetRequestAction({
  type: approveTransactionType
});

/**
 * Module dependencies.
 */

import { Icon, Link, color, units } from 'pmint-design-system';
import React from 'react';
import backArrowIcon from 'client/assets/svg/arrow-back.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  params?: {
    searchWord?: string;
    selectedFilter?: {
      label: string;
      value: string;
    };
  };
  to: string;
};

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: start;
  text-decoration: none;
  text-transform: uppercase;
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  background-color: ${color('white')};
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(105, 122, 137, 0.05), 0 3px 8px 0 rgba(105, 122, 137, 0.1),
    0 0 1px 0 rgba(105, 122, 137, 0.5);
  height: ${units(2.5)};
  margin-right: ${units(1.75)};
  width: ${units(2.5)};
`;

/**
 * `BackLink` component.
 */

function BackLink({ params, to }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <StyledLink colorTheme={'secondary'}
      size={'small'}
      to={{ pathname: to, state: params }}
    >
      <StyledIcon icon={backArrowIcon} />

      {translate('common.actions.back')}
    </StyledLink>
  );
}

/**
 * Export `BackLink` component.
 */

export default BackLink;

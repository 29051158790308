
/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-bottom: 1px solid ${color('gray600')};
  display: grid;
  grid-column-gap: ${units(1.75)};
  grid-template-columns: 2fr 1fr 2fr 2fr 3fr;
  grid-template-rows: auto;
  justify-items: start;

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(1)} 0;
  `}
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 500;
  line-height: 24px;
  margin-left: ${units(1)};
`;

/**
 * `ListHeader` component.
 */

function ListHeader(): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper data-cy={'operations-header'}>
      <Value>
        {translate('operations.listItems.amount')}
      </Value>

      <Value>
        {translate('operations.listItems.partner')}
      </Value>

      <Value>
        {translate('operations.listItems.type')}
      </Value>

      <Value>
        {translate('operations.listItems.status')}
      </Value>

      <Value>
        {translate('operations.listItems.createdAt')}
      </Value>
    </Wrapper>
  );
}

/**
 * Export `ListHeader` component.
 */

export default ListHeader;

/**
 * Module dependencies.
 */

import { dropWhile, takeWhile } from 'lodash';

/**
 * Is big number.
 */

function isBigNumber(value: string | number) {
  return typeof value === 'string' && value.length > 16;
}

/**
 * `Options` type.
 */

type Options = {
  currency?: string;
  decimalPlaces?: number;
  locale: string;
};

/**
 * `FormatDateOptions` type.
 */

type FormatDateOptions = {
  hasTime: boolean;
};

/**
 * Export `formatNumber`.
 */

export function formatNumber(value: string | number, options: Options) {
  const { currency, decimalPlaces, locale } = options;

  if (typeof Intl === 'undefined') {
    if (currency) {
      return `${currency} ${value}`;
    }

    return value;
  }

  const formatter = new Intl.NumberFormat(locale, {
    currency,
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: 2,
    style: currency ? 'currency' : 'decimal'
  });

  if (!formatter.formatToParts) {
    if (isBigNumber(value)) {
      if (currency) {
        return `${currency} ${value}`;
      }

      return value;
    }

    return formatter.format(parseFloat(String(value)));
  }

  if (typeof value === 'number' || !isBigNumber(value)) {
    return formatter.formatToParts(parseFloat(String(value)));
  }

  const [integer, decimal] = value.split('.');
  const integerParts = formatter.formatToParts(parseFloat(integer));
  const decimalParts = formatter.formatToParts(parseFloat(`0.${decimal}`));

  return [
    ...takeWhile(integerParts, ({ type }) => type !== 'decimal'),
    ...dropWhile(decimalParts, ({ type }) => type !== 'decimal')
  ];
}

/**
 * Export `formatDate`.
 */

export function formatDate(value: string, { hasTime }: FormatDateOptions) {
  const date = new Date(value);
  const sliceValue = hasTime ? 19 : 10;

  return date.toISOString().slice(0, sliceValue).replace('T', ' ');
}

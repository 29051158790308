/**
 * Module dependencies.
 */

import { AppError } from 'client/core/types/error';
import { getNetworkErrorPaths } from 'client/core/utils/errors';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import useTranslate from './use-translate';

/**
 * `useNetworkErrorMessage` hook.
 */

const useNetworkErrorMessage = (basePath: string, error: AppError | undefined) => {
  const { translate } = useTranslate();
  const { showErrorMessage } = useSnackbar();

  if (error) {
    const errorMessage = translate(getNetworkErrorPaths(basePath, error.code));

    return showErrorMessage(errorMessage);
  }
};

/**
 * Export `useNetworkErrorMessage` hook.
 */

export default useNetworkErrorMessage;


/**
 * Module dependencies.
 */

import type { Partner } from 'client/core/types/partners';
import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchPartnerState`.
 */

export const getFetchPartnerState: RequestStateSelector = createRequestStateSelector('fetchPartner');

/**
 * Export `getPartnerInformation`.
 */

export function getPartnerInformation(state: unknown): Partner {
  return getFetchPartnerState(state)?.data?.[0] || {};
}

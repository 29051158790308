
/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/earn-operations';
import ListContent from './list-content';
import ListEmptyState from 'client/components/core/lists/empty-state';
import ListHeader from './list-header';
import React from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

 type Props = {
  operations: Array<Operation>;
};

/**
 * `List` component.
 */

function List({ operations }: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <>
      {operations.length ? (
        <>
          <ListHeader />

          <ListContent operations={operations} />
        </>
      ) : (
        <ListEmptyState
          dataCy={'operations-emptyState'}
          description={translate('operations.listEmptyState')}
        />
      )}
    </>
  );
}

/**
 * Export `List` component.
 */

export default List;

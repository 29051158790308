/**
 * Module dependencies.
 */

import type { Address } from 'client/core/types/identities';
import { Type, media, units } from 'pmint-design-system';
import FieldValue from 'client/components/core/field-value';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  homeInformation: Address;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `SideBySideCountryInformation` styled component.
 */

const SideBySideCountryInformation = styled.div`
  display: grid;

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `SideBySideCityInformation` styled component.
 */

const SideBySideCityInformation = styled.div`
  display: grid;

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `HomeInformation` component.
 */

function HomeInformation({ homeInformation }: Props): JSX.Element {
  const { city, country, postalCode, state, street } = homeInformation;

  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Title>{translate('identitiesListDetails.homeInformation.title')}</Title>

      <SideBySideCountryInformation>
        <FieldValue
          data-cy={'identitiesListDetails-homeInformation-country'}
          label={translate('identitiesListDetails.homeInformation.labels.country')}
        >
          {country}
        </FieldValue>

        <FieldValue
          data-cy={'identitiesListDetails-homeInformation-state'}
          label={translate('identitiesListDetails.homeInformation.labels.state')}
        >
          {state}
        </FieldValue>
      </SideBySideCountryInformation>

      <FieldValue
        data-cy={'identitiesListDetails-homeInformation-address'}
        label={translate('identitiesListDetails.homeInformation.labels.address')}
      >
        {street}
      </FieldValue>

      <SideBySideCityInformation>
        <FieldValue
          data-cy={'identitiesListDetails-homeInformation-city'}
          label={translate('identitiesListDetails.homeInformation.labels.city')}
        >
          {city}
        </FieldValue>

        <FieldValue
          data-cy={'identitiesListDetails-homeInformation-zipCode'}
          label={translate('identitiesListDetails.homeInformation.labels.zipCode')}
        >
          {postalCode}
        </FieldValue>
      </SideBySideCityInformation>
    </Wrapper>
  );
}

/**
 * Export `HomeInformation` component.
 */

export default HomeInformation;

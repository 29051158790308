/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getLogoutState`.
 */

export const getLogoutState: RequestStateSelector = createRequestStateSelector('logout');

/**
 * Module dependencies.
 */

import { getLogoutState } from 'client/core/redux/selectors/logout';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import useTranslate from 'client/hooks/use-translate';

/**
 * `LogoutListener` container.
 */

function LogoutListener(): null {
  const { isSuccessful } = useSelector(getLogoutState);
  const { showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();

  useEffect(() => {
    if (isSuccessful) {
      showSuccessMessage(translate('logoutMessage'));
    }
  }, [isSuccessful, showSuccessMessage, translate]);

  return null;
}

/**
 * Export `LogoutListener` container.
 */

export default LogoutListener;


/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `postUserPasswordType`.
 */

export const postUserPasswordType = 'POST_USER_PASSWORD';

/**
 * Export `postUserPasswordTypes`.
 */

export const postUserPasswordTypes = createRequestActionTypes({
  method: 'POST',
  type: postUserPasswordType
});

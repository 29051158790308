/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  createdAt: string;
  id: string;
  status: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row-gap: ${units(1)};

  ${media.min('lg')`
    grid-template-columns: repeat(2, auto);
  `}

  ${media.min('xl')`
    grid-template-columns: repeat(3, auto);
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Label)`
  color: ${color('textColor')};
  font-weight: 500;
  margin-right: ${units(1)};
`;

/**
 * `TextContent` styled component.
 */

const TextContent = styled(Type.Paragraph)`
  font-weight: 400;
`;

/**
 * `UacManagementDetailsUacInformation` component.
 */

function UacManagementDetailsUacInformation(props: Props): JSX.Element {
  const { createdAt, id, status } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <ContentWrapper>
        <Label>
          {translate('uacManagementDetails.uacInformation.id')}
        </Label>

        <TextContent data-cy={'uacManagementDetails-uacInformation-id'}>
          {id}
        </TextContent>
      </ContentWrapper>

      <ContentWrapper>
        <Label>
          {translate('uacManagementDetails.uacInformation.createdAt')}
        </Label>

        <TextContent data-cy={'uacManagementDetails-uacInformation-createdAt'}>
          {createdAt}
        </TextContent>
      </ContentWrapper>

      <ContentWrapper>
        <Label>
          {translate('uacManagementDetails.uacInformation.status')}
        </Label>

        <TextContent data-cy={'uacManagementDetails-uacInformation-status'}>
          {status}
        </TextContent>
      </ContentWrapper>
    </Wrapper>
  );
}

/**
 * Export `UacManagementDetailsUacInformation` component.
 */

export default UacManagementDetailsUacInformation;

/**
 * Module dependencies.
 */

import type { FetchIdentitiesType, Identity } from 'client/core/types/identities';
import { Type, color, media, units } from 'pmint-design-system';
import { formatDate } from 'client/core/utils/formatter';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useCallback, useState } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  handleFetchIdentities: (data: FetchIdentitiesType) => void;
  identities: Array<Identity>;
  searchWord?: string;
  selectedFilter?: {
    label: string;
    value: string;
  };
};

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-gap: ${units(0.5)} ${units(1.75)};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  overflow-wrap: anywhere;
  padding: ${units(1)} 0;

  ${media.min('xs')`
    grid-template-columns: 0.4fr 0.4fr 0.2fr;
  `}

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(2)} 0;
  `}

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }

  &:hover,
  &:focus {
    background-color: ${color('gray100')};
  }
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  margin-left: ${units(1)};
`;

/**
 * Parse identities.
 */

const parseIdentities = (identities: Identity[]) =>
  identities.map(identity => ({
    ...identity,
    createdAt: formatDate(identity.createdAt, { hasTime: true })
  }));

/**
 * `IdentitiesListContent` component.
 */

function IdentitiesListContent({ handleFetchIdentities, identities, searchWord, selectedFilter }: Props): JSX.Element {
  const history = useHistory();
  const [itemPage, setItemPage] = useState(1);
  const handleRowClick = useCallback(
    (id: string) => {
      history.push({
        pathname: routes.dashboard.identitiesListDetails,
        state: {
          identityId: id,
          searchWord,
          selectedFilter
        }
      });
    },
    [history, searchWord, selectedFilter]
  );

  const parsedIdentities = parseIdentities(identities);
  const handleNextEvent = useCallback(() => {
    const nextPage = itemPage + 1;

    setItemPage(nextPage);
    handleFetchIdentities({ number: nextPage.toString() });
  }, [handleFetchIdentities, itemPage]);

  return (
    <InfiniteScroll
      dataLength={parsedIdentities.length}
      hasMore
      height={'40vh'}
      loader={null}
      next={handleNextEvent}
    >
      {parsedIdentities.map(identity => {
        const { createdAt, email, id, name } = identity;

        return (
          <RowWrapper key={email}
            onClick={() => handleRowClick(id)}
          >
            <Value data-cy={'identitiesList-content-name'}>{name}</Value>

            <Value data-cy={'identitiesList-content-email'}>{email}</Value>

            <Value data-cy={'identitiesList-content-createdAt'}>{createdAt}</Value>
          </RowWrapper>
        );
      })}
    </InfiniteScroll>
  );
}

/**
 * Export `IdentitiesListContent` component.
 */

export default IdentitiesListContent;

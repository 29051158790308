
/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchOperationDocumentsType } from 'client/core/redux/action-types/fetch-operation-documents';

/**
 * Export `fetchOperationDocuments`.
 */

export default combineReducers(createRequestReducers({
  type: fetchOperationDocumentsType
}));

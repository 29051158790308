
/**
 * Module dependencies.
 */

import { Box, media, units } from 'pmint-design-system';
import { Redirect, useLocation } from 'react-router-dom';
import { fetchOperation, resetFetchOperation } from 'client/core/redux/actions/fetch-operation';
import { fetchOperationDocuments, resetFetchOperationDocuments } from 'client/core/redux/actions/fetch-operation-documents';
import { get } from 'lodash';
import { getFetchOperationState, getOperation } from 'client/core/redux/selectors/fetch-operation';
import { getOperationDocuments } from 'client/core/redux/selectors/fetch-operation-documents';
import { useDispatch, useSelector } from 'react-redux';
import BackLink from 'client/components/core/back-link';
import DocumentsContent from 'client/components/dashboard/operation-details/documents-content';
import InformationContent from 'client/components/dashboard/operation-details/information-content';
import Loading from 'client/components/core/loading';
import PartnerDetails from 'client/components/dashboard/operation-details/partner-details';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Tabs from 'client/components/core/tabs';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `InformationType` type.
 */

type InformationType = 'documents' | 'information';

/**
 * Information types.
 */

const informationTypes: { [key: string]: InformationType } = {
  documents: 'documents',
  information: 'information'
};

/**
 * `BackWrapper` styled component.
 */

const BackWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-bottom: ${units(3)};
  margin-left: ${units(3)};
`;

/**
 * `StyledTabs` styled component.
 */

const StyledTabs = styled(Tabs)`
  display: flex;
  grid-column: 2 / -2;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin: 0 ${units(3)};
  `}
`;

/**
 * `OperationDetails` container.
 */

function OperationDetails(): JSX.Element {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [informationType, setInformationType] = useState<InformationType>(informationTypes.information);
  const handleInformationClick = useCallback(() => {
    setInformationType(informationTypes.information);
  }, []);

  const handleDocumentsClick = useCallback(() => {
    setInformationType(informationTypes.documents);
  }, []);

  const tabItems = useMemo(() => [{
    dataCy: 'operationDetails-tab-information',
    id: 'information',
    isSelected: informationType === informationTypes.information,
    label: translate('operationDetails.tabs.information'),
    onSelectTab: handleInformationClick
  }, {
    dataCy: 'operationDetails-tab-documents',
    id: 'documents',
    isSelected: informationType === informationTypes.documents,
    label: translate('operationDetails.tabs.documents'),
    onSelectTab: handleDocumentsClick
  }]
  , [
    handleDocumentsClick,
    handleInformationClick,
    informationType,
    translate
  ]);

  const {
    errors,
    isFailed,
    isLoading
  } = useSelector(getFetchOperationState);

  const operationDocuments = useSelector(getOperationDocuments);
  const operation = useSelector(getOperation);
  const {
    amount,
    metadata,
    partner,
    type
  } = operation;

  const { state: locationState } = useLocation();
  const { operationId: queryOperationId } = useQueryString();
  const operationId = queryOperationId || get(locationState, 'operationId');
  const backLink = get(locationState, 'to') || routes.dashboard.operations;
  const isManualPartner = partner === 'abra' || partner === 'celsius' && type === 'receive';
  const operationValues = useMemo(() => {
    return Object.keys(operation).filter(key => key !== 'metadata').map(key => {
      const translatedValues = [
        'partner',
        'status',
        'type'
      ];

      if (!translatedValues.includes(key)) {
        return {
          label: translate([`operationDetails.labels.${key}`, 'operationDetails.labels.unknown']),
          value: get(operation, key)
        };
      }

      return {
        label: translate([`operationDetails.labels.${key}`, 'operationDetails.labels.unknown']),
        value: translate([`operationDetails.listValues.${get(operation, key)}`, 'operationDetails.listValues.unknown'])
      };
    });
  }, [operation, translate]);

  useEffect(() => {
    dispatch(fetchOperation(operationId));
    dispatch(fetchOperationDocuments(operationId));

    return () => {
      dispatch(resetFetchOperation());
      dispatch(resetFetchOperationDocuments());
    };
  }, [dispatch, operationId]);

  useNetworkErrorMessage('operationDetails.errors', errors);

  if (isFailed || !operationId) {
    return <Redirect to={backLink} />;
  }

  if (!Object.keys(operation).length || isLoading) {
    return <Loading isVisible />;
  }

  return (
    <>
      <BackWrapper>
        <BackLink to={backLink} />
      </BackWrapper>

      {!isManualPartner ? <PartnerDetails operationValues={operationValues} /> : (
        <>
          <StyledTabs items={tabItems} />

          <Box gridColumn={'2 / -2'}>
            <InformationContent
              amount={amount}
              isActionButtonsDisabled={get(metadata, 'processedInBackoffice', undefined)}
              isSelected={informationType === informationTypes.information}
              operationId={operationId}
              operationValues={operationValues}
              partner={partner}
              type={type}
            />

            <DocumentsContent
              isSelected={informationType === informationTypes.documents}
              operationDocuments={operationDocuments}
              operationId={operationId}
            />
          </Box>
        </>
      )
      }
    </>
  );
}

/**
 * Export `OperationDetails` container.
 */

export default OperationDetails;

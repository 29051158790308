/**
 * Module dependencies.
 */

import { AnyAction } from 'redux';
import type { UacDetails } from 'client/core/types/uacs';
import { fetchUacByIdTypes } from 'client/core/redux/action-types/fetch-uac-by-id';
import { patchIdentityTypes } from 'client/core/redux/action-types/patch-identity';

/**
 * Uacs.
 */

function uacs(state: any = {}, action: AnyAction): UacDetails {
  const { payload, type } = action ?? {};

  switch (type) {
    case fetchUacByIdTypes.successType:
      return payload;

    case patchIdentityTypes.successType:
      return {
        ...state,
        identity: {
          ...state.identity,
          blocked: payload.blocked
        }
      };

    default:
      return state;
  }
}

/**
 * Export `uacs`.
 */

export default uacs;

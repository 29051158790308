
/**
 * Module dependencies.
 */

import type { Transaction } from 'client/core/types/transactions';
import { createRequestStateSelector } from 'core-redux/request';
import { get } from 'lodash';

/**
 * Export `getFetchTransactionsState`.
 */

export const getFetchTransactionsState = createRequestStateSelector('fetchTransactions');

/**
 * Export `getAllTransactions`.
 */

export const getAllTransactions = (state: unknown): Array<Transaction> => {
  return get(getFetchTransactionsState(state), 'data.data', []);
};

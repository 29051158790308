/**
 * Module dependencies.
 */

import { getLogoutState } from 'client/core/redux/selectors/logout';
import { getUserEmail } from 'client/core/redux/selectors/fetch-user';
import { logout, resetLogout } from 'client/core/redux/actions/logout';
import { resetFetchUser } from 'client/core/redux/actions/fetch-user';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from 'client/components/core/header';
import React, { useCallback, useEffect } from 'react';
import profileIcon from 'client/assets/svg/profile-32px.svg';
import routes from 'core/routes';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `DashboardHeader` component.
 */

function DashboardHeader(): JSX.Element {
  const dispatch = useDispatch();
  const email = useSelector(getUserEmail);
  const { errors } = useSelector(getLogoutState);
  const history = useHistory();
  const handleLogout = useCallback(() => {
    return dispatch(logout())
      .then(() => dispatch(resetFetchUser()))
      .then(() => history.push(routes.signIn));
  }, [dispatch, history]);

  const { translate } = useTranslate();

  useEffect(() => {
    return () => dispatch(resetLogout());
  }, [dispatch]);

  useNetworkErrorMessage('logout.errors.network', errors);

  return (
    <Header icon={profileIcon}
      onLogout={handleLogout}
      title={`${translate('dashboard.header.hi')} ${email}`}
    />
  );
}

/**
 * Export `DashboardHeader` component.
 */

export default DashboardHeader;

/**
 * Module dependencies.
 */

import { useLocation } from 'react-router-dom';
import qs from 'qs';

/**
 * Parse query string.
 */

function parseQueryString(search: string) {
  return qs.parse(search, { ignoreQueryPrefix: true });
}

/**
 * Use query string.
 */

function useQueryString() {
  const { search } = useLocation();

  return parseQueryString(search);
}

/**
 * Export `useQueryString`.
 */

export default useQueryString;


/**
 * Module dependencies.
 */

import { Button, color, media, states, units } from 'pmint-design-system';
import { VerificationStatus } from 'client/core/types/transactions';
import { ifProp, theme } from 'styled-tools';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  handleReview: (item: VerificationStatus) => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${units(2)};
  justify-items: center;
  margin: ${units(4)} 0;

  ${media.min('xs')`
    grid-column-gap: ${units(3)};
    grid-template-columns: repeat(2 ,1fr);
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  background-color: ${ifProp('isRejectButton', color('errorDark'), color('bender2'))};
  border: 1px solid ${ifProp('isRejectButton', color('errorDark'), color('bender2'))};
  border-radius: ${units(1)};
  box-shadow: none;
  color: ${color('white')};
  cursor: pointer;
  display: inline-block;
  font-size: ${units(2.25)};
  line-height: ${units(3)};
  max-width: ${units(40)};
  min-height: ${units(8)};
  outline: 0;
  padding: ${units(1.5)} ${units(3)};
  text-align: center;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color, color;
  width: 100%;

  ${states.interaction`
    background-color: ${ifProp('isRejectButton', '#d93d3d', color('bender3'))};
    border-color: ${ifProp('isRejectButton', '#d93d3d', color('bender3'))};
  `}
`;

/**
 * `ReviewButtons` component.
 */

function ReviewButtons(props: Props): JSX.Element {
  const { handleReview } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <StyledButton
        data-cy={'transactionDetails-transactionInformation-approve'}
        onClick={() => {
          handleReview('approved');
        }}
      >
        {translate('common.actions.approve')}
      </StyledButton>
    </Wrapper>
  );
}

/**
 * Export `ReviewButtons` component.
 */

export default ReviewButtons;

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchIdentitiesCountType } from 'client/core/redux/action-types/fetch-identities-count';

/**
 * Export `fetchIdentitiesCount`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchIdentitiesCountType
  })
);


/**
 * Module dependencies.
 */

import { Comment } from 'client/core/types/comment';
import { Type, color, units } from 'pmint-design-system';
import { formatDate } from 'client/core/utils/formatter';
import { getFetchIdentityCommentsState } from 'client/core/redux/selectors/fetch-identity-comments';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import ListEmptyState from 'client/components/core/lists/empty-state';
import Loading from 'client/components/core/loading';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  comments: Comment[];
};

/**
 * `CommentBody` styled component.
 */

const CommentBody = styled(Type.Label).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: ${units(3)};
  margin-left: ${units(1)};
  hyphens: auto;
  word-break: break-all;
`;

/**
 * `CommentInfo` styled component.
 */

const CommentInfo = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: ${units(3)};
  margin-left: ${units(1)};
`;

/**
 * `CommentWrapper` styled component.
 */

const CommentWrapper = styled.div`
  padding-bottom: ${units(2)};
  margin-top: ${units(2)};

  &:not(:last-child) {
    border-bottom: 1px solid ${color('gray300')};
  }
`;

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.a`
  align-items: center;
  display: flex;
  padding: 0 0;
`;

/**
 * Parse comments.
 */

function parseComments(comments: Comment[]) {
  return comments?.map(comment => ({
    ...comment,
    createdAt: formatDate(comment.createdAt, { hasTime: true })
  })) ?? [];
}

/**
  * `CommentsList` component.
  */

function CommentsList({ comments }: Props): JSX.Element {
  const parsedComments = parseComments(comments);
  const { translate } = useTranslate();
  const { isLoading } = useSelector(getFetchIdentityCommentsState);

  if (isLoading) {
    return <Loading isVisible />;
  }

  return (
    <>
      {!isEmpty(comments) ? parsedComments.map((comment: Comment) => {
        const { createdAt, id, name, text } = comment;

        return (
          <CommentWrapper key={id}>
            <RowWrapper>
              <CommentInfo data-cy={`identitiesListDetails-comments-info-${id}`}>
                {`${name} · ${createdAt}`}
              </CommentInfo>
            </RowWrapper>

            <RowWrapper>
              <CommentBody data-cy={`identitiesListDetails-comments-body-${id}`}>
                {text}
              </CommentBody>
            </RowWrapper>
          </CommentWrapper>
        );
      }) : (
        <ListEmptyState
          dataCy={'identitiesListDetails-comments-emptyState'}
          description={translate('identitiesListDetails.comments.listEmptyState')}
        />
      )}
    </>
  );
}

/**
  * Export `CommentsList` component.
  */

export default CommentsList;

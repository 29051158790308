/**
 * Module dependencies.
 */

import { FormErrorsBasePathContext } from './form-errors-base-path-context';
import { FormProvider } from '@seegno/react-forms';
import React, { ReactNode } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  errorsBasePath?: string;
  initialValues?: any;
  jsonSchema: any;
  onSubmit: (data: any) => Promise<any> | undefined;
  validationOptions?: any;
};

/**
 * `Form` component.
 */

function Form(props: Props): JSX.Element {
  const { children, errorsBasePath, initialValues, jsonSchema, onSubmit, validationOptions } = props;

  return (
    <FormErrorsBasePathContext.Provider value={errorsBasePath}>
      <FormProvider
        initialValues={initialValues}
        jsonSchema={jsonSchema}
        onSubmit={onSubmit}
        validationOptions={validationOptions}
      >
        {({ submit }: { submit: () => Promise<void> }) => <form onSubmit={submit}>{children}</form>}
      </FormProvider>
    </FormErrorsBasePathContext.Provider>
  );
}

/**
 * Export `Form` component.
 */

export default Form;

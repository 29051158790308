
/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `patchUserType`.
 */

export const patchUserType = 'PATCH_USER';

/**
 * Export `patchIdentityTypes`.
 */

export const patchUserTypes = createRequestActionTypes({
  method: 'PATCH',
  type: patchUserType
});


/**
 * Module dependencies.
 */

import { AnyAction } from 'redux';
import { Box, media, units } from 'pmint-design-system';
import { Redirect, useLocation } from 'react-router-dom';
import { fetchIdentityById, resetFetchIdentityById } from 'client/core/redux/actions/fetch-identity-by-id';
import { get } from 'lodash';
import { getFetchIdentityByIdState } from 'client/core/redux/selectors/fetch-identity-by-id';
import { useDispatch, useSelector } from 'react-redux';
import BackLink from 'client/components/core/back-link';
import Comments from 'client/components/dashboard/identities-list-details/comments';
import DocumentsDetails from 'client/components/dashboard/identities-list-details/documents-details';
import IdentityDetails from 'client/components/dashboard/identities-list-details/identity-details';
import KycDetails from 'client/components/dashboard/identities-list-details/kyc-details';
import Loading from 'client/components/core/loading';
import OperationDetails from 'client/components/dashboard/identities-list-details/operation-details';
import React, { useCallback, useEffect, useReducer } from 'react';
import Tabs from 'client/components/core/tabs';
import VerificationStatus from 'client/components/dashboard/identities-list-details/verification-status';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `BackWrapper` styled component.
 */

const BackWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-bottom: ${units(3)};
  margin-left: ${units(3)};
`;

/**
 * `StyledTabs` styled component.
 */

const StyledTabs = styled(Tabs)<{
  identity: string;
}>`
  display: flex;
  grid-column: 2 / -2;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin: 0 ${units(3)};
  `}
`;

/**
 * Reducer function.
 */

function reducer(state: any, action: AnyAction) {
  switch (action.type) {
    case 'selectIdentity':
      return {
        isCommentSelected: false,
        isDocumentsSelected: false,
        isIdentitySelected: true,
        isKycDetailsSelected: false,
        isOperationDetailsSelected: false
      };

    case 'selectDocuments':
      return {
        isCommentSelected: false,
        isDocumentsSelected: true,
        isIdentitySelected: false,
        isKycDetailsSelected: false,
        isOperationDetailsSelected: false
      };

    case 'selectKycDetails':
      return {
        isCommentSelected: false,
        isDocumentsSelected: false,
        isIdentitySelected: false,
        isKycDetailsSelected: true,
        isOperationDetailsSelected: false
      };

    case 'selectOperationDetails':
      return {
        isCommentSelected: false,
        isDocumentsSelected: false,
        isIdentitySelected: false,
        isKycDetailsSelected: false,
        isOperationDetailsSelected: true
      };

    case 'selectComments':
      return {
        isCommentSelected: true,
        isDocumentsSelected: false,
        isIdentitySelected: false,
        isKycDetailsSelected: false,
        isOperationDetailsSelected: false
      };

    default:
      return state;
  }
}

/**
 * `IdentitiesListDetails` container.
 */

function IdentitiesListDetails(): JSX.Element {
  const {
    data: activeIdentity,
    errors,
    isFailed,
    isLoading
  } = useSelector(getFetchIdentityByIdState);

  const reduxDispatch = useDispatch();
  const { identityId } = useQueryString();
  const { state: locationState } = useLocation();
  const { translate } = useTranslate();
  const activeIdentityId = identityId ?? get(locationState, 'identityId');
  const transaction = get(locationState, 'transaction');
  const backLinkTo = transaction ? routes.dashboard.transactionsDetails : routes.dashboard.identitiesList;
  const backLinkParams = transaction ? {
    transactionId: transaction
  } : {
    searchWord: get(locationState, 'searchWord'),
    selectedFilter: get(locationState, 'selectedFilter')
  };

  const [state, dispatch] = useReducer(reducer, {
    isCommentSelected: false,
    isDocumentsSelected: false,
    isIdentitySelected: true,
    isKycDetailsSelected: false,
    isOperationDetailsSelected: false
  });

  const handleFetchIdentityById = useCallback(
    (activeIdentityId: string) => {
      return reduxDispatch(fetchIdentityById(activeIdentityId));
    },
    [reduxDispatch]
  );

  useEffect(() => {
    handleFetchIdentityById(activeIdentityId);

    return () => reduxDispatch(resetFetchIdentityById());
  }, [activeIdentityId, dispatch, handleFetchIdentityById, reduxDispatch]);

  useNetworkErrorMessage('identitiesListDetails.errors', errors);

  if (isFailed || !activeIdentityId) {
    return <Redirect to={routes.dashboard.identitiesList} />;
  }

  if (!activeIdentity || isLoading) {
    return <Loading isVisible />;
  }

  const tabItems = [
    {
      dataCy: 'identitiesList-tab-identity',
      id: 'identity',
      isSelected: state.isIdentitySelected,
      label: translate('identitiesListDetails.tabs.identity'),
      onSelectTab: () => {
        dispatch({ type: 'selectIdentity' });
      }
    },
    {
      dataCy: 'identitiesList-tab-documents',
      id: 'documents',
      isSelected: state.isDocumentsSelected,
      label: translate('identitiesListDetails.tabs.documents'),
      onSelectTab: () => {
        dispatch({ type: 'selectDocuments' });
      }
    },
    {
      dataCy: 'identitiesList-tab-kyc-details',
      id: 'kycDetails',
      isSelected: state.isKycDetailsSelected,
      label: translate('identitiesListDetails.tabs.kycDetails'),
      onSelectTab: () => {
        dispatch({ type: 'selectKycDetails' });
      }
    },
    {
      dataCy: 'identitiesList-tab-operationsList',
      id: 'operationDetails',
      isSelected: state.isOperationDetailsSelected,
      label: translate('identitiesListDetails.tabs.operationDetails'),
      onSelectTab: () => {
        dispatch({ type: 'selectOperationDetails' });
      }
    },
    {
      dataCy: 'identitiesList-tab-comments',
      id: 'comments',
      isSelected: state.isCommentSelected,
      label: translate('identitiesListDetails.tabs.comments'),
      onSelectTab: () => {
        dispatch({ type: 'selectComments' });
      }
    }
  ];

  return (
    <>
      <BackWrapper>
        <BackLink
          params={backLinkParams}
          to={backLinkTo}
        />
      </BackWrapper>

      <VerificationStatus
        owner={activeIdentity.identityOwner}
        type={activeIdentity.type}
        verifications={activeIdentity.verifications}
      />

      <StyledTabs
        identity={activeIdentity}
        items={tabItems}
      />

      <Box gridColumn={'2 / -2'}>
        <IdentityDetails identity={activeIdentity}
          isSelected={state.isIdentitySelected}
        />

        <DocumentsDetails
          activeIdentity={activeIdentity}
          isSelected={state.isDocumentsSelected}
        />

        <KycDetails
          activeIdentity={activeIdentity}
          isSelected={state.isKycDetailsSelected}
        />

        <OperationDetails
          activeIdentity={activeIdentity}
          isSelected={state.isOperationDetailsSelected}
        />

        <Comments
          activeIdentity={activeIdentity}
          isSelected={state.isCommentSelected}
        />
      </Box>
    </>
  );
}

/**
 * Export `IdentitiesListDetails` container.
 */

export default IdentitiesListDetails;


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { rejectOperationType } from 'client/core/redux/action-types/reject-operation';

/**
 * `Options` type.
 */

type Options = {
  operationId: string;
};

/**
 * Export `rejectOperation`.
 */

export function rejectOperation({ operationId }: Options) {
  return createRequestAction({
    endpoint: {
      name: 'approveOrRejectOperation',
      params: { operationId, order: 'reject' }
    },
    handleResponse: ({ data }: any) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: rejectOperationType
  });
}

/**
 * Export `resetRejectOperation`.
 */

export const resetRejectOperation = createResetRequestAction({
  method: 'POST',
  type: rejectOperationType
});

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchIdentityDocumentsType } from 'client/core/redux/action-types/fetch-identity-documents';

/**
 * Export `fetchIdentityDocuments`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchIdentityDocumentsType
  })
);

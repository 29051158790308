/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `fetchIdentitiesType`.
 */

export const fetchIdentitiesType = 'FETCH_IDENTITIES';

/**
 * Export `fetchIdentitiesTypes`.
 */

export const fetchIdentitiesTypes = createRequestActionTypes({
  type: fetchIdentitiesType
});

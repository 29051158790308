
/**
 * Module dependencies.
 */

import { requestMiddleware } from 'core-redux/request';
import axios from 'axios';
import config from 'config';

/**
 * Handle fusion auth responses.
 */

function handleFusionAuthResponses(error: any) {
  const responseCodeMapper = {
    // eslint-disable-next-line quote-props
    '404': 'invalidCredentials'
  };

  const { data, status: statusCode } = error as any ?? {};
  const { code } = data ?? {};

  return Promise.reject({
    ...data,
    code: code || (responseCodeMapper as any)[statusCode]
  });
}

/**
 * Export `requestMiddleware`.
 */

export default requestMiddleware({
  baseUrl: config.get('api.baseUrl'),
  endpoints: {
    approveOrRejectOperation: '/operations/${operationId}/${order}',
    approveTransaction: '/transactions/${id}/approve',
    closeUAC: '/uacs/${id}/close',
    createToken: '/auth/token',
    fetchDocumentPresignUrl: '/documents/${id}/presign-url',
    fetchEarnDocumentPresignUrl: '/operation-documents/${documentId}/presign-url',
    fetchEarnPresignUrl: '/earn-presign-url?operationId=${operationId}&fileName=${fileName}',
    fetchOperation: '/operations/${operationId}',
    fetchOperationDocuments: '/operations/${operationId}/documents',
    fetchOperations: '/operations',
    fetchPartner: '/partners/${partner}',
    fetchPartnerOperations: '/partners/${partner}/operations',
    fetchPartners: '/partners',
    fetchPresignUrl: '/presign-url',
    fetchUacById: '/uacs/${uacId}',
    fetchUacs: '/uacs',
    fetchUser: '/users/${userId}',
    fetchUserRoles: '/user-roles',
    identities: '/identities',
    identitiesCount: '/identities/count',
    identityById: '/identities/${id}',
    identityComments: '/identities/${id}/comments',
    identityDocuments: '/identities/${id}/documents',
    identityOperations: '/identities/${id}/operations',
    identityVerifications: '/identities/${id}/verifications',
    insertOperationDocument: '/operations/${operationId}/documents',
    patchIdentity: '/identities/${identityId}',
    patchUser: '/users',
    postUserPassword: '/users/password',
    refreshToken: '/auth/token/refresh',
    reopenIdentityReview: '/identities/${id}/reopen-review',
    reviewIdentity: '/identities/${id}/review',
    revokeToken: '/auth/token/revoke',
    transactionById: '/transactions/${id}',
    transactions: '/transactions',
    uploadIdentityDocument: 'identities/${id}/documents',
    validateToken: '/auth/token/validate'
  },
  handleError: ({ response }: any) => handleFusionAuthResponses(response),
  request: axios.create()
});

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-bottom: 1px solid ${color('gray600')};
  display: grid;
  grid-column-gap: ${units(1.75)};
  grid-template-columns: 0.4fr 0.4fr 0.2fr;
  grid-template-rows: auto;
  justify-items: start;

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(1)} 0;
  `}
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 500;
  line-height: 24px;
  margin-left: ${units(1)};
`;

/**
 * `IdentitiesListHeader` component.
 */

function IdentitiesListHeader(): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper data-cy={'identitiesList-header'}>
      <Value>{translate('identitiesList.listItems.name')}</Value>

      <Value>{translate('identitiesList.listItems.email')}</Value>

      <Value>{translate('identitiesList.listItems.createdAt')}</Value>
    </Wrapper>
  );
}

/**
 * Export `IdentitiesListHeader` component.
 */

export default IdentitiesListHeader;


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { postUserPasswordType } from 'client/core/redux/action-types/post-user-password';

/**
 * Incoming 'PasswordData' type
 */

type PasswordData = {
  currentPassword: string;
  password: string;
};

/**
 * Export `postUserPassword`.
 */

export function postUserPassword(data: PasswordData) {
  return createRequestAction({
    data,
    endpoint: {
      name: 'postUserPassword'
    },
    handleResponse: (response: any) => response.data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: postUserPasswordType
  });
}

/**
 * Export `resetPostUserPassword`.
 */

export const resetPostUserPassword = createResetRequestAction({
  method: 'POST',
  type: postUserPasswordType
});

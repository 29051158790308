
/**
 * Module dependencies.
 */

import { Icon, Link, units } from 'pmint-design-system';
import type { Identity } from 'client/core/types/identities';
import {
  fetchIdentityComments,
  resetFetchIdentityComments
} from 'client/core/redux/actions/fetch-identity-comments';

import { getIdentityComments } from 'client/core/redux/selectors/fetch-identity-comments';
import { getPostIdentityCommentState } from 'client/core/redux/selectors/post-identity-comment';
import { ifProp, switchProp } from 'styled-tools';
import { isEmpty } from 'lodash';
import { postIdentityComment } from 'client/core/redux/actions/post-identity-comment';
import { useDispatch, useSelector } from 'react-redux';
import { useFormActions } from '@seegno/react-forms';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import CommentsForm from './comments-form';
import CommentsList from './comments-list';
import Form from 'client/components/core/forms/form';
import React, { useCallback, useEffect, useState } from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  activeIdentity: Identity;
  isSelected: boolean;
};

/**
 * Write comment schema.
 */

const writeComment = {
  additionalProperties: false,
  properties: {
    text: {
      maxLength: 255,
      type: 'string'
    }
  },
  type: 'object'
};

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: start;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  height: ${units(2.5)};
  margin-right: ${units(1.75)};
  width: ${units(2.5)};

  ${switchProp('order', {
    '-createdAt': css`
      transform: rotate(90deg);
    `,
    createdAt: css`
      transform: rotate(-90deg);
    `
  })}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isSelected: boolean | undefined;
}>`
  display: ${ifProp('isSelected', 'block', 'none')};
`;

/**
 * `Comments` component.
 */

function Comments({ activeIdentity, isSelected }: Props): JSX.Element {
  const dispatch = useDispatch();
  const comments = useSelector(getIdentityComments);
  const initialFormValues = { text: undefined };
  const { id } = activeIdentity;
  const { isSuccessful } = useSelector(getPostIdentityCommentState);
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const { reset } = useFormActions();
  const [order, setOrder] = useState('-createdAt');

  useEffect(() => {
    dispatch(fetchIdentityComments(id, order));

    return () => {
      dispatch(resetFetchIdentityComments());
    };
  }, [dispatch, id, isSuccessful, order]);

  useEffect(() => {
    if (isSuccessful) {
      showSuccessMessage(translate('identitiesListDetails.comments.successMessage'));
    }
  }, [dispatch, isSuccessful, showSuccessMessage, translate]);

  const handleSubmit = useCallback(async (values: any) => {
    try {
      await dispatch(postIdentityComment(id, values));

      reset();
    } catch (error) {
      showErrorMessage(translate('identitiesListDetails.comments.errorMessage'));
    }
  }, [dispatch, id, reset, showErrorMessage, translate]);

  return (
    <Wrapper isSelected={isSelected}>
      <Form
        errorsBasePath={'identitiesListDetails.comments.errors'}
        initialValues={initialFormValues}
        jsonSchema={writeComment}
        onSubmit={handleSubmit}
      >
        <CommentsForm />
      </Form>

      {!isEmpty(comments) && (
        <StyledLink
          colorTheme={'secondary'}
          onClick={() => {
            setOrder(order === 'createdAt' ? '-createdAt' : 'createdAt');
          }}
          size={'small'}
        >
          {translate('identitiesListDetails.comments.date')}

          <StyledIcon
            icon={arrowIcon}
            order={order}
          />
        </StyledLink>
      )}

      <CommentsList comments={comments} />
    </Wrapper>
  );
}

/**
 * Export `Comments` component.
 */

export default Comments;

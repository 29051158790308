/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import React from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  title?: string;
};

/**
 * `SidebarContent` component.
 */

function SidebarContent(props: Props): JSX.Element {
  const { title } = props;
  const { translate } = useTranslate();

  return (
    <Type.H1
      color={color('bender6')}
      fontWeight={400}
      marginBottom={units(3.5)}
    >
      {title ? title : translate('sidebar.title')}
    </Type.H1>
  );
}

/**
 * Export `SidebarContent` component.
 */

export default SidebarContent;

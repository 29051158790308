/**
 * Module dependencies.
 */

import type { Operation } from 'client/core/types/operations';
import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchIdentityOperationsState`.
 */

export const getFetchIdentityOperationsState = createRequestStateSelector('fetchIdentityOperations');

/**
 * Export `getIdentityOperations`.
 */

export const getIdentityOperations = (state: unknown): Array<Operation> => {
  return getFetchIdentityOperationsState(state)?.data ?? [];
};

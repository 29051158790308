/**
 * Module dependencies.
 */

import { cookiesStorage } from 'client/core/utils/cookies';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { logoutType } from 'client/core/redux/action-types/logout';
import { storageKeys } from 'core/utils/storage-keys';

/**
 * Remove session cookies.
 */

function removeSessionCookies() {
  cookiesStorage.remove(storageKeys.token);
  cookiesStorage.remove(storageKeys.userId);
}

/**
 * Export `logout`.
 */

export const logout = () => {
  return createRequestAction({
    endpoint: 'revokeToken',
    handleError: () => {
      removeSessionCookies();
    },
    handleResponse: ({ data }: any) => {
      removeSessionCookies();

      return data;
    },
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: logoutType
  });
};

/**
 * Export `resetLogout`.
 */

export const resetLogout = createResetRequestAction({
  method: 'POST',
  type: logoutType
});

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';
import { get } from 'lodash';

/**
 * Export `getFetchIdentitiesCountState`.
 */

export const getFetchIdentitiesCountState = createRequestStateSelector('fetchIdentitiesCount');

/**
 * Export `getIdentitiesCount`.
 */

export const getIdentitiesCount = (state: unknown): number => {
  return get(getFetchIdentitiesCountState(state), 'data.total', 0);
};

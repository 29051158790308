/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchPresignUrlType } from 'client/core/redux/action-types/fetch-presign-url';

/**
 * Export `fetchPesignUrl`.
 */

export default combineReducers(
  createRequestReducers({
    type: fetchPresignUrlType
  })
);

/**
 * Module dependencies.
 */

import { Dispatch } from 'redux';
import { fetchUser } from 'client/core/redux/actions/fetch-user';
import { setAuthenticated } from 'client/core/redux/actions/set-authenticated';
import { signIn } from 'client/core/redux/actions/sign-in';

/**
 * Export `LoginPayload` type.
 */

export type LoginPayload = {
  email: string;
  password: string;
};

/**
 * Export `performLogin`.
 */

export function performLogin(values: LoginPayload) {
  return (dispatch: Dispatch) => {
    return dispatch(signIn(values)).then(() => {
      return dispatch(fetchUser()).then(() => {
        return dispatch(setAuthenticated());
      });
    });
  };
}

/**
 * Module dependencies.
 */

import { Icon, color, states, units } from 'pmint-design-system';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { fetchUserRoles, resetFetchUserRoles } from 'client/core/redux/actions/fetch-user-roles';
import { getUserRole } from 'client/core/redux/selectors/fetch-user';
import { getUserRoles } from 'client/core/redux/selectors/fetch-user-roles';
import { ifProp, theme } from 'styled-tools';
import { isNil } from 'lodash';
import { matchPath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import arrowIcon from 'client/assets/svg/arrow-back.svg';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `MenuList` type.
 */

type MenuList = {
  key: string;
  paths?: Array<string>;
  title: string;
  to?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(RouterLink)<{
  active?: boolean;
  colorTheme?: 'primary' | 'secondary';
}>`
  border-bottom: 1px solid ${color('gray300')};
  color: ${color('textColorLight')};
  cursor: pointer;
  display: block;
  font-size: 18px;
  padding: 0 26px;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color;

  ${ifProp(
    'active',
    css`
      border-color: ${color('textColor')};
    `
  )}

  ${states.interaction`
    background-color: ${color('gray300')};
  `}
`;

/**
 * `EarnLink` styled component.
 */

const EarnLink = styled.div<{
  colorTheme?: 'primary' | 'secondary';
  onClick?: () => void;
}>`
  border-bottom: 1px solid ${color('gray300')};
  color: ${color('textColorLight')};
  cursor: pointer;
  display: block;
  font-size: 18px;
  padding: 0 26px;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color;

  ${states.interaction`
    background-color: ${color('gray300')};
  `}
`;

/**
 * `StyledMenuItem` styled component.
 */

const StyledMenuItem = styled.div<{
  earn?: boolean;
}>`
  ${theme('typography.styles.label')};

  align-items: center;
  display: flex;
  font-weight: 400;
  min-height: 73px;
  justify-content: space-between;

  ${ifProp(
    'earn',
    css`
      margin-left: ${units(2)};
    `
  )}
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  display: flex;
  height: ${units(2.5)};
  margin-right: ${units(1.75)};
  width: ${units(2.5)};
  transform: rotate(-90deg);

`;

/**
 * Resolve menu list items by role.
 */

const resolveMenuListItemsByRole = (menuList: Array<MenuList>, role: string, userRoles: any): Array<MenuList> => {
  const availableItems = userRoles[role] || ['myAccount'];

  return menuList.filter(({ key }) => availableItems.includes(key));
};

/**
 * `DashboardMenuList` component.
 */

function DashboardMenuList(): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const role = useSelector(getUserRole);
  const userRoles = useSelector(getUserRoles);
  const { translate } = useTranslate();

  const earnIsActive = !isNil(
    matchPath(location.pathname, {
      exact: true,
      path: [routes.dashboard.operations, routes.dashboard.operationDetails]
    })
  ) || !isNil(
    matchPath(location.pathname, {
      exact: true,
      path: [routes.dashboard.partners, routes.dashboard.partnerDetails]
    })
  );

  const [isEarnActive, setIsEarnActive] = useState(earnIsActive);
  const availableMenuList = [
    {
      key: 'myAccount',
      title: translate('dashboard.content.menuList.myAccount'),
      to: routes.dashboard.myAccount
    },
    {
      key: 'identitiesList',
      paths: [routes.dashboard.identitiesList, routes.dashboard.identitiesListDetails],
      title: translate('dashboard.content.menuList.identitiesList'),
      to: routes.dashboard.identitiesList
    },
    {
      key: 'uacManagement',
      paths: [routes.dashboard.uacManagement, routes.dashboard.uacManagementDetails],
      title: translate('dashboard.content.menuList.uacManagement'),
      to: routes.dashboard.uacManagement
    },
    {
      key: 'transactions',
      paths: [routes.dashboard.transactions, routes.dashboard.transactionsDetails],
      title: translate('dashboard.content.menuList.transactions'),
      to: routes.dashboard.transactions
    },
    {
      key: 'admin',
      title: translate('dashboard.content.menuList.admin'),
      to: routes.dashboard.admin
    }
  ];

  const earnMenuList = [
    {
      key: 'operations',
      paths: [routes.dashboard.operations, routes.dashboard.operationDetails],
      title: translate('dashboard.content.menuList.operations'),
      to: routes.dashboard.operations
    },
    {
      key: 'partners',
      paths: [routes.dashboard.partners, routes.dashboard.partnerDetails],
      title: translate('dashboard.content.menuList.partners'),
      to: routes.dashboard.partners
    }
  ];

  const menuItems = resolveMenuListItemsByRole(availableMenuList, role, userRoles);

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(resetFetchUserRoles());
  }, [dispatch]);

  return (
    <Wrapper data-cy={'dashboardMenuList'}>
      {menuItems.map(({ key, paths, title, to }) => {
        const isActive = !isNil(
          matchPath(location.pathname, {
            exact: true,
            path: paths
          })
        );

        return (
          <StyledLink
            active={isActive}
            colorTheme={'secondary'}
            data-cy={key}
            key={key}
            to={to as any}
          >
            <StyledMenuItem>{title}</StyledMenuItem>
          </StyledLink>
        );
      })}
      {role === 'earn' || role === 'admin' && (
        <EarnLink
          colorTheme={'secondary'}
          data-cy={'earn'}
          onClick={() => setIsEarnActive(!isEarnActive)}
        >
          <StyledMenuItem>
            {'Earn'}

            <StyledIcon icon={arrowIcon} />
          </StyledMenuItem>
        </EarnLink>
      )}
      {isEarnActive && earnMenuList.map(({ key, paths, title, to }) => {
        const isActive = !isNil(
          matchPath(location.pathname, {
            exact: true,
            path: paths
          })
        );

        return (
          <StyledLink
            active={isActive}
            colorTheme={'secondary'}
            data-cy={key}
            key={key}
            to={to as any}
          >
            <StyledMenuItem earn>{title}</StyledMenuItem>
          </StyledLink>
        );
      })
      }
    </Wrapper>
  );
}

/**
 * Export `DashboardMenuList` component.
 */

export default DashboardMenuList;

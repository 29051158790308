
/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `postIdentityCommentType`.
 */

export const postIdentityCommentType = 'POST_IDENTITY_COMMENT';

/**
 * Export `postIdentityCommentTypes`.
 */

export const postIdentityCommentTypes = createRequestActionTypes({
  method: 'PATCH',
  type: postIdentityCommentType
});

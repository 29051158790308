/**
 * Module dependencies.
 */

import type { Tax } from 'client/core/types/identities';
import { Type, media, units } from 'pmint-design-system';
import FieldValue from 'client/components/core/field-value';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  taxInformation: Tax;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2 ,1fr);
  `}
`;

/**
 * `TaxInformation` component.
 */

function TaxInformation({ taxInformation }: Props): JSX.Element {
  const { country, idNumber, state } = taxInformation;

  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Title>{translate('identitiesListDetails.taxInformation.title')}</Title>

      <SideBySide>
        <FieldValue
          data-cy={translate('identitiesListDetails-taxInformation-country')}
          label={translate('identitiesListDetails.taxInformation.labels.country')}
        >
          {country}
        </FieldValue>

        <FieldValue
          data-cy={translate('identitiesListDetails-taxInformation-state')}
          label={translate('identitiesListDetails.taxInformation.labels.state')}
        >
          {state}
        </FieldValue>
      </SideBySide>

      <FieldValue
        data-cy={translate('identitiesListDetails-taxInformation-idNumber')}
        label={translate('identitiesListDetails.taxInformation.labels.idNumber')}
      >
        {idNumber}
      </FieldValue>
    </Wrapper>
  );
}

/**
 * Export `TaxInformation` component.
 */

export default TaxInformation;

/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchPresignUrlType } from 'client/core/redux/action-types/fetch-presign-url';
import { get as getFp } from 'lodash/fp';

/**
 * `Options` type.
 */

type Options = {
  fileName: string;
  identityId: string;
  isComplianceDocument?: boolean;
};

/**
 * Export `fetchPresignUrl`.
 */

export function fetchPresignUrl({ fileName, identityId, isComplianceDocument }: Options) {
  return createRequestAction({
    endpoint: 'fetchPresignUrl',
    getQuery: () => ({
      fileName,
      identityId,
      isComplianceDocument
    }),
    handleResponse: getFp('data'),
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchPresignUrlType
  });
}

/**
 * Export `resetFetchPresignUrl`.
 */

export const resetFetchPresignUrl = createResetRequestAction({
  type: fetchPresignUrlType
});


/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { patchUserType } from 'client/core/redux/action-types/patch-user';

/**
 * Incoming 'UserData' type
 */

type UserData = {
  fullName: string;
};

/**
 * Export `patchUser`.
 */

export function patchUser(data: UserData) {
  return createRequestAction({
    data,
    endpoint: {
      name: 'patchUser'
    },
    handleResponse: (response: any) => response.data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'PATCH',
    type: patchUserType
  });
}

/**
 * Export `resetPatchUser`.
 */

export const resetPatchUser = createResetRequestAction({
  method: 'PATCH',
  type: patchUserType
});

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import React from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-bottom: 1px solid ${color('gray600')};
  display: grid;
  grid-column-gap: ${units(1.75)};
  grid-template-columns: 4fr 4fr 2fr;
  justify-items: start;

  ${media.min('md')`
    grid-column-gap: ${units(3.75)};
    padding: ${units(1)} 0;
  `}
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 500;
  line-height: 24px;
`;

/**
 * `DocumentsListHeader` component.
 */

function DocumentsListHeader(): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Value>{translate('documentsList.name')}</Value>

      <Value>{translate('documentsList.createdAt')}</Value>
    </Wrapper>
  );
}

/**
 * Export `DocumentsListHeader` component.
 */

export default DocumentsListHeader;

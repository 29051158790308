
/**
 * Module dependencies.
 */

import { Type, units } from 'pmint-design-system';
import { ifProp } from 'styled-tools';
import ActionButtons from './action-buttons';
import FieldValue from 'client/components/core/field-value';
import PartnerInformation from './partner-information';
import React from 'react';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  amount: string;
  isActionButtonsDisabled: boolean;
  isSelected: boolean;
  operationId: string;
  operationValues: Array<{
    label: string;
    value: string;
  }>;
  partner: string;
  type: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
    isSelected?: boolean;
}>`
  display: none;
  margin-bottom: ${units(2)};

  ${ifProp('isSelected', css`
    display: block;
  `)}
`;

/**
 * `FieldsWrapper` styled component.
 */

const FieldsWrapper = styled.div`
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(2, 1fr);
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `InformationContent` component.
 */

function InformationContent({
  amount,
  isActionButtonsDisabled,
  isSelected,
  operationId,
  operationValues,
  partner,
  type
}: Props): JSX.Element {
  const { translate } = useTranslate();

  return (
    <Wrapper isSelected={isSelected}>
      <Title>
        {translate('operationDetails.title')}
      </Title>

      <FieldsWrapper>
        {operationValues.map(({ label, value }) => (
          <FieldValue
            data-cy={`operationDetails-information-${label.split(' ').join('_')}`}
            key={value}
            label={label}
          >
            {value}
          </FieldValue>
        ))}
      </FieldsWrapper>

      <PartnerInformation
        amount={amount}
        partner={partner}
        type={type}
      />

      <ActionButtons
        disabled={isActionButtonsDisabled}
        operationId={operationId}
      />
    </Wrapper>
  );
}

/**
 * Export `InformationContent` component.
 */

export default InformationContent;

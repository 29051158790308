
/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { postIdentityCommentType } from 'client/core/redux/action-types/post-identity-comment';

/**
 * Export `postIdentityComment`.
 */

export default combineReducers(
  createRequestReducers({
    method: 'POST',
    type: postIdentityCommentType
  })
);

/**
 * Module dependencies.
 */

import { formatNumber } from 'client/core/utils/formatter';
import useTranslate from './use-translate';

/**
 * `Options` type.
 */

type Options = {
  currency?: string;
  decimalPlaces?: number;
  value: string;
};

/**
 * `useFormatter` hook.
 */

const useFormatter = (options: Options) => {
  const { currency, decimalPlaces = 2, value } = options;

  const { i18n } = useTranslate();
  const formattedValue = formatNumber(value || '0', {
    currency: currency && currency.toUpperCase(),
    decimalPlaces,
    locale: i18n.language
  });

  if (!Array.isArray(formattedValue)) {
    return null;
  }

  return formattedValue.map(({ value }) => value).join('');
};

/**
 * Export `useFormatter` hook.
 */

export default useFormatter;

/**
 * Module dependencies.
 */

import type { Identity } from 'client/core/types/identities';
import {
  fetchIdentityOperations,
  resetFetchIdentityOperations
} from 'client/core/redux/actions/fetch-identity-operations';
import { getIdentityOperations } from 'client/core/redux/selectors/fetch-identity-operations';
import { ifProp } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import ListEmptyState from 'client/components/core/lists/empty-state';
import OperationsListContent from './operations-list-content';
import OperationsListHeader from './operations-list-header';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  activeIdentity: Identity;
  isSelected: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isSelected: boolean | undefined;
}>`
  display: ${ifProp('isSelected', 'block', 'none')};
`;

/**
 * `OperationDetails` component.
 */

function OperationDetails({ activeIdentity, isSelected }: Props): JSX.Element {
  const dispatch = useDispatch();
  const operations = useSelector(getIdentityOperations);
  const { id } = activeIdentity;
  const { translate } = useTranslate();

  useEffect(() => {
    dispatch(fetchIdentityOperations(id));

    return () => {
      dispatch(resetFetchIdentityOperations());
    };
  }, [dispatch, id]);

  return (
    <Wrapper isSelected={isSelected}>
      {operations.length ? (
        <>
          <OperationsListHeader />

          <OperationsListContent operations={operations} />
        </>
      ) : (
        <ListEmptyState
          dataCy={'identitiesListDetails-operationsList-emptyState'}
          description={translate('identitiesListDetails.operationsList.listEmptyState')}
        />
      )}
    </Wrapper>
  );
}

/**
 * Export `OperationDetails` component.
 */

export default OperationDetails;


/**
 * Module dependencies.
 */

import { units } from 'pmint-design-system';
import BackLink from 'client/components/core/back-link';
import React from 'react';
import TransactionsList from 'client/components/dashboard/transactions-list/list';
import routes from 'core/routes';
import styled from 'styled-components';

/**
 * `BackWrapper` styled component.
 */

const BackWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-left: ${units(3)};
`;

/**
 * `Transactions` container.
 */

function Transactions(): JSX.Element {
  return (
    <>
      <BackWrapper>
        <BackLink to={routes.dashboard.base} />
      </BackWrapper>

      <TransactionsList />
    </>
  );
}

/**
 * Export `Transactions` container.
 */

export default Transactions;

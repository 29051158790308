/**
 * Module dependencies.
 */

import { LoginPayload, performLogin } from 'client/core/redux/actions/perform-login';
import { getSignInState } from 'client/core/redux/selectors/sign-in';
import { media } from 'pmint-design-system';
import { resetSignIn } from 'client/core/redux/actions/sign-in';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ForgotPassword from 'client/components/core/forgot-password';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import PasswordField from 'client/components/core/forms/fields/password';
import React, { useCallback, useEffect } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  redirectUrl: string;
};

/**
 * Initial values.
 */

const initialValues = {
  email: undefined,
  password: undefined
};

/**
 * Sign in schema.
 */

const signInSchema = {
  additionalProperties: false,
  properties: {
    email: {
      format: 'email',
      type: 'string'
    },
    password: {
      type: 'string'
    }
  },
  required: ['email', 'password'],
  type: 'object'
};

/**
 * `StyledForgotPassword` styled component.
 */

const StyledForgotPassword = styled(ForgotPassword)`
  margin: 18px 0 38px;
`;

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;

    ${media.min('xs')`
      flex-grow: 0;
    `}
  }
`;

/**
 * `SignInForm` component.
 */

function SignInForm({ redirectUrl }: Props): JSX.Element {
  const { errors } = useSelector(getSignInState);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = useCallback(
    (values: LoginPayload) => {
      return (dispatch(performLogin(values)) as any).then(() => history.push(redirectUrl));
    },
    [dispatch, history, redirectUrl]
  );

  useEffect(() => {
    return () => dispatch(resetSignIn());
  }, [dispatch]);

  useNetworkErrorMessage('signIn.errors', errors);

  return (
    <Form
      errorsBasePath={'signIn.form.errors'}
      initialValues={initialValues}
      jsonSchema={signInSchema}
      onSubmit={handleSubmit}
    >
      <InputField label={translate('signIn.form.fields.email')}
        name={'email'}
        type={'email'}
      />

      <PasswordField label={translate('signIn.form.fields.password')}
        name={'password'}
      />

      <StyledForgotPassword />

      <SubmitButtonWrapper>
        <SubmitButton
          dataCy={'signIn-form-submitButton'}
          fullWidth
        >
          {translate('common.actions.login')}
        </SubmitButton>
      </SubmitButtonWrapper>
    </Form>
  );
}

/**
 * Export `SignInForm` component.
 */

export default SignInForm;

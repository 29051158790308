/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { fetchUacByIdType } from 'client/core/redux/action-types/fetch-uac-by-id';
import { normalizeUacData } from 'client/core/utils/normalizer';

/**
 * Export `fetchUacById`.
 */

export const fetchUacById = (uacId: string) =>
  createRequestAction({
    endpoint: {
      name: 'fetchUacById',
      params: { uacId }
    },
    handleResponse: ({ data }: any) => normalizeUacData(data),
    type: fetchUacByIdType
  });

/**
 * Export `resetFetchUacById`.
 */

export const resetFetchUacById = createResetRequestAction({
  type: fetchUacByIdType
});

/**
 * Module dependencies.
 */

import { FixedSizeList as List } from 'react-window';
import { ThemeContext } from 'styled-components';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: (data: { index: number; style: unknown }) => JSX.Element;
  dataCy?: string;
  items: Array<any>;
};

/**
 * `Options` type.
 */

type Options = {
  itemSize: number;
  items: Props['items'];
};

/**
 * Default item size.
 */

const defaultItemSize = 80;

/**
 * Max item size.
 */

const maxItemSize = 130;

/**
 * Max height.
 */

const maxHeight = 720;

/**
 * Calculate height.
 */

const calculateHeight = (options: Options) => {
  const { itemSize, items } = options;

  if (isEmpty(items)) {
    return 0;
  }

  const height = itemSize * items.length;

  return height > maxHeight ? maxHeight : height;
};

/**
 * `InfiniteScrollList` component.
 */

function InfiniteScrollList({ children, dataCy, items }: Props): JSX.Element {
  const { breakpoints } = useContext(ThemeContext);
  const [itemSize, setItemSize] = useState(defaultItemSize);
  const height = calculateHeight({
    itemSize,
    items
  });

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.screen.width;

      return setItemSize(screenWidth >= breakpoints?.xs ? defaultItemSize : maxItemSize);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoints.xs]);

  return (
    <div data-cy={dataCy}>
      <List
        height={height}
        itemCount={items.length}
        itemSize={itemSize}
        width={'100%'}
      >
        {children}
      </List>
    </div>
  );
}

/**
 * Export `InfiniteScrollList` component.
 */

export default InfiniteScrollList;

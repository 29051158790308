/**
 * Module dependencies.
 */

import { Button, Loader, Type, color, units } from 'pmint-design-system';
import { find } from 'lodash';
import { ifProp } from 'styled-tools';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  documentUrl: string | undefined;
};

/**
 * `Image` styled component.
 */

const Image = styled.img<{
  imageLoaded: any;
}>`
  display: none;
  height: 100%;
  margin-bottom: ${units(5)};
  max-height: 60%;
  max-width: 60%;
  object-fit: cover;
  width: 100%;
  z-index: 5;

  ${ifProp(
    'imageLoaded',
    css`
      display: block;
    `
  )}
`;

/**
 * `StyledButtonWrapper` styled component.
 */

const StyledButtonWrapper = styled.div`
  text-align: center;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  align-items: center;
  min-height: ${units(8)};
  min-width: ${units(30)};
`;

/**
 * `DocumentImage` component.
 */

function DocumentImage({ documentUrl, ...rest }: Props): JSX.Element {
  const [documentImage, setDocumentImage] = useState(documentUrl);
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(() => {
    const image = find(document.images, { src: documentImage });

    return image ? image.complete : false;
  });

  const { translate } = useTranslate();
  const handleDocumentViewButtonClick = useCallback(() => {
    window.open(documentUrl);
  }, [documentUrl]);

  useEffect(() => {
    if (documentUrl !== documentImage) {
      setDocumentImage(documentUrl);
      setImageLoaded(false);
    }

    if (documentUrl) {
      const timer = setTimeout(() => {
        if (!imageLoaded) {
          setErrorLoadingImage(true);
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [documentImage, documentUrl, imageLoaded]);

  return (
    <>
      <Image
        {...rest}
        imageLoaded={imageLoaded}
        onLoad={() => setImageLoaded(true)}
        src={documentImage}
      />

      {!imageLoaded && !errorLoadingImage ? (
        <Loader {...rest}
          colorTheme={color('bender1')}
          size={units(7)}
          stroke={units(0.7)}
        />
      ) : (
        <StyledButtonWrapper>
          {errorLoadingImage && (
            <Type.Label as={'h2'}
              color={color('textColor')}
              fontWeight={500}
              marginBottom={units(8)}
            >
              {translate('documents.modals.viewDocument.errorLoadingDocument')}
            </Type.Label>
          )}
          <StyledButton onClick={handleDocumentViewButtonClick}>
            {translate('documents.modals.viewDocument.openDocumentButton')}
          </StyledButton>
        </StyledButtonWrapper>
      )}
    </>
  );
}

/**
 * Export `DocumentImage` component.
 */

export default DocumentImage;

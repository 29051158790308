/**
 * Module dependencies.
 */

import { sendPageToAnalytics } from 'client/core/utils/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * `AnalyticsTracker` container.
 */

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    sendPageToAnalytics();
  }, [location.pathname]);

  return null;
};

/**
 * Export `AnalyticsTracker` container.
 */

export default AnalyticsTracker;

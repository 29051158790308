/**
 * Module dependencies.
 */

import { RequestStateSelector } from 'client/core/types/core-redux';
import { createRequestStateSelector } from 'core-redux/request';
import { get } from 'lodash';

/**
 * Export `getFetchUserState`.
 */

export const getFetchUserState: RequestStateSelector = createRequestStateSelector('fetchUser');

/**
 * Export `getUserEmail`.
 */

export const getUserEmail = (state: unknown): string => {
  return get(getFetchUserState(state), 'data.user.email', '');
};

/**
 * Export `getUserRole`.
 */

export const getUserRole = (state: unknown): string => {
  return get(getFetchUserState(state), 'data.user.registrations[0].roles[0]', '');
};

/**
 * Export `getUser`.
 */

export const getUser = (state: unknown) => {
  return get(getFetchUserState(state), 'data');
};

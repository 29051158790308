/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `signInType`.
 */

export const signInType = 'SIGN_IN';

/**
 * Export `signInTypes`.
 */

export const signInTypes = createRequestActionTypes({
  method: 'POST',
  type: signInType
});


/**
 * Module dependencies.
 */

import type { Identity } from 'client/core/types/identities';
import type { TransactionDetails } from 'client/core/types/transactions';
import { Type, media, units } from 'pmint-design-system';
import { formatDate } from 'client/core/utils/formatter';
import { useHistory } from 'react-router-dom';
import FieldValue from 'client/components/core/field-value';
import React, { useCallback } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';
import useFormatter from 'client/hooks/use-formatter';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  amount: string;
  createdAt: string;
  currency: string;
  destinationAddress: string;
  id: string;
  identity: Identity;
  identityId: string;
  status: string;
  transactionDetails: TransactionDetails;
  type: string;
};

/**
 * `IdentityValue` styled component.
 */

const IdentityValue = styled(FieldValue)`
  cursor: pointer;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `TitleWrapper` styled component.
 */

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(1)};
`;

/**
 * `SideBySide` styled component.
 */

const SideBySide = styled.div`
  display: grid;

  ${media.min('xs')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2 ,1fr);
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph)`
  font-weight: 500;
  margin-bottom: ${units(1.5)};
`;

/**
 * `TransactionInformation` component.
 */

function TransactionInformation(props: Props): JSX.Element {
  const {
    amount,
    createdAt,
    currency,
    destinationAddress,
    id,
    identity,
    identityId,
    status,
    transactionDetails,
    type
  } = props;

  const { translate } = useTranslate();
  const normalizedAmount = useFormatter({ currency, value: amount });
  const history = useHistory();
  const translatedStatus = translate(
    `transactionDetails.transactionInformation.status.${status}`
  );

  const handleIdentityClick = useCallback(() => {
    history.push({
      pathname: routes.dashboard.identitiesListDetails,
      state: {
        identityId,
        transaction: id
      }
    });
  }, [history, id, identityId]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>
          {translate('transactionDetails.transactionInformation.title')}
        </Title>
      </TitleWrapper>

      <SideBySide>
        <FieldValue
          data-cy={'transactionDetails-transactionInformation-address'}
          label={translate('transactionDetails.transactionInformation.labels.address')}
        >
          {destinationAddress}
        </FieldValue>

        <FieldValue
          data-cy={'transactionDetails-transactionInformation-type'}
          label={translate('transactionDetails.transactionInformation.labels.type')}
        >
          {translate([`transactionDetails.transactionInformation.type.${type}`, 'NaN'])}
        </FieldValue>
      </SideBySide>

      <SideBySide>
        <FieldValue
          data-cy={'transactionDetails-transactionInformation-amount'}
          label={translate('transactionDetails.transactionInformation.labels.amount')}
        >
          {`${normalizedAmount}`}
        </FieldValue>

        <IdentityValue
          data-cy={'transactionDetails-transactionInformation-identity'}
          label={translate('transactionDetails.transactionInformation.labels.identity')}
          onClick={() => handleIdentityClick()}
        >
          {identity ? identity.name : ''}
        </IdentityValue>
      </SideBySide>

      <SideBySide>
        <FieldValue
          data-cy={'transactionDetails-transactionInformation-originAddress'}
          label={translate('transactionDetails.transactionInformation.labels.originAddress')}
        >
          {transactionDetails.address}
        </FieldValue>

        <FieldValue
          data-cy={'transactionDetails-transactionInformation-chain'}
          label={translate('transactionDetails.transactionInformation.labels.chain')}
        >
          {transactionDetails.chain}
        </FieldValue>
      </SideBySide>

      <SideBySide>
        <FieldValue
          data-cy={'transactionDetails-transactionInformation-createdAt'}
          label={translate('transactionDetails.transactionInformation.labels.createdAt')}
        >
          {formatDate(createdAt, { hasTime: true })}
        </FieldValue>

        <FieldValue
          data-cy={'transactionDetails-transactionInformation-status'}
          label={translate('transactionDetails.transactionInformation.labels.status')}
        >
          {translatedStatus}
        </FieldValue>
      </SideBySide>
    </Wrapper>
  );
}

/**
 * Export `TransactionInformation` component.
 */

export default TransactionInformation;

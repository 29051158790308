
/**
 * Module dependencies.
 */

import type { FetchTransactionsType } from 'client/core/types/transactions';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { fetchTransactionsType } from 'client/core/redux/action-types/fetch-transactions';

/**
 * Export `fetchTransactions`.
 */

export const fetchTransactions = ({ number = '0', size = '20' }: FetchTransactionsType) => {
  return createRequestAction({
    endpoint: 'transactions',
    getQuery: () => ({
      page: {
        number,
        size
      }
    }),
    handleResponse: (response: any) => response.data,
    type: fetchTransactionsType
  });
};

/**
 * Export `resetFetchTransactions`.
 */

export const resetFetchTransactions = createResetRequestAction({
  type: fetchTransactionsType
});

/**
 * Module dependencies.
 */

import { setActiveDocumentType } from 'client/core/redux/action-types/documents';

/**
 * Export `setActiveDocumentType`.
 */

export const setActiveDocument = (documentId: string) => {
  return {
    payload: { documentId },
    type: setActiveDocumentType
  };
};

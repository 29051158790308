/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { fetchDocumentPresignUrlType } from 'client/core/redux/action-types/fetch-document-presign-url';

/**
 * `Values` type.
 */

type Values = {
  documentId: string;
};

/**
 * Export `fetchDocumentPresignUrl`.
 */

export const fetchDocumentPresignUrl = ({ documentId }: Values) => {
  return createRequestAction({
    endpoint: {
      name: 'fetchDocumentPresignUrl',
      params: { id: documentId }
    },
    handleResponse: ({ data }: any) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchDocumentPresignUrlType
  });
};

/**
 * Export `resetFetchDocumentPresignUrl`.
 */

export const resetFetchDocumentPresignUrl = createResetRequestAction({
  type: fetchDocumentPresignUrlType
});

/**
 * Module dependencies.
 */

import { cookiesStorage } from 'client/core/utils/cookies';
import { createRequestAction, createResetRequestAction } from 'core-redux/request';

import { get } from 'lodash';
import { signInType } from 'client/core/redux/action-types/sign-in';
import { storageKeys } from 'core/utils/storage-keys';

/**
 * `Options` type.
 */

type Options = {
  email: string;
  password: string;
};

/**
 * Export `signIn`.
 */

export const signIn = ({ email, password }: Options) => {
  return createRequestAction({
    data: { email, password },
    endpoint: 'createToken',
    handleResponse: ({ data }: any) => {
      const id = get(data, 'id');
      const token = get(data, 'token');

      if (token) {
        cookiesStorage.remove(storageKeys.token);
      }

      if (id) {
        cookiesStorage.remove(storageKeys.userId);
      }

      cookiesStorage.set(storageKeys.token, token);
      cookiesStorage.set(storageKeys.userId, id);

      return data;
    },
    method: 'POST',
    type: signInType
  });
};

/**
 * Export `resetSignIn`.
 */

export const resetSignIn = createResetRequestAction({
  method: 'POST',
  type: signInType
});

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { ifNotProp, switchProp } from 'styled-tools';
import { isNil, startCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';
import config from 'config';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  owner: string;
  type: string;
  verifications: {
    acuant?: string;
    primeTrust?: string;
    publicMint?: string;
  };
};

/**
 * Is acuant active.
 */

const { isAcuantActive } = config.get<{ isAcuantActive: boolean }>('identityReview');

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-row-gap: ${units(1)};
  margin-bottom: ${units(5)};
  grid-column-gap: ${units(2)};

  ${media.min('md')`
    grid-template-columns: repeat(2, auto);
  `}

  ${media.min('xl')`
    grid-template-columns: repeat(3, auto);
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Label)`
  color: ${color('textColor')};
  font-weight: 500;
  margin-right: ${units(1)};

  ${ifNotProp('owner', null, css`&:hover {
    text-decoration: underline;
    cursor: pointer;
  }`)}
`;

/**
 * `Status` styled component.
 */

const Status = styled(Type.Paragraph)`
  font-weight: 500;

  ${switchProp('status', {
    approved: css`
      color: ${color('bender2')};
    `,
    pending: css`
      color: ${color('warning')};
    `,
    rejected: css`
      color: ${color('errorDark')};
    `
  })}
`;

/**
 * `VerificationStatus` component.
 */

function VerificationStatus(props: Props): JSX.Element {
  const history = useHistory();
  const filterVerificationKey = isAcuantActive ? 'publicMint' : 'acuant';
  const { owner, type, verifications } = props;
  const { translate } = useTranslate();
  const filteredVerifications = Object.entries(verifications).reduce((acc, [key, value]) => {
    if (key === filterVerificationKey) {
      return acc;
    }

    return { ...acc, [key]: value };
  }, {});

  const handleRowClick = useCallback(
    (id: string) => {
      history.push({
        pathname: routes.dashboard.identitiesListDetails,
        state: {
          identityId: id
        }
      });
    },
    [history]
  );

  return (
    <Wrapper>
      {Object.entries(filteredVerifications).map(([provider, status]) => {
        return (
          <ContentWrapper
            data-cy={`identitiesListDetails-verificationStatus-${provider}`}
            key={provider}
          >
            <Label>{`${translate(`identitiesListDetails.verificationStatusLabels.${provider}`)}:`}</Label>

            <Status>{status || translate(`identitiesListDetails.verificationStatusLabels.na`)}</Status>
          </ContentWrapper>
        );
      })}

      <ContentWrapper data-cy={'identitiesListDetails-verificationStatus-type'}>
        <Label>
          {`${translate(`identitiesListDetails.verificationStatusLabels.type`)}:`}
        </Label>
        <Status>
          {startCase(type)}
        </Status>
      </ContentWrapper>
      {owner && (<ContentWrapper>
        <Label
          data-cy={'identitiesListDetails-verificationStatus-identityOwner'}
          onClick={() => {
            return isNil(owner) ? null : handleRowClick(owner);
          }}
          owner={owner}
        >
          {translate(`identitiesListDetails.verificationStatusLabels.identityOwner`)}
        </Label>
      </ContentWrapper>
      )}

    </Wrapper>
  );
}

/**
 * Export `VerificationStatus` component.
 */

export default VerificationStatus;

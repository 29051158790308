/**
 * Module dependencies.
 */

import { createRequestAction, createResetRequestAction } from 'core-redux/request';
import { uploadIdentityDocumentType } from 'client/core/redux/action-types/upload-identity-document';

/**
 * `Values` type.
 */

type Values = {
  identityId: string;
  label: string;
  side: string;
  type: string;
  url: string;
};

/**
 * Export `uploadIdentityDocument`.
 */

export const uploadIdentityDocument = (data: Values) => {
  const { identityId, label, side, type, url } = data;

  return createRequestAction({
    data: {
      label,
      side,
      type,
      url
    },
    endpoint: {
      name: 'uploadIdentityDocument',
      params: { id: identityId }
    },
    handleResponse: ({ data }: any) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: uploadIdentityDocumentType
  });
};

/**
 * Export `resetUploadIdentityDocument`.
 */

export const resetUploadIdentityDocument = createResetRequestAction({
  method: 'POST',
  type: uploadIdentityDocumentType
});

/**
 * Module dependencies.
 */

import { createReactPortal } from 'client/core/utils/portals';
import { ifProp, theme } from 'styled-tools';
import { setBodyScroll } from 'client/core/utils/body-scroll';
import React, {
  ReactPortal,
  SyntheticEvent,
  useCallback,
  useEffect
} from 'react';

import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
  isOpen: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isVisible: boolean | undefined;
}>`
  transition: visibility ${theme('animations.styles.defaultTransition')};
  visibility: ${ifProp('isVisible', 'visible', 'hidden')};
`;

/**
 * `ModalPortal` component.
 */

function ModalPortal(props: Props): ReactPortal | null {
  const { children, isOpen } = props;
  const handleGhostClick = useCallback((event: SyntheticEvent) => {
    if (event) {
      event.stopPropagation();
    }
  }, []);

  useEffect(() => {
    setBodyScroll(!isOpen);
  }, [isOpen]);

  useEffect(
    () => () => {
      if (isOpen) {
        return setBodyScroll(true);
      }
    },
    [isOpen]
  );

  return createReactPortal((
    <Wrapper
      isVisible={isOpen}
      onClick={handleGhostClick}
    >
      {children}
    </Wrapper>
  ), 'modal-root');
}

/**
 * Export `ModalPortal` component.
 */

export default ModalPortal;
